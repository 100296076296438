import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  TextField,
  FormControl,
  Select,
  MenuItem,
  FormHelperText,
  Autocomplete,
} from "@mui/material";
import "./ProfileAddress.scss";
import { CountryContext } from "../../../MainComponent";
import * as queries from "../../../../../operations/queries";
import * as mutations from "../../../../../operations/mutations";
import { useMutation, useQuery } from "@apollo/client";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "axios";

interface FormDataState {
  id: number | null;
  region: {
    region: string;
    region_code: string;
    region_id: number;
  };
  company: string;
  country_code: string;
  street: string[];
  telephone: string;
  postcode: string;
  city: string;
  firstname: string;
  lastname: string;
  default_shipping: boolean;
  default_billing: boolean;
}

interface ValidationState {
  [key: string]: string;
}

const ProfileAddress = () => {
  const { store } = useContext(CountryContext);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [custCountry, setCustCountry] = useState(String());
  const [state, setState] = useState([]);
  const [custState, setCustState] = useState("");
  //eslint-disable-next-line
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [validationErrors, setValidationErrors] = useState<ValidationState>({});
  const [cities, setCities] = useState([]);
  const [filteredCities, setFilteredCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState("");
  console.log("selectedCity",selectedCity)
  const [searchParams] = useSearchParams();
  const props = searchParams.get("props");
  const navigate = useNavigate();
  const editAddressId = searchParams?.get("id") ? searchParams?.get("id") : "";
  const { t } = useTranslation();
  const loggedInToken = localStorage.getItem("loggedInCust");

  const initialFormData = {
    id: null,
    region: {
      region: "",
      region_code: "",
      region_id: 0,
    },
    company: "",
    country_code: "AE",
    street: [""],
    telephone: "",
    postcode: store === ("ae_en" || "ae_ar") ? "0000" : " ",
    city: "",
    firstname: "",
    lastname: "",
    default_shipping: false,
    default_billing: false,
  };

  const [formData, setFormData] = useState<FormDataState>(initialFormData);

  const [createCustomerAddress] = useMutation(
    mutations.customerMutation.CREATE_ADDRESS,
    {
      context: {
        headers: {
          Authorization: loggedInToken
            ? `Bearer ${JSON.parse(loggedInToken)}`
            : "",
          Connection: "keep-alive",
          store: store,
        },
      },
    }
  );

  const [updateCustomerAddress] = useMutation(
    mutations.customerMutation.UPDATE_ADDRESS,
    {
      context: {
        headers: {
          Authorization: loggedInToken
            ? `Bearer ${JSON.parse(loggedInToken)}`
            : "",
          Connection: "keep-alive",
        },
      },
    }
  );

  const { data: custDetailsDataRes } = useQuery(
    queries.customerQueries.GET_CUSTOMER_INFO,
    {
      skip: !loggedInToken,
      context: {
        headers: {
          Authorization: loggedInToken
            ? `Bearer ${JSON.parse(loggedInToken)}`
            : "",
          Connection: "keep-alive",
        },
      },
    }
  );
  const custDetails = custDetailsDataRes?.customer;

  useEffect(() => {
    if (custDetails) {
      const add = custDetails?.addresses?.filter(
        (item: any) => item?.id.toString() === editAddressId
      );
      if (add && add.length > 0) {
        const data = {
          id: parseInt(add[0]?.id),
          region: {
            region: add[0]?.region?.region,
            region_code: add[0]?.region?.region_code,
            region_id: 0,
          },
          company: add[0]?.company,
          country_code: add[0]?.country_code,
          street: add[0]?.street,
          telephone: add[0]?.telephone,
          postcode: add[0]?.postcode,
          city: add[0]?.city,
          firstname: add[0]?.firstname || "",
          lastname: add[0]?.lastname || "",
          default_shipping: add[0]?.default_shipping,
          default_billing: add[0]?.default_billing,
        };
        setFormData(data);
        setCustCountry(add[0]?.country_code);
        setCustState(add[0]?.region?.region_code);
        setSelectedCity(add[0]?.city)
      }
    }
  }, [custDetails, editAddressId]);

  const { data: countryRegRes, refetch: refetchCountryRes } = useQuery(
    queries.configurationQueries.GET_COUNTRIES,
    {
      fetchPolicy: "network-only",
      context: {
        headers: {
          Connection: "keep-alive",
          store: store,
        },
      },
    }
  );

  useEffect(() => {
    refetchCountryRes();
    //eslint-disable-next-line
  }, [store]);

  const countries = countryRegRes?.countries;
  const selectedCountryData = countryRegRes?.countries?.find(
    (data: any) => data?.two_letter_abbreviation === selectedCountry
  );

  useEffect(() => {
    if (countries && countries?.length > 0) {
      // eslint-disable-next-line array-callback-return
      const data = countries?.filter((item: any) => {
        if (item?.two_letter_abbreviation === custCountry) {
          return item?.available_regions;
        }
      });
      setState(data[0]?.available_regions);
    }
    // eslint-disable-next-line
  }, [custCountry]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    let sanitizedValue = value;

    if (name === "postcode") {
      sanitizedValue = value.trim().replace(/\D/g, "");
    }

    if (name === "telephone") {
      sanitizedValue = value.replace(/\D/g, "");
    }

    if (name === "street") {
      setFormData((prevData) => ({
        ...prevData,
        [name]: [sanitizedValue],
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: sanitizedValue,
      }));
    }

    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  useEffect(() => {
    if (store?.length > 0) {
      localStorage.setItem("store_code", store);
    }
    onCountryChange();
  }, [store]);

  const onCountryChange = async () => {
    const store_code = await localStorage.getItem("store_code");
    if (store_code === "ae_en" || store_code === "ae_ar") {
      setSelectedCountry("AE");
    } else if (store_code === "sa_en" || store_code === "sa_ar") {
      setSelectedCountry("SA");
      localStorage.setItem("getCountryCode", "SA");
    } else if (store_code === "en" || store_code === "ar") {
      setSelectedCountry("OM");
    } else if (store_code === "in") {
      setSelectedCountry("IN");
    }
  };

  const handledCustState = (event: any) => {
    // eslint-disable-next-line
    const rCode: any = state?.filter(
      (item: any) => item.code === event.target.value
    );

    let region = {
      region: event,
      region_code: event,
      region_id: 0,
    };

    setFormData({ ...formData, region: region });
    setCustState(event);

    if (isSubmitted) {
      if (store === "sa_ar" || store === "sa_en") {
        setValidationErrors((prevErrors) => ({
          ...prevErrors,
          city: t("auth.checkout.city_err"),
        }));
      }
    }
  };

  useEffect(() => {
    if (custState) {
      const rCode: any = state?.find((item: any) => item.code === custState);
      if (rCode) {
        const region = {
          region: rCode.name,
          region_code: rCode.code,
          region_id: rCode.id,
        };
        setFormData({ ...formData, region });
      }
    }
    // eslint-disable-next-line
  }, [custState, state]);

  const validateForm = () => {
    const errors: any = {};
    if (!formData.firstname.trim()) {
      errors.firstname = t("auth.checkout.firstname_err");
    }

    if (!formData.lastname.trim()) {
      errors.lastname = t("auth.checkout.lastname_err");
    }

    if (!formData.telephone.trim()) {
      errors.telephone = t("auth.checkout.ph1_err");
    } else if (!/^\d+$/.test(formData.telephone)) {
      errors.telephone = t("auth.checkout.ph2_err");
    } else if (
      formData.telephone.length < 10 ||
      formData.telephone.length > 15
    ) {
      errors.telephone = t("auth.checkout.ph3_err");
    }

    if (!formData.city.trim()) {
      errors.city = t("auth.checkout.city_err");
    }

    if (!formData.postcode.trim()) {
      errors.postcode = t("auth.checkout.post1_err");
    } else if (!/^\d+$/.test(formData.postcode)) {
      errors.postcode = t("auth.checkout.post2_err");
    }

    if (!formData.street[0].trim()) {
      errors.street = t("auth.checkout.street_err");
    }

    // errors.region = errors.region || {};
    if (!formData?.region?.region as any) {
      errors.state = t("auth.checkout.state_err");
    }

    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  useEffect(() => {
    if (Object.keys(validationErrors).length > 0) {
      validateForm();
    }
    //eslint-disable-next-line
  }, [t]);

  const closePage = () => {
    const currentPath = window.location.pathname;
    const basePath = currentPath.split("/add-new-address")[0];
    navigate(basePath);
  };

  const handleCreateAddress = async (event: any) => {
    event.preventDefault();

    if (!validateForm()) {
      return;
    }

    const addressId = formData.id;

    setIsSubmitted(true);

    try {
      const selectedRegion = selectedCountryData?.available_regions?.find(
        (region: any) => region.code === custState
      );
      let result;

      if (props === "edit-address") {
        result = await updateCustomerAddress({
          variables: {
            id: addressId,
            input: {
              region: {
                region: formData.region.region,
                region_code: formData.region.region_code,
                region_id: formData.region.region_id,
              },
              company: "jebelz",
              country_code: formData.country_code,
              street: formData.street,
              telephone: formData.telephone,
              postcode: formData.postcode,
              city: formData.city,
              firstname: formData.firstname,
              lastname: formData.lastname,
              default_shipping: formData.default_shipping,
              default_billing: formData.default_billing,
            },
          },
        });
      } else {
        //eslint-disable-next-line
        result = await createCustomerAddress({
          variables: {
            input: {
              region: {
                region: selectedRegion?.name || "",
                region_code: selectedRegion?.code || "",
                region_id: selectedRegion?.id || 0,
              },
              company: "jebelz",
              country_code: selectedCountry,
              street: formData.street,
              telephone: formData.telephone,
              postcode: formData.postcode,
              city: formData.city,
              firstname: formData.firstname,
              lastname: formData.lastname,
              default_shipping: formData.default_shipping,
              default_billing: formData.default_billing,
            },
          },
        });
      }
      closePage();
      setFormData(initialFormData);
      setCustState("");
    } catch (error) {
      console.error("Address Operation Error:", error);
    }
  };

  useEffect(() => {
    if (store === "sa_en" || store === "sa_ar") {
      axios
        .get("https://access.jebelz.com/media/contents/ksa_city.json")
        .then((response) => {
          setCities(response?.data);
        })
        .catch((error) => {
          console.error("Error fetching cities:", error);
        });
    }
  }, [store]);

  useEffect(() => {
    if (custState) {
      setFilteredCities(
        cities.filter((city: any) => city?.region_code === custState)
      );
    }
  }, [custState, cities]);

  const handleCityChange = (event: any) => {
    const cityCode = event.target.value;
    setSelectedCity(cityCode);
    if (cityCode) {
      setFormData({ ...formData, city: cityCode });
    }

    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      city: cityCode ? "" : t("auth.checkout.city_err"),
    }));
  };

  const title =
    props !== "edit-address"
      ? t("auth.checkout.new_add")
      : t("auth.checkout.edit_add");

  return (
    <>
      <Box className="profile-box profile-address">
        <Box className="profile-title add-new-address">
          <Box>
            <h4>{title}</h4>
          </Box>
        </Box>

        <Box className="profile-title">
          <h4>{t("auth.checkout.add_desc")}</h4>
        </Box>

        <Box className="general-info-box">
          <h4>{t("auth.checkout.address_title")}</h4>
          <hr />
          <Box
            component="form"
            noValidate
            onSubmit={handleCreateAddress}
            sx={{ mt: 3 }}
            autoComplete="off"
          >
            <Box className="input-grp">
              <Box className="input-box">
                <p>{t("auth.checkout.f_name")}</p>
                <TextField
                  autoComplete="given-name"
                  name="firstname"
                  required
                  fullWidth
                  id="firstname"
                  value={formData.firstname}
                  onChange={handleInputChange}
                  error={!!validationErrors.firstname}
                  helperText={validationErrors.firstname}
                  FormHelperTextProps={{
                    sx: {
                      color: "error.main !important",
                      fontSize: "0.75rem !important",
                    },
                  }}
                  sx={{
                    "& .MuiInputBase-input": {
                      fontSize: "13px",
                    },
                  }}
                />
              </Box>

              <Box className="input-box">
                <p>{t("auth.checkout.l_name")}</p>
                <TextField
                  autoComplete="given-name"
                  name="lastname"
                  required
                  fullWidth
                  id="lastname"
                  value={formData.lastname}
                  onChange={handleInputChange}
                  error={!!validationErrors.lastname}
                  helperText={validationErrors.lastname}
                  sx={{
                    "& .MuiInputBase-input": {
                      fontSize: "13px",
                    },
                  }}
                  FormHelperTextProps={{
                    sx: {
                      color: "error.main !important",
                      fontSize: "0.75rem !important",
                    },
                  }}
                />
              </Box>

              <Box className="input-box">
                <p>{t("auth.checkout.mob_no")}</p>
                <TextField
                  autoComplete="given-telephone"
                  name="telephone"
                  required
                  fullWidth
                  id="telephone"
                  value={formData.telephone}
                  onChange={handleInputChange}
                  error={!!validationErrors.telephone}
                  helperText={validationErrors.telephone}
                  sx={{
                    "& .MuiInputBase-input": {
                      fontSize: "13px",
                    },
                  }}
                  FormHelperTextProps={{
                    sx: {
                      color: "error.main !important",
                      fontSize: "0.75rem !important",
                    },
                  }}
                />
              </Box>
            </Box>
            <Box className="input-grp">
              <Box className="input-box">
                <p>{t("auth.checkout.state")}</p>
                <FormControl fullWidth>
                  <Autocomplete
                    id="state-autocomplete"
                    options={selectedCountryData?.available_regions || []}
                    getOptionLabel={(option: any) => option?.name || ""}
                    isOptionEqualToValue={(option: any, value: any) =>
                      option?.code === value?.code
                    }
                    filterOptions={(options, { inputValue }) => {
                      const isArabic = /[\u0600-\u06FF]/.test(inputValue);

                      if (isArabic) {
                        return options.filter((option) =>
                          option.name.includes(inputValue)
                        );
                      } else {
                        const formattedInput = inputValue
                          .trim()
                          .replace(/\s+/g, "-")
                          .toLowerCase();

                        return options.filter(
                          (option) =>
                            option.code
                              .toLowerCase()
                              .includes(formattedInput) ||
                            option.name.toLowerCase().includes(formattedInput)
                        );
                      }
                    }}
                    value={
                      selectedCountryData?.available_regions?.find(
                        (region: any) => region.code === custState
                      ) || null
                    }
                    onChange={(event, newValue: any) => {
                      if (newValue) {
                        handledCustState(newValue.code);
                        setValidationErrors((prevErrors) => ({
                          ...prevErrors,
                          state: "",
                        }));
                      }
                    }}
                    // popupIcon={<CustomSelectIcon />}
                    renderInput={(params: any) => (
                      <TextField
                        className="selecteState"
                        {...params}
                        required
                        error={!!validationErrors.state}
                        InputProps={{
                          ...params.InputProps,
                          sx: {
                            padding: "10px",
                            fontSize:"13px"
                          },
                        }}
                      />
                    )}
                    ListboxProps={{
                      style: {
                        maxHeight: 300,
                        overflowY: "auto",
                        fontSize:"13px"
                      },
                    }}
                  />
                </FormControl>
                {validationErrors.state && (
                  <FormHelperText
                    sx={{
                      color: "error.main",
                      marginLeft: "14px",
                      fontSize: "0.75rem !important",
                    }}
                  >
                    {validationErrors.state}
                  </FormHelperText>
                )}
              </Box>

              {store === "sa_ar" || store === "sa_en" ? (
                <Box className="input-box">
                  <p>
                    {t("auth.checkout.city")}
                    <span style={{ color: "#EA0C0C" }}>*</span>
                  </p>
                  <FormControl fullWidth>
                    <Select
                      // IconComponent={CustomSelectIcon}
                      labelId="city-simple-select-label"
                      id="city-simple-select"
                      required
                      value={selectedCity}
                      onChange={handleCityChange}
                      // error={validationErrors.region.region !== ""}
                      disabled={!custState}
                      error={!!validationErrors.city}
                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: 300,
                            overflowY: "auto",
                            fontSize:"13px"
                          },
                        },
                      }}
                      sx={{
                        "& .MuiSelect-select": {
                          fontSize: "13px", 
                        },
                      }}
                    >
                      {filteredCities?.map((city: any) => (
                        <MenuItem key={city?.city_code} value={city?.city_code}>
                          {store === "sa_en"
                            ? city?.city_name_en
                            : city?.city_name_ar}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  {validationErrors.city && (
                    <FormHelperText
                      sx={{
                        color: "error.main",
                        marginLeft: "14px",
                        fontSize: "0.75rem !important",
                      }}
                    >
                      {validationErrors.city}
                    </FormHelperText>
                  )}
                </Box>
              ) : (
                <Box className="input-box">
                  {store === "in" ? (
                    <p> {t("auth.checkout.city")}</p>
                  ) : (
                    <p> {t("auth.checkout.town")}</p>
                  )}
                  <TextField
                    autoComplete="address-level2"
                    name="city"
                    required
                    fullWidth
                    id="city"
                    value={formData.city}
                    onChange={handleInputChange}
                    error={!!validationErrors.city}
                    helperText={validationErrors.city}
                    sx={{
                      "& .MuiInputBase-input": {
                        fontSize: "13px",
                      },
                    }}
                    FormHelperTextProps={{
                      sx: {
                        color: "error.main !important",
                        fontSize: "0.75rem !important",
                      },
                    }}
                  />
                </Box>
              )}

              <Box className="input-box">
                <p>{t("auth.checkout.country")}</p>
                <FormControl fullWidth>
                  <Select
                    name="country"
                    disabled={true}
                    value={selectedCountry}
                    // onChange={handleInputChange}
                    required
                    sx={{
                      "& .MuiInputBase-input": {
                        fontSize: "13px",
                      },
                    }}
                  >
                    {countryRegRes?.countries &&
                      countryRegRes?.countries.map((option: any) => {
                        return (
                          <MenuItem
                            key={option?.two_letter_abbreviation}
                            value={option?.two_letter_abbreviation}
                          >
                            {option?.full_name_english}
                          </MenuItem>
                        );
                      })}
                    {/* Add other countries as needed */}
                  </Select>
                </FormControl>
              </Box>
            </Box>
            <Box className="input-grp">
              {/* { store !== ("ae_en" || "ae_ar") && */}
              <Box className="input-box">
                {store === "in" ? (
                  <p> {t("auth.checkout.Pincode")}</p>
                ) : (
                  <p> {t("auth.checkout.Postcode")}</p>
                )}
                <TextField
                  autoComplete="postcode"
                  name="postcode"
                  required
                  fullWidth
                  id="postcode"
                  value={formData.postcode}
                  onChange={handleInputChange}
                  error={!!validationErrors.postcode}
                  helperText={validationErrors.postcode}
                  sx={{
                    "& .MuiInputBase-input": {
                      fontSize: "13px",
                    },
                  }}
                  FormHelperTextProps={{
                    sx: {
                      color: "error.main !important",
                      fontSize: "0.75rem !important",
                    },
                  }}
                />
              </Box>
              {/* } */}
              <Box className="input-box">
                <p>{t("auth.checkout.street_add")}</p>
                <TextField
                  autoComplete="address-line1"
                  name="street"
                  required
                  fullWidth
                  id="address"
                  value={formData.street}
                  onChange={handleInputChange}
                  error={!!validationErrors.street}
                  helperText={validationErrors.street}
                  sx={{
                    "& .MuiInputBase-input": {
                      fontSize: "13px",
                    },
                  }}
                  FormHelperTextProps={{
                    sx: {
                      color: "error.main !important",
                      fontSize: "0.75rem !important",
                    },
                  }}
                />
              </Box>
            </Box>

            <Box className="update-btn">
              <button className="active" type="submit">
                {t("auth.checkout.use_address")}
              </button>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default ProfileAddress;
