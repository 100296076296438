import React from 'react';
import { Link } from 'react-router-dom';

interface MessageWithLinksProps {
  message: string;
  store: string;
}

const MessageWithLinks: React.FC<MessageWithLinksProps> = ({ message, store }) => {
  if (!message) return null;

  const parts = message.split(/(password|sign up)/i);

  return (
    <>
      {parts.map((part, index) => {
        if (/password/i.test(part)) {
          return (
            <Link
              key={`password-${index}`}
              to={`/${store}/forgotpassword`}
              style={{ color: "#10CDF2", textDecoration: "none" }}
            >
              {part}
            </Link>
          );
        }

        if (/sign up/i.test(part)) {
          return (
            <Link
              key={`signup-${index}`}
              to={`/${store}/registration`}
              style={{ color: "#10CDF2", textDecoration: "none" }}
            >
              {part}
            </Link>
          );
        }

        return <span key={`text-${index}`}>{part}</span>;
      })}
    </>
  );
};

export default MessageWithLinks;
