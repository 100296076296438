import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  MenuItem,
  Pagination,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import "./ReturnItems.scss";
import NoReturn from "./noReturnRequests/NoReturn";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useQuery } from "@apollo/client";
import * as queries from "../../../../operations/queries";
import { CountryContext } from "../../MainComponent";
import Loader from "../loader/Loader";

const ReturnItems = () => {
  const { store } = useContext(CountryContext);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const loggedInToken = localStorage.getItem("loggedInCust");
  const { t } = useTranslation();

  const array = [
    { value: 10, label: "10" },
    { value: 15, label: "15" },
    { value: 20, label: "20" },
    { value: 30, label: "30" },
  ];

  //eslint-disable-next-line
  const {
    data : returnListData,
    refetch: refetchListData,
    loading: returnLoading,
  } = useQuery(queries.returnQueries.GET_RETURN_REQUESTS, {
    variables: { type: "list" },
    fetchPolicy: "network-only",
    context: {
      headers: {
        Authorization: loggedInToken
          ? `Bearer ${JSON.parse(loggedInToken)}`
          : "",
        Connection: "keep-alive",
        store: store,
      },
    },
  });

  const returnRequestList = returnListData?.getReturnRequests;

  useEffect(() => {
    if (returnListData) {
      refetchListData();
    }
  }, [returnListData, refetchListData,store]);

  const filteredReturnRequests = returnRequestList?.filter(
    (item: any) => item?.return_status === "" 
  );
  const returnRequestsWithPendingStatus = filteredReturnRequests?.map(
    (item: any) => ({
      ...item,
      status: t("buyer_dashboard.return.pending"),
    })
  );

  const handlePageChange = (event: any, newPage: any) => {
    setCurrentPage(newPage);
  };

  const handlePageSizeChange = (event: any) => {
    setPageSize(event.target.value);
    setCurrentPage(1);
  };

  const totalItems = returnRequestsWithPendingStatus?.length;
  const totalPages = Math.ceil(totalItems / pageSize);
  const paginatedItems = returnRequestsWithPendingStatus
    ?.sort(
      (a: any, b: any) =>
        new Date(b.refund_date).getTime() - new Date(a.refund_date).getTime()
    )
    ?.slice((currentPage - 1) * pageSize, currentPage * pageSize);

  return (
    <div className="return-items">
      <div className="heading-sec">
        <div className="name-sec">
          <h2 className="title">{t("buyer_dashboard.return.title")}</h2>
          <p className="subtitle">{t("buyer_dashboard.return.return_desc")}</p>
        </div>

        {returnRequestsWithPendingStatus?.length !== 0 && (
          <div className="btn-sec">
            <Link to={`return-completed`}>
              <button style={{ cursor: "pointer" }}>
                {t("buyer_dashboard.return.return_completed_btn")}
              </button>
            </Link>
          </div>
        )}
      </div>

      {returnRequestsWithPendingStatus?.length === 0 ? (
        <NoReturn />
      ) : !returnLoading ? (
        <>
          <div className="desktop-view">
            <Box
              sx={{
                overflowX: "auto",
                "&::-webkit-scrollbar": {
                  height: "6px",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "#888",
                  borderRadius: "10px",
                },
                "&::-webkit-scrollbar-thumb:hover": {
                  backgroundColor: "#555",
                },
                "&::-webkit-scrollbar-track": {
                  backgroundColor: "#f1f1f1",
                },
              }}
            >
              <Table sx={{ backgroundColor: "white" }}>
                <TableHead>
                  <TableRow>
                    <TableCell className="header-cell">
                      {t("buyer_dashboard.return.product")}
                    </TableCell>
                    <TableCell className="header-cell">
                      {t("buyer_dashboard.return.order")}
                    </TableCell>
                    <TableCell className="header-cell">
                      {t("buyer_dashboard.return.product_name")}
                    </TableCell>
                    <TableCell className="header-cell">
                      {t("buyer_dashboard.return.date")}
                    </TableCell>
                    <TableCell className="header-cell">
                      {t("buyer_dashboard.return.price")}
                    </TableCell>
                    <TableCell className="header-cell">
                      {t("buyer_dashboard.return.status")}
                    </TableCell>
                  </TableRow>
                </TableHead>
                {/* {loading ? (
                <div
                  style={{
                    display: "flex",
                    marginTop: "20px",
                    alignItems: "center",
                    textAlign:"center",
                    marginLeft:'50px'
                  }}
                >
                  <CircularProgress />
                </div>
              ) : ( */}
                <TableBody>
                  {paginatedItems?.map((item: any) => (
                    <TableRow key={item?.id}>
                      <TableCell className="body-cell">
                        {item?.product_image ? (
                          <img
                            src={item?.product_image}
                            alt="Product"
                            className="product-image"
                          />
                        ) : (
                          <span className="no-image">No Image</span>
                        )}
                      </TableCell>
                      <TableCell className="body-cell">
                        {item.order_number}
                      </TableCell>
                      <TableCell
                        className="body-cell"
                        sx={{ maxWidth: "39px" }}
                      >
                        {item.product_name}
                      </TableCell>
                      <TableCell className="body-cell">
                        {new Date(item.refund_date).toLocaleDateString("en-GB")}
                      </TableCell>
                      <TableCell className="body-cell">
                        {(Number(item?.refundable_amount) || 0).toFixed(2)}
                      </TableCell>
                      <TableCell className="body-cell">
                        <span className={`status ${item.status.toLowerCase()}`}>
                          {item.status}
                        </span>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
                {/* )} */}
              </Table>
            </Box>
          </div>

          <div className="mobile-view">
            {paginatedItems?.map((item: any) => (
              <div className="table-container" key={item.id}>
                <Table sx={{ backgroundColor: "white" }}>
                  <TableBody>
                    <TableRow>
                      <TableCell className="header">Product</TableCell>
                      <TableCell>
                        {item.product_image ? (
                          <img
                            src={item.product_image}
                            alt="Product"
                            className="product-image"
                          />
                        ) : (
                          <span className="no-image">No Image</span>
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className="header">
                        {t("buyer_dashboard.return.order")}
                      </TableCell>
                      <TableCell>{item.order_number}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className="header">
                        {t("buyer_dashboard.return.product_name")}
                      </TableCell>
                      <TableCell>{item.product_name}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className="header">
                        {t("buyer_dashboard.return.date")}
                      </TableCell>
                      <TableCell>
                        {new Date(item.refund_date).toLocaleDateString("en-GB")}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className="header">
                        {t("buyer_dashboard.return.price")}
                      </TableCell>
                      <TableCell>
                        {" "}
                        {Number(item.refundable_amount).toFixed(2)}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className="header">
                        {t("buyer_dashboard.return.status")}
                      </TableCell>
                      <TableCell>
                        <span className={`status ${item.status.toLowerCase()}`}>
                          {item.status}
                        </span>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </div>
            ))}
          </div>
          {totalItems > 9 && (
            <Box className="deskPagination">
              <Stack className="pagination-controls">
                <Typography className="items-label">
                  {t("buyer_dashboard.myOrderList.Item_per_page")}:
                </Typography>
                <Select
                  value={pageSize}
                  onChange={handlePageSizeChange}
                  variant="outlined"
                  size="small"
                  className="items-select"
                >
                  {array.map((size: any) => (
                    <MenuItem key={size.value} value={size.value}>
                      {size.label}
                    </MenuItem>
                  ))}
                </Select>
              </Stack>

              <Pagination
                count={totalPages}
                page={currentPage}
                onChange={handlePageChange}
                shape="rounded"
              />
            </Box>
          )}
        </>
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default ReturnItems;
