import { gql } from "@apollo/client";

export const GET_RETURN_REQUESTS = gql`
  query GetReturnRequests($type: String!) {
    getReturnRequests(type: $type) {
      id
      customer_email
      customer_name
      order_number
      item_id
      refund_date
      refund_type
      detailed_reason
      status
      is_product_open
      product_name
      product_image
      refundable_amount
      createdAt
      updated_at
      aws_generated_status
      return_status
    }
  }
`;
