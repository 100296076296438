import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import "./HelpCenter.scss";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { CountryContext, mainContext } from "../../container/buyer/MainComponent";
import { helpTopics } from "./dummyAPI/HelpCenterData";
import HelpCenterDetails from "./details/HelpCenterDetails";

interface SubTopic {
  name: string;
  linkUrl: string;
  details: any;
}

interface LinkInfo {
  name: string;
  linkUrl: string;
  details: any;
}

interface HelpTopic {
  category: string;
  subTopics: SubTopic[];
  additionalInfo?: LinkInfo[];
}

interface ManagementLink {
  title: string;
  desc: string;
  link: string;
  icon: string;
}

const HelpCenter: React.FC = () => {
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [filteredResults, setFilteredResults] = useState<LinkInfo[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<HelpTopic | null>(
    null
  );
  const [selectedSubTopic, setSelectedSubTopic] = useState<SubTopic | null>(
    null
  );
  const [detailsData, setDetailsData] = useState<any>();
  // const {
  //   detailsData,
  //   setDetailsData
  // } = useContext(mainContext);
  // console.log("detailsData", detailsData);
  const navigate = useNavigate();
  const { store } = useContext(CountryContext);
  const location= useLocation()

  const managementLinks: ManagementLink[] = [
    {
      title: "Your Orders",
      desc: "Track packages, Edit or cancel orders",
      link: `/${store}/buyerdashboard/my-order-list`,
      icon: "📦",
    },
    {
      title: "Returns and Refunds",
      desc: "Return or exchange items, Print return mailing labels",
      link: `/${store}/buyerdashboard/my-returns`,
      icon: "📬",
    },
    {
      title: "Manage Addresses",
      desc: "Update your addresses, Add address, landmark details",
      link: `/${store}/buyerdashboard`,
      icon: "🏠",
    },
    {
      title: "Payment Settings",
      desc: "Add or edit payment methods, Change expired debit or credit card",
      link: `/${store}/buyerdashboard/payment`,
      icon: "💳",
    },
    {
      title: "Account Settings",
      desc: "Change your password, Update login information",
      link: `/${store}/buyerdashboard`,
      icon: "👤",
    },
    {
      title: "Wishlist Settings",
      desc: "Add or edit your wishlist items or products.",
      link: `/${store}/buyerdashboard/my-wishlist`,
      icon: "🛒",
    },
  ];

  // const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   const query = e.target.value.toLowerCase();
  //   setSearchQuery(query);

  //   if (query.length > 0) {
  //     const results: LinkInfo[] = helpTopics.flatMap((topic) => [
  //       ...topic.subTopics.flatMap((subTopic) => subTopic.details),
  //       ...(topic.additionalInfo || []),
  //     ]).filter((info) => info.text.toLowerCase().includes(query));

  //     setFilteredResults(results);
  //   } else {
  //     setFilteredResults([]);
  //   }
  // };

  // const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   const query = e.target.value.toLowerCase();
  //   setSearchQuery(query);

  //   if (query.length > 0) {
  //     // Flatten subTopics, additionalInfo, and their details into a single array
  //     const results: LinkInfo[] = helpTopics.flatMap((topic) => [
  //       ...topic.subTopics.flatMap((subTopic) => [
  //         {
  //           name: subTopic.name,
  //           linkUrl: subTopic.linkUrl,
  //           details: subTopic.details,
  //         }
  //       ]),
  //       ...(topic.additionalInfo || []).flatMap((info) => [
  //         {
  //           name: info.name,
  //           linkUrl: info.linkUrl,
  //           details: info.details,
  //         }
  //       ]),
  //     ]).filter((item) => item.name.toLowerCase().includes(query));

  //     setFilteredResults(results);
  //   } else {
  //     setFilteredResults([]);
  //   }
  // };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);

    if (query.length > 0) {
      const stripHtml = (html: string) =>
        html.replace(/<[^>]*>/g, "").toLowerCase();

      const results: LinkInfo[] = [];

      helpTopics.forEach((topic) => {
        topic.subTopics.forEach((subTopic) => {
          if (subTopic.name.toLowerCase().includes(query)) {
            results.push({
              name: subTopic.name,
              linkUrl: subTopic.linkUrl,
              details: subTopic.details,
            });
          } else {
            const foundInDetails = subTopic.details.some((detail: string) =>
              stripHtml(detail).includes(query)
            );

            if (foundInDetails) {
              results.push({
                name: subTopic.name,
                linkUrl: subTopic.linkUrl,
                details: subTopic.details,
              });
            }
          }
        });

        if (topic.additionalInfo) {
          topic.additionalInfo.forEach((info) => {
            if (info.name.toLowerCase().includes(query)) {
              results.push({
                name: info.name,
                linkUrl: info.linkUrl,
                details: info.details,
              });
            } else {
              const foundInDetails = info.details.some((detail: string) =>
                stripHtml(detail).includes(query)
              );

              if (foundInDetails) {
                results.push({
                  name: info.name,
                  linkUrl: info.linkUrl,
                  details: info.details,
                });
              }
            }
          });
        }
      });

      setFilteredResults(results);
    } else {
      setFilteredResults([]);
    }
  };

  // const currentPath = window.location.pathname;
  // const basePath = currentPath.split("/help")[0];

  const handleSubTopicClick = (subTopic: SubTopic) => {
    // const navigateLink = `${basePath}/cms/${subTopic?.linkUrl}`;
    // navigate(orderLink);
    setDetailsData(subTopic);
    console.log("subTopic", subTopic);
  };

  

useEffect(() => {
  if(detailsData){
    navigate(`details/${detailsData?.linkUrl}`, { state: { data : detailsData } });
  }
},[navigate,detailsData])


  const handleAdditionalInfoClick = (info: LinkInfo) => {
    // const navigateLink = `${basePath}/cms/${info?.linkurl}`;
    navigate(`details/${info?.linkUrl}`, { state: { data: info } });
    console.log("info", info);
  };

  const isLoggedIn = () => {
    return !!localStorage.getItem("loggedInCust");
  };

  const handleNavigation = (path: string) => {
    if (isLoggedIn()) {
      navigate(path);
    } else {
      toast.error("Please log in first!");
      navigate(`/${store}/login`, { state: { from: location.pathname } });
    }
  };
  const handleSubTopicSelect = (topic : any) => {
    setSelectedCategory(topic)
    setSelectedSubTopic(topic)
  }

  return (
    <div className="help-center directionrtl">
      {/* Section: Quick Management Options */}
      <div className="management-options-title">
        <h2>Help Center , What can we help you with?</h2>
        <p className="description">
          Welcome to our Help Center! Here, you'll find answers to common
          questions about orders, services, and more. Select an option below to
          get started.If you need further assistance, feel free to reach out to
          our support team.
        </p>
      </div>

      <div className="management-options">
        {managementLinks?.map((item, index) => (
          <div
            key={index}
            className="help-box"
            onClick={() => handleNavigation(item.link)}
            style={{ cursor: "pointer" }}
          >
            <span className="icon">{item.icon}</span>
            <div>
              <p className="title">{item.title}</p>
              <p className="desc">{item.desc}</p>
            </div>
          </div>
        ))}
      </div>

      <div>
        {/* Search Bar Title */}
        <div className="search-bar-title">
          <h2>Help & Customer Service</h2>
        </div>

        <div className="search-suggestion-text">
          Type something like, <strong>"Searching about Return an Item"</strong>
        </div>

        {/* Search Bar */}
        <div className="search-bar">
          <div className="search-input-container">
            <span className="search-icon">
              <SearchIcon />
            </span>
            <input
              type="text"
              placeholder="Search help topics..."
              value={searchQuery}
              onChange={handleSearch}
            />
          </div>
        </div>

        {/* Section: Search Suggestions */}
        {searchQuery && filteredResults.length > 0 && (
          <div className="search-suggestions">
            <div className="suggestions-dropdown">
              {filteredResults.map((info, index) => (
                <div
                  key={index}
                  className="suggestion-item"
                  onClick={() => handleAdditionalInfoClick(info)}
                >
                  {info.name}
                </div>
              ))}
            </div>
          </div>
        )}
      </div>

      <div className="browse-topics">
        <h3>Browse Help Topics</h3>
        <div className="help-container">
          <div className="column fixed-width">
            <h4>Recommended Topics</h4>
            {helpTopics.map((topic, index) => (
              <div
                key={index}
                className={`category ${
                  selectedCategory === topic ? "active" : ""
                }`}
                onMouseEnter={() => handleSubTopicSelect(topic) 
                }
              >
                {topic.category}
              </div>
            ))}
          </div>

          {selectedCategory && (
            <div className="column">
              <h4>Reach Out Reason</h4>
              {selectedCategory.subTopics.map((sub, index) => (
                <div
                  key={index}
                  className={`subtopic ${
                    selectedSubTopic === sub ? "active" : ""
                  }`}
                  onClick={() => handleSubTopicClick(sub)}
                >
                  {sub.name}
                </div>
              ))}
            </div>
          )}

          {selectedCategory && selectedCategory.additionalInfo && (
            <div className="column">
              <h4>Other</h4>
              {selectedCategory.additionalInfo.map((info, index) => (
                <div
                  key={index}
                  className="subtopic"
                  onClick={() => handleAdditionalInfoClick(info)}
                >
                  {info.name}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>

      <footer className="footer">
        <p style={{ textAlign: "center" }}>
          Need more help? Visit our <Link to="#">Support Center</Link> or
          contact us for personalized assistance.
        </p>
        <div className="coffee-section">
          <p>Take a break, grab a coffee, and let us help you!</p>
          <div className="coffee-gifs">
            {/* <img
              src="https://i.pinimg.com/originals/a3/7d/03/a37d03e9e6ad102a4f84c77413ac2673.gif"
              alt="Coffee pouring"
              className="coffee-gif"
            /> */}
          </div>
          <p>
            Enjoy a warm cup of coffee while we assist you with your queries.
            Good things happen over coffee!
          </p>
        </div>
      </footer>
      {detailsData && <HelpCenterDetails detailsData={detailsData} />}
    </div>
  );
};

export default HelpCenter;
