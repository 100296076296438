import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  // SelectChangeEvent,
  TextField,
} from "@mui/material";
import React, { useContext, useEffect, useMemo, useState } from "react";
import "./Profile.scss";
import * as mutations from "../../../../operations/mutations";
import * as queries from "../../../../operations/queries";
import { useMutation, useQuery } from "@apollo/client";
import { toast } from "react-toastify";
import { CountryContext, mainContext } from "../../MainComponent";
import ChangePasswordModal from "./changePwd/ChangePasswordModal";
// import AddPhoneNumber from "./addPhoneNumber/AddPhoneNumber";
// import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import DeleteAccountModal from "./deleteAccount/DeleteAccountModal";
import { useTranslation } from "react-i18next";

const Profile = () => {
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [isChangePasswordOpen, setChangePasswordOpen] = useState(false);
  // const [addPhoneNumberOpen, setAddPhoneNumberOpen] = useState(false);
  const [deleteAccOpen, setDeleteAccOpen] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState("");
  const { store } = useContext(CountryContext);
  const { setIsUpdate } = useContext(mainContext);
  const loggedInToken = localStorage.getItem("loggedInCust");
  const { i18n } = useTranslation();
  const { t } = useTranslation();
  const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);
  const handleLanguageChange = (event: any) => {
    setSelectedLanguage(event.target.value as string);
  };

  const { data: custDetailsDataRes, refetch: refetchCustInfo } = useQuery(
    queries.customerQueries.GET_CUSTOMER_INFO,
    {
      skip:!loggedInToken,
      fetchPolicy:"network-only",
      context: {
        headers: {
          Authorization: loggedInToken
            ? `Bearer ${JSON.parse(loggedInToken)}`
            : "",
          Connection: "keep-alive",
        },
      },
    }
  );
  const custDetails = custDetailsDataRes?.customer;

  useEffect(()=>{
    setFirstname(custDetails?.firstname || "");
        setLastname(custDetails?.lastname || "");
        setEmail(custDetails?.email || "");
  },[store,custDetails])

  const onCountryChange = async () => {
    const store_code = await localStorage.getItem("store_code");
    if (store_code === "ae_en" || store_code === "ae_ar") {
      setSelectedCountry("AE");
    } else if (store_code === "sa_en" || store_code === "sa_ar") {
      setSelectedCountry("SA");
      localStorage.setItem("getCountryCode", "SA");
    } else if (store_code === "en" || store_code === "ar") {
      setSelectedCountry("OM");
    } else if (store_code === "in") {
      setSelectedCountry("IN");
    }
  };

  useEffect(() => {
    if (store?.length > 0) {
      localStorage.setItem("store_code", store);
    }
    onCountryChange();
  }, [store]);

  // const addresses = custDetails?.addresses?.slice(-3) || [];

  const filteredAddresses = useMemo(() => {
    if (custDetailsDataRes?.customer?.addresses) {
      // Filter addresses by selected country
      const addressesByCountry = custDetailsDataRes.customer.addresses.filter(
        (data: any) => data.country_code === selectedCountry
      );

      return addressesByCountry.slice(-3);
    }
    refetchCustInfo();
    return [];
    // eslint-disable-next-line
  }, [custDetailsDataRes, selectedCountry]);

  const [selectedAddress, setSelectedAddress] = useState("");

  useEffect(() => {
    if (filteredAddresses.length > 0) {
      setSelectedAddress((prev) => prev || filteredAddresses[0].id);
    }
  }, [filteredAddresses,custDetails]);

  const handleChangeRadio = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedAddress(event.target.value);
  };

  const [updateCustomer] = useMutation(
    mutations.customerMutation.UPDATE_CUSTOMER,
    {
      context: {
        headers: {
          Authorization: loggedInToken
            ? `Bearer ${JSON.parse(loggedInToken)}`
            : "",
          Connection: "keep-alive",
        },
      },
    }
  );

  useEffect(() => {
    // Enable/disable button based on changes
    if (custDetailsDataRes?.customer) {
      setIsButtonDisabled(
        firstname === custDetailsDataRes.customer.firstname &&
          lastname === custDetailsDataRes.customer.lastname 
          // &&
          // selectedLanguage === i18n.language
      );
    }
  }, [
    firstname,
    lastname,
    selectedLanguage,
    custDetailsDataRes,
    // i18n.language,
  ]);

  // useEffect(() => {
  //   if (successAddress) {
  //     refetchCustInfo();
  //   } // eslint-disable-next-line
  // }, [successAddress]);

  const handleUpdate = () => {
    i18n.changeLanguage(selectedLanguage);
    document.documentElement.setAttribute("lang", selectedLanguage);

    if (selectedLanguage === "en") {
      document.body.classList.add("en");
      document.body.classList.remove("ar");
    } else {
      document.body.classList.add("ar");
      document.body.classList.remove("en");
    }

    updateCustomer({
      variables: {
        input: {
          firstname,
          lastname,
        },
      },
    })
      .then(() => {
        toast.success(t("buyer_dashboard.profileSection.update_toast"));
        setIsUpdate(true)
        refetchCustInfo();
      })
      .catch((error) => {
        console.error("Error updating customer:", error);
      });
  };

  useEffect(() => {
    document.body.classList.toggle("rtl", i18n.language === "ar");
    document.body.classList.toggle("ltr", i18n.language === "en");
    setSelectedLanguage(i18n.language)
  }, [i18n.language]);

  useEffect(() => {
    if (loggedInToken && loggedInToken !== "" && loggedInToken !== null) {
      refetchCustInfo();
    }
  }, [loggedInToken, refetchCustInfo , store]);

  const handleOpenChangePassword = () => setChangePasswordOpen(true);
  const handleCloseChangePassword = () => setChangePasswordOpen(false);

  // const handleOpenAddPhoneNumber = () => setAddPhoneNumberOpen(true);
  // const handleCloseAddPhoneNumber = () => setAddPhoneNumberOpen(false);

  const handleOpenDeleteAcc = () => setDeleteAccOpen(true);
  const handleCloseDeleteAcc = () => setDeleteAccOpen(false);

  return (
    <>
      <div className="profile-box">
        <div className="profile-title">
          <h4>{t("buyer_dashboard.profileSection.title")}</h4>

          <p>{t("buyer_dashboard.profileSection.desc")}</p>
        </div>

        <div className="general-info-box">
          <h4>{t("buyer_dashboard.profileSection.gen_info")}</h4>

          <hr />

          <div className="input-grp">
            <div className="input-box">
              <p>{t("buyer_dashboard.profileSection.f_name")}</p>
              <TextField
                autoComplete="given-name"
                name="firstname"
                required
                fullWidth
                id="firstname"
                value={firstname}
                onChange={(e) => setFirstname(e.target.value)}
                sx={{
                  "& .MuiInputBase-input": {
                    fontSize: "13px",
                  },
                }}
              />
            </div>

            <div className="input-box">
              <p>{t("buyer_dashboard.profileSection.l_name")}</p>
              <TextField
                autoComplete="family-name"
                name="lastname"
                required
                fullWidth
                id="lastname"
                value={lastname}
                onChange={(e) => setLastname(e.target.value)}
                sx={{
                  "& .MuiInputBase-input": {
                    fontSize: "13px",
                  },
                }}
              />
            </div>

            <div className="input-box dropdown">
              <p>{t("buyer_dashboard.profileSection.language")}</p>
              <Select
                value={selectedLanguage}
                onChange={handleLanguageChange}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                sx={{ fontSize: "13px" }}
                disabled
              >
                <MenuItem value="en" sx={{ fontSize: "13px" }}>
                  English
                </MenuItem>
                {store !== "in" && (
                  <MenuItem value="ar" sx={{ fontSize: "13px" }}>
                    Arabic
                  </MenuItem>
                )}
              </Select>
            </div>
          </div>

          <div className="update-btn">
            <Button
              variant="contained"
              color="primary"
              disabled={isButtonDisabled}
              onClick={handleUpdate}
            >
              {t("buyer_dashboard.profileSection.update_btn")}
            </Button>
          </div>
        </div>

        <div className="address-box">
          <Box
            sx={{
              display: "flex",
              gap: "15px",
              justifyContent: "space-between",
            }}
          >
            <Box>
              <h4>{t("buyer_dashboard.profileSection.address")}</h4>
            </Box>

            <Box>
              {filteredAddresses.length > 0 && (
                <>
                  <Box className="address-grp">
                    <Box className="no-address">
                      <p>{t("buyer_dashboard.profileSection.add_desc")}</p>
                      <Link to={`add-new-address?props=add-address`}>
                        <button>
                          {t("buyer_dashboard.profileSection.link_btn")}
                        </button>
                      </Link>
                    </Box>
                  </Box>
                </>
              )}
            </Box>
          </Box>

          <hr />

          {filteredAddresses.length <= 0 ? (
            <Box className="address-grp">
              <div className="no-address">
                <div>
                  <h5>{t("buyer_dashboard.profileSection.no_address")}</h5>
                  <p>{t("buyer_dashboard.profileSection.no_address_desc")}</p>
                </div>
                <Link to={`add-new-address?props=add-address`}>
                  <button>
                    {t("buyer_dashboard.profileSection.link_btn")}
                  </button>
                </Link>
              </div>
            </Box>
          ) : (
            // <Box className="address-grp">
            //   <Box className="address-selection">
            //     {addresses.map((address: any, index: number) => (
            //       <Box key={address.id || index} className="address-list">
            //         <Radio
            //           checked={selectedAddress === address.id}
            //           onChange={handleChangeRadio}
            //           value={address.id}
            //           name="radio-buttons"
            //           inputProps={{ "aria-label": `Address ${index + 1}` }}
            //         />
            //          <Link to={`add-new-address?props=edit-address&id=${address.id}`}>
            //         <Box className="address-details">
            //           <p>{address.firstname}</p>
            //           <p>{address.telephone}</p>
            //           <p>{address.city}</p>
            //           <p>{address.street[0]}</p>
            //           <p>{address.postcode}</p>
            //         </Box>
            //         </Link>
            //       </Box>
            //     ))}
            //   </Box>
            // </Box>
            <Box
              className="address-selection"
              sx={{ marginTop: "10px", marginLeft: "40px" }}
            >
              <RadioGroup value={selectedAddress} onChange={handleChangeRadio}>
                <Grid container spacing={3}>
                  {filteredAddresses?.map((address: any, index: number) => (
                    <Grid
                      item
                      xs={12}
                      sm={4}
                      key={address.id || index}
                      sx={{
                        padding: "15px",
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                        position: "relative",
                        borderRight:
                          filteredAddresses.length > 1 &&
                          store.includes("ar")
                            ? "none"
                            : {
                                sm:
                                  index !== filteredAddresses.length - 1
                                    ? "1px solid #ddd"
                                    : "none",
                                xs: "none",
                              },
                        borderLeft:
                          filteredAddresses.length > 1 &&
                          store.includes("ar")
                            ? {
                                sm:
                                  index !== filteredAddresses.length - 1
                                    ? "1px solid #ddd"
                                    : "none",
                                xs: "none",
                              }
                            : "none",
                        borderBottom:
                          filteredAddresses.length > 1
                            ? {
                                xs:
                                  index !== filteredAddresses.length - 1
                                    ? "1px solid #ddd"
                                    : "none",
                                sm: "none",
                              }
                            : "none",
                        marginTop: "10px",
                      }}
                    >
                      <FormControlLabel
                        value={address.id}
                        control={
                          <Radio
                            sx={{ position: "relative", marginRight: "10px" }}
                          />
                        }
                        label={
                          <Box
                            sx={{ fontSize: "14px", lineHeight: "1.8" }}
                            className="address-details"
                          >
                            <Link
                              to={`add-new-address?props=edit-address&id=${address.id}`}
                              style={{
                                textDecoration: "none",
                                color: "inherit",
                              }}
                            >
                              <p
                                style={{
                                  margin: "0",
                                  textDecoration: "underline",
                                }}
                              >
                                {address.firstname} {address.lastname}
                              </p>
                              <p
                                style={{
                                  margin: "0",
                                  textDecoration: "underline",
                                }}
                              >
                                {address.telephone}
                              </p>
                              <p
                                style={{
                                  margin: "0",
                                  textDecoration: "underline",
                                }}
                              >
                                {address.city}, {address?.region?.region},{" "}
                                {address.postcode}
                              </p>
                              <p
                                style={{
                                  margin: "0",
                                  textDecoration: "underline",
                                }}
                              ></p>
                              <p
                                style={{
                                  margin: "0",
                                  textDecoration: "underline",
                                }}
                              >
                                {address.street[0]}
                              </p>
                            </Link>
                          </Box>
                        }
                      />
                    </Grid>
                  ))}
                </Grid>
              </RadioGroup>
            </Box>
          )}
        </div>

        <div className="security-box">
          <h4>{t("buyer_dashboard.profileSection.security")}</h4>

          <hr />

          <div className="input-grp">
            <div className="input-box">
              <p>{t("buyer_dashboard.profileSection.email")}</p>

              <TextField
                autoComplete="given-name"
                name="email"
                required
                fullWidth
                id="email"
                value={email}
                disabled
                sx={{
                  pointerEvents: "none",
                  "& .MuiInputBase-input": {
                    fontSize: "13px",
                  },
                }}
              />
            </div>

            <div className="input-box">
              <p>{t("buyer_dashboard.profileSection.password")}</p>

              <TextField
                autoComplete="password"
                name="password"
                required
                fullWidth
                id="password"
                disabled
                value="********"
                sx={{
                  cursor: "not-allowed",
                  "& .MuiInputBase-input": {
                    fontSize: "13px",
                  },
                }}
              />
            </div>

            {/* <div className="input-box dropdown">
              <p>{t("buyer_dashboard.profileSection.ph_number")}</p>
              <Select
                value={age}
                onChange={handleChange}
                displayEmpty
                sx={{ fontSize: "13px",padding:'12px' }}
              >
                <MenuItem value="" sx={{ fontSize: "13px" }}>Phone Number</MenuItem>
                <MenuItem value={10} sx={{ fontSize: "13px" }}>Phone Number 1</MenuItem>
                <MenuItem value={20} sx={{ fontSize: "13px" }}>Phone Number 2</MenuItem>
                <MenuItem value={30} sx={{ fontSize: "13px" }}>Phone Number 3</MenuItem>
              </Select>
            </div> */}
          </div>

          <div className="update-btn-grp">
            <button
              className="update-password-btn"
              onClick={handleOpenChangePassword}
            >
              {t("buyer_dashboard.profileSection.change_pwd_btn")}
            </button>
            {isChangePasswordOpen && (
              <ChangePasswordModal
                isOpen={isChangePasswordOpen}
                handleClose={handleCloseChangePassword}
              />
            )}
            {/* <button
              className="add-phone-btn"
              onClick={handleOpenAddPhoneNumber}
            >
              {t("buyer_dashboard.profileSection.add_ph_btn")}
            </button>
            {addPhoneNumberOpen && (
              <AddPhoneNumber
                isOpen={addPhoneNumberOpen}
                handleClose={handleCloseAddPhoneNumber}
              />
            )} */}
            <button
              className="delete-btn"
              onClick={handleOpenDeleteAcc}
              disabled
              style={{ cursor: "not-allowed" }}
            >
              {t("buyer_dashboard.profileSection.delete_acc")}
            </button>
            {deleteAccOpen && (
              <DeleteAccountModal
                isOpen={deleteAccOpen}
                handleClose={handleCloseDeleteAcc}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Profile;
