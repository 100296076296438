import React, { useContext, useEffect } from "react";
import "./HelpCenterDetails.scss";
import { useLocation, useNavigate } from "react-router-dom";
import { CountryContext } from "../../../container/buyer/MainComponent";
import { toast } from "react-toastify";
import { useQuery } from "@apollo/client";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import * as queries from "../../../operations/queries";
import { Box } from "@mui/material";

interface ManagementLink {
  title: string;
  desc: string;
  link: string;
  icon: string;
}

interface HelpCenterProps {
  detailsData?: {
    info?: {
      details?: string;
    };
    subTopic?: {
      details?: string;
    };
  };
}

const HelpCenterDetails: React.FC<HelpCenterProps> = ({ detailsData }) => {
  const navigate = useNavigate();
  const { store } = useContext(CountryContext);
  const location = useLocation();
  const identifier = location?.pathname?.split("/");
  const currentPath = window.location.pathname;
  const basePath = currentPath.split("/help")[0];
  console.log("basePath",basePath)
  const customHeader = {
    store: store,
    Connection: "keep-alive",
  };
// console.log("my data is",detailsData)
  // const content = location?.state
  console.log("location",location)
  const contenthtml = location?.state?.data?.details ;

  console.log("detailsData",detailsData)

  const {
    data: paymentData,
    refetch: refetchPaymentData,
    loading: paymentLoading,
  } = useQuery(queries.cmsQueries.GET_PAYMENT_PAGE, {
    variables: { identifier: identifier[identifier.length - 1] },
    context: {
      headers: customHeader,
    },
  });

  let paymentHtml = paymentData?.cmsPage?.content;
  const str = paymentHtml?.replaceAll("&lt;", "<");
  const finalStr = str?.replaceAll("&gt;", ">");
  console.log("paymentData", paymentData);

  const managementLinks: ManagementLink[] = [
    {
      title: "Your Orders",
      desc: "Track packages, Edit or cancel orders",
      link: `/${store}/buyerdashboard/my-order-list`,
      icon: "📦",
    },
    {
      title: "Returns and Refunds",
      desc: "Return or exchange items, Print return mailing labels",
      link: `/${store}/buyerdashboard/my-returns`,
      icon: "📬",
    },
    {
      title: "Manage Addresses",
      desc: "Update your addresses, Add address, landmark details",
      link: `/${store}/buyerdashboard`,
      icon: "🏠",
    },
    {
      title: "Payment Settings",
      desc: "Add or edit payment methods, Change expired debit or credit card",
      link: `/${store}/buyerdashboard/payment`,
      icon: "💳",
    },
    {
      title: "Account Settings",
      desc: "Change your password, Update login information",
      link: `/${store}/buyerdashboard`,
      icon: "👤",
    },
    {
      title: "Wishlist Settings",
      desc: "Add or edit your wishlist items or products.",
      link: `/${store}/buyerdashboard/my-wishlist`,
      icon: "🛒",
    },
  ];

  const isLoggedIn = () => {
    return !!localStorage.getItem("loggedInCust");
  };

  const handleNavigation = (path: string) => {
    if (isLoggedIn()) {
      navigate(path);
    } else {
      toast.error("Please log in first!");
      navigate(`/${store}/login`, { state: { from: location.pathname } });
    }
  };

  const handleBackButton = () => {
    const currentPath = window.location.pathname;
    const basePath = currentPath.split("/details")[0];
    navigate(basePath);
  };

  // console.log("detailsData",detailsData)

  // useEffect(() => {
  //   const handleLinkClick = (event : any) => {
  //     const link = event.target.closest("a");
  //     if (link && link.getAttribute("href").startsWith("/")) {
  //       event.preventDefault();
  //       navigate(link.getAttribute(`/${basePath}/href`));
  //     }
  //   };

  //   document.addEventListener("click", handleLinkClick);
  //   return () => document.removeEventListener("click", handleLinkClick);
  // }, [navigate]);

  return (
    <div className="help-center-details directionrtl">
      {/* Back Button */}
      <button className="back-button" onClick={handleBackButton}>
      <ArrowBackIosNewIcon sx={{ fontSize: "12px" }}/>&nbsp; Back
      </button>

      <div className="helpcenter-container">
        {/* Left Sidebar */}
        <aside className="sidebar">
          <h3>Quick solutions</h3>
          {managementLinks.map((item, index) => (
            <div
              key={index}
              className="help-box"
              onClick={() => handleNavigation(item.link)}
            >
              <span className="icon">{item.icon}</span>
              <div>
                <p className="title">{item.title}</p>
                <p className="desc">{item.desc}</p>
              </div>
            </div>
          ))}
        </aside>

        {/* Main Content */}
        <main className="content">
        {/* <div>{detailsData?.info?.details ?? detailsData?.subTopic?.details}</div> */}
          <div dangerouslySetInnerHTML={{ __html: finalStr }}></div>
          <div dangerouslySetInnerHTML={{ __html: contenthtml }}></div>
        </main>
        
      </div>
    </div>
  );
};

export default HelpCenterDetails;
