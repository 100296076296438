import React, { useState, useContext, useEffect, useMemo } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Container } from "@material-ui/core";
import * as mutations from "../../../operations/mutations";
import CardHeader from "@mui/material/CardHeader";
import { FormControl, Input, InputLabel } from "@mui/material";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Divider from "@mui/material/Divider";
import CardMedia from "@mui/material/CardMedia";
import { useSearchParams } from "react-router-dom";
import * as queries from "../../../operations/queries";
import { useQuery } from "@apollo/client";
import { CountryContext, mainContext } from "../MainComponent";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Alert from "@mui/material/Alert";
import AddNewAddress from "./AddNewAddress";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import CCAvenueForm from "../../../components/ccevenueForm/CcevenueForm";
import Modal from "../../../components/Modal/Modal";
import { toast } from "react-toastify";
import PaymentFailer from "../../../components/PaymentFal/PaymentFal/PaymentFalier";
import PreHeader from "../Layout/PreLayout/PreHeader";
import PostpayWidget from "../Innerpages/postpayStrip/postPayStrip";
import TabbyWidget from "../Innerpages/postpayStrip/tabbyStrip";
import theme from "../../../theme";
import { useMediaQuery } from "@mui/material";
import { useMutation } from "@apollo/client";
import CircularProgress from "@mui/material/CircularProgress";
import { styled } from "@mui/system";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import googleIcon from "../../../assets/images/google_icon.png";
import { useGoogleLogin } from "@react-oauth/google";
import "../../buyer/Layout/header.scss";
import "../../buyer/buyer-gen.scss";
import MessageWithLinks from "../../../components/MessageWithLinks/MessageWithLinks";
// import jwt_decode from 'jwt-decode';
// import { jwtDecode } from "jwt-decode";
// import AppleSignin from "react-apple-signin-auth";

declare const window: any;

function Checkout(props: any) {
  const [searchParams] = useSearchParams();
  const params = searchParams.get("paymentFail");
  const { store } = useContext(CountryContext);
  // const store: any = localStorage.getItem("store_code");
  // console.log("store:", store);
  const storePath = window.location.pathname;
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [custCountry, setCustCountry] = useState(String());
  // const [custState, setCustState] = useState(String());
  const [resError, setResError] = useState("");
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [paymentvalue, setPaymentValue] = useState("");
  const [isEdit, setIsEdit] = useState("");
  let loggedInToken = localStorage.getItem("loggedInCust");
  const [billingOnLoad, setBillingOnLoad] = useState(true);
  const [showPwd, setShowPwd] = useState(false);
  const [open, setOpen] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [successUrl, setSuccessUrl] = useState("");
  const [shippingToggle, setShipingToggle] = useState(false);
  const [checkShipingToggle, setCheckShipingToggle] = useState(false);
  const [procedToPayToggle, setProcedToPayToggle] = useState(false);
  const [shippingSuccess, setShippingSuccess] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState("");
  // const [isCartEmpty, setIsCartEmpty] = useState(false);
  // eslint-disable-next-line
  const [selectedRegion, setSelectedRegion] = useState({
    bill_region: "",
    region_id: 0,
  });
  const [telerToken, setTelerToken] = useState("");
  const [ccevenueRes, setCcevenueRes] = useState("");
  const [isOpenRegister, setIsOpenRegister] = useState(false);
  const [isOpenFPwd, setIsOpenFPwd] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [telrRedirectUrl, setTelrRediretUrl] = useState("");
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isSubmit, setIsSubmit] = useState(false);
  // eslint-disable-next-line
  const [billFname, setBillFname] = useState("");
  // eslint-disable-next-line
  const [billSname, setBillSname] = useState("");
  // eslint-disable-next-line
  const [billAddr1, setBillAddr1] = useState("");
  // eslint-disable-next-line
  const [billCity, setBillCity] = useState("");
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [billZip, setBillZip] = useState("00000");
  // eslint-disable-next-line
  const [billEmail, setBillEmail] = useState("");
  // eslint-disable-next-line
  const [billTel, setBillTel] = useState("");
  // eslint-disable-next-line
  const [pinCode, setPinCode] = useState("");
  const [shipAmt, setShipAmt] = useState<any>();
  const [shipAmtCurr, setShipAmtCurr] = useState("");
  const [orderSummaryData, setOrderSummaryData] = useState<any>();
  const [cartSubTotal, setCartSubTotal] = useState<any>();
  const [cartSubTotalCurr, setCartSubTotalCurr] = useState<any>();
  const [prodQty, setProQty] = useState<any>();
  const [isQtyDisabled, setIsQtyDisabled] = useState(false);
  const [vat, setVat] = useState<any>();
  const [customDuty, setCustomDuty] = useState<any>();
  const selectedStores =
    store && store !== "" ? store : storePath?.split("/")?.[1];
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selectedStore, setSelectedStore] = useState<any>(selectedStores);
  const [cartUpdated, setCartUpdated] = useState<any>();
  const [paymentFailed, setPaymentFailed] = useState(false);
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [cartItemUid, setCartItemUid] = useState("");
  const [couponCode, setCouponCode] = useState("");
  const [couponCodeSuccess, setCouponCodeSuccess] = useState(false);
  const [applyCouponToCart] = useMutation(
    mutations.couponMutation.APPLY_COUPON_MUTATION
  );
  const [removeCouponFromCart] = useMutation(
    mutations.couponMutation.REMOVE_COUPON_MUTATION
  );
  const [successAddress, setSuccessAddress] = useState(false);
  const [islogin, setIslogin] = useState<any>(
    loggedInToken && JSON.parse(loggedInToken)
  );
  const [placeOrderLoading, setPlaceOrderLoading] = useState<boolean>(false);
  const [cartDetails, setCartDetails] = useState<any>();
  const [newToken, setnewToken] = useState<any>();
  const [newResponse, setnewResponse] = useState<any>();
  const { quest_cart_id, setCartCount, set_quest_cart_id } =
    useContext(mainContext);
  const [selectedAddressId, setSelectedAddressId] = useState("");
  const store_code = localStorage.getItem("store_code");
  const [selectedAddress, setSelectedAddress] = useState({
    firstname: "",
    lastname: "",
    street: [""],
    country_code: "",
    city: "",
    postcode: "",
    telephone: "",
    company: "jebelz",
    region: "",
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (params === "true") {
      setPaymentFailed(true);
    }
  }, [params]);

  useEffect(() => {
    if (store?.length > 0) {
      localStorage.setItem("store_code", store);
    }
    onCountryChange();
  }, [store, storePath]);

  const [updateCartItems] = useMutation(
    mutations.addToCartMutation.UPDATE_CART_ITEMS,
    {
      context: {
        headers: {
          Authorization: loggedInToken
            ? `Bearer ${JSON.parse(loggedInToken)}`
            : "",
          Connection: "keep-alive",
          store: store && store !== "" ? store : storePath?.split("/")?.[1],
        },
      },
    }
  );

  const onCountryChange = async () => {
    const store_code = await localStorage.getItem("store_code");
    if (store_code === "ae_en" || store_code === "ae_ar") {
      setSelectedCountry("AE");
    } else if (store_code === "sa_en" || store_code === "sa_ar") {
      setSelectedCountry("SA");
      localStorage.setItem("getCountryCode", "SA");
    } else if (store_code === "en" || store_code === "ar") {
      setSelectedCountry("OM");
    } else if (store_code === "in") {
      setSelectedCountry("IN");
    }
  };

  const handleOpenModal = (isEdit: any) => {
    if (isEdit) {
      setIsEdit(isEdit);
    } else {
      setIsEdit("");
    }
    setOpen(true);
  };
  const handleCloseModal = () => {
    setOpen(false);
    setIsEdit("");
  };

  let subtotal: any = "";
  // eslint-disable-next-line
  let getQty: any = "";

  let subTotalAmount: number = 0;

  const shippingMethods = [
    {
      carrier_code: "",
      method_code: "",
    },
  ];

  const { data: cartDetailsRes, refetch: refetchCartData } = useQuery(
    queries.cartQueries.GET_CART_DETAILS,
    {
      variables: { cart_id: quest_cart_id },
      context: {
        headers: {
          Authorization: loggedInToken
            ? `Bearer ${JSON.parse(loggedInToken)}`
            : "",
          Connection: "keep-alive",
          store: store && store !== "" ? store : storePath?.split("/")?.[1],
        },
      },
      onCompleted: (data) => {
        return data;
      },
    }
  );
  const cartDetailsData = cartDetailsRes?.cart;
  // console.log("cartDetailsData",cartDetailsData)

  useEffect(() => {
    setCartDetails(cartDetailsData);
  }, [cartDetails, cartDetailsData]);

  useEffect(() => {
    setOrderSummaryData(cartDetails);
    const qtyData: any = [];
    cartDetails?.items?.forEach((data: any) => {
      const datas = {
        id: data?.id,
        sku: data?.product?.sku,
        qty: data?.quantity,
        uid: data?.uid,
      };
      // eslint-disable-next-line
      getQty = data?.quantity;
      setCartItemUid(data?.uid);
      // eslint-disable-next-line
      subtotal = {
        currency: data?.prices?.row_total?.currency,
        amount: data?.prices?.row_total?.value,
      };
      let cartSubTotal = data?.prices?.row_total?.value;
      // eslint-disable-next-line
      subTotalAmount += cartSubTotal;
      qtyData.push(datas);
    });
    setVat(cartDetails?.prices?.vat[0]?.amount);
    setCustomDuty(cartDetails?.prices?.custom_duty[0]?.amount);
    setProQty(qtyData);
    setCartSubTotal(subTotalAmount);
    setCartSubTotalCurr(subtotal?.currency);
  }, [cartDetails]);

  let discountPrice: [] = orderSummaryData?.prices?.discounts;
  const { data: shippingPayRes, refetch } = useQuery(
    queries.cartQueries.SHIPPING_AND_PAYMENT_METHOD,
    {
      variables: { cart_id: quest_cart_id },
      context: {
        headers: {
          Authorization: loggedInToken
            ? `Bearer ${JSON.parse(loggedInToken)}`
            : "",
          Connection: "keep-alive",
          store: store && store !== "" ? store : storePath?.split("/")?.[1],
        },
      },
    }
  );
  shippingMethods[0].carrier_code =
    shippingPayRes?.cart?.shipping_addresses[0]?.available_shipping_methods[0]?.carrier_code;
  shippingMethods[0].method_code =
    shippingPayRes?.cart?.shipping_addresses[0]?.available_shipping_methods[0]?.method_code;

  useEffect(() => {
    if (shippingToggle === false) {
      if (
        shippingMethods[0]?.carrier_code !== "" &&
        shippingMethods[0]?.carrier_code !== undefined &&
        shippingMethods[0]?.method_code !== undefined &&
        shippingMethods[0].method_code !== ""
      ) {
        handleSetShippingMethod();
        setShippingSuccess(true);
        setShipingToggle(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shippingMethods, shippingToggle]);

  useEffect(() => {
    if (shippingSuccess) {
      SetOrderPaymentMethod({
        variables: {
          cartId: quest_cart_id,
          paymentCode: paymentvalue,
        },
        context: {
          headers: {
            Authorization: loggedInToken
              ? `Bearer ${JSON.parse(loggedInToken)}`
              : "",
            Connection: "keep-alive",
            store: store && store !== "" ? store : storePath?.split("/")?.[1],
          },
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentvalue, shippingSuccess]);

  const [setUestEmail] = useMutation(
    mutations.addToCartMutation.SET_GUEST_EMAIL,
    {
      context: {
        headers: {
          Authorization: loggedInToken
            ? `Bearer ${JSON.parse(loggedInToken)}`
            : "",
          Connection: "keep-alive",
          store: store && store !== "" ? store : storePath?.split("/")?.[1],
        },
      },
    }
  );

  const { data: availablePayMethodRes } = useQuery(
    queries.orderQueries.GET_AVAILABLE_PAYMENT_METHODS_QUERY,
    {
      variables: {
        cartId: quest_cart_id,
      },
      context: {
        headers: {
          Authorization: loggedInToken
            ? `Bearer ${JSON.parse(loggedInToken)}`
            : "",
          Connection: "keep-alive",
          store: store && store !== "" ? store : storePath?.split("/")?.[1],
        },
      },
    }
  );

  let custDetailsDataRes: any;
  const { data: custDetailsDataRess, refetch: refetchCustInfo } = useQuery(
    queries.customerQueries.GET_CUSTOMER_INFO,
    {
      context: {
        headers: {
          Authorization: loggedInToken
            ? `Bearer ${JSON.parse(loggedInToken)}`
            : "",
          Connection: "keep-alive",
          store: store && store !== "" ? store : storePath?.split("/")?.[1],
        },
      },
    }
  );
  custDetailsDataRes = custDetailsDataRess;
  // }
  useEffect(() => {
    if (successAddress) {
      refetchCustInfo();
    } // eslint-disable-next-line
  }, [successAddress]);

  useEffect(() => {
    if (loggedInToken && loggedInToken !== "" && loggedInToken !== null) {
      refetchCustInfo();
    }
  }, [loggedInToken, refetchCustInfo]);
  let defaultBilling = custDetailsDataRes?.customer?.addresses.filter(function (
    val: any
  ) {
    return val?.default_shipping === true;
  });
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const handleChange = (event: any) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const [removeItemFromCart] = useMutation(
    mutations.addToCartMutation.REMOVECART_MUTATION,
    {
      context: {
        headers: {
          Authorization: loggedInToken
            ? `Bearer ${JSON.parse(loggedInToken)}`
            : "",
          Connection: "keep-alive",
          store: store && store !== "" ? store : storePath?.split("/")?.[1],
        },
      },
    }
  );

  const handleRemoveItemClick = (id: any) => {
    removeItemFromCart({
      variables: {
        // cartId: props?.isCartId,
        cartId: quest_cart_id,
        cartItemId: id,
      },
      context: {
        headers: {
          Authorization: loggedInToken
            ? `Bearer ${JSON.parse(loggedInToken)}`
            : "",
          Connection: "keep-alive",
          store: store && store !== "" ? store : storePath?.split("/")?.[1],
        },
      },
    })
      .then((result) => {
        refetchCartData();
        setCartCount(result?.data?.removeItemFromCart?.cart?.items?.length);
      })
      .catch((error) => {});
  };

  let [generateCustomerToken, { data: generateCustData }] = useMutation(
    mutations.loginMutation.LOGIN_MUTATION,
    {
      context: {
        headers: {
          Authorization: loggedInToken
            ? `Bearer ${JSON.parse(loggedInToken)}`
            : "",
          Connection: "keep-alive",
          store: store && store !== "" ? store : storePath?.split("/")?.[1],
        },
      },
    }
  );

  let [generateCustomerTokenAPI] = useMutation(
    mutations.loginMutation.SIGN_UP_WITH_LOGIN,
    {
      context: {
        headers: {
          Authorization: newToken ? `Bearer ${newToken}` : `Bearer ${newToken}`,
          Connection: "keep-alive",
          store: store && store !== "" ? store : storePath?.split("/")?.[1],
        },
      },
    }
  );

  const [mergeCartsMutation] = useMutation(
    mutations.addToCartMutation.MERGE_CARTS,
    {
      context: {
        headers: {
          Authorization: loggedInToken
            ? `Bearer ${JSON.parse(loggedInToken)}`
            : "",
          Connection: "keep-alive",
          store: store && store !== "" ? store : storePath?.split("/")?.[1],
        },
      },
    }
  );

  const handleGenerateToken = async (event: any) => {
    event.preventDefault();
    const data = formData;

    try {
      // Generate token
      const response = await generateCustomerToken({
        variables: data,
      });

      generateCustData = response?.data;

      setResError("");
      setShowErrorAlert(false);

      const token = generateCustData?.generateCustomerToken?.token;

      if (token) {
        await localStorage.setItem("loggedInCust", JSON.stringify(token));

        setIslogin(token);

        await handleMergeCarts();

        custCartQueryDataRefetch();
        refetchCartData();
        navigate(`/${store_code}/checkout`);
      }
    } catch (error: any) {
      if (error.networkError && error.networkError.result) {
        const networkErrorResponse = error.networkError.result;
        if (
          networkErrorResponse.errors &&
          networkErrorResponse.errors.length > 0
        ) {
          const errorMessages = networkErrorResponse.errors
            .map((err: any) => err.message)
            .join(", ");
          setResError(errorMessages);
        } else {
          setResError(error.networkError.message);
        }
      } else if (error.graphQLErrors && error.graphQLErrors.length > 0) {
        const errorMessages = error.graphQLErrors
          .map((err: any) => err.message)
          .join(", ");
        setResError(errorMessages);
      } else {
        setResError(t("auth.registration.user_err"));
      }
      setShowErrorAlert(true);
    }
  };

  const {
    // eslint-disable-next-line
    loading: custlload,
    data: custQueryDataRes,
    refetch: custCartQueryDataRefetch,
  } = useQuery(queries.customerQueries.GET_CUSTOMER_CART_QUERY, {
    context: {
      headers: {
        Authorization: loggedInToken
          ? `Bearer ${JSON.parse(loggedInToken)}`
          : "",
        Connection: "keep-alive",
        store: store && store !== "" ? store : storePath?.split("/")?.[1],
      },
    },
  });
  // eslint-disable-next-line

  // useEffect()
  let destinationCartId = custQueryDataRes?.customerCart?.id;

  useEffect(() => {
    if (custQueryDataRes && loggedInToken !== "") {
      set_quest_cart_id(custQueryDataRes?.customerCart?.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [custQueryDataRes]);

  useEffect(() => {
    custCartQueryDataRefetch()
      .then((result: any) => {
        if (result?.data?.customerCart?.id) {
          refetchCartData();
        }
      })
      .catch((error) => {
        console.error("login page Refetch error", error);
      });
    // eslint-disable-next-line
  }, [islogin, loggedInToken, cartDetailsRes]);

  const sessionStoreData = sessionStorage.getItem("storeAndCart");
  // eslint-disable-next-line
  const currentsessionStore = sessionStoreData
    ? JSON.parse(sessionStoreData)
    : [];

  useEffect(() => {
    if (destinationCartId && quest_cart_id && islogin) {
      handleMergeCarts();
      refetchCartData();
    }
    // eslint-disable-next-line
  }, [destinationCartId, quest_cart_id, islogin]);

  const handleMergeCarts = async () => {
    try {
      await mergeCartsMutation({
        variables: {
          cartId: quest_cart_id,
          destinationCartId: destinationCartId,
        },
        context: {
          headers: {
            Authorization: islogin ? `Bearer ${islogin}` : "",
            Connection: "keep-alive",
            store: store && store !== "" ? store : storePath?.split("/")?.[1],
          },
        },
      });
      const sessionStoreData = sessionStorage.getItem("storeAndCart");
      const currentsessionStore = sessionStoreData
        ? JSON.parse(sessionStoreData)
        : [];
      const filteredArray =
        currentsessionStore &&
        currentsessionStore?.filter((object: any) => {
          return object.genStore === selectedStores;
        });
      sessionStorage.setItem("storeAndCart", JSON.stringify(filteredArray));
    } catch (error) {
      console.error("login page Merge Carts Error:", error);
    }
  };

  useEffect(() => {
    if (custQueryDataRes && loggedInToken) {
      set_quest_cart_id(custQueryDataRes?.customerCart?.id);
    }
    // eslint-disable-next-line
  }, [custQueryDataRes]);

  const { data: countryRegRes } = useQuery(
    queries.configurationQueries.GET_COUNTRIES,
    {
      context: {
        headers: {
          Authorization: loggedInToken
            ? `Bearer ${JSON.parse(loggedInToken)}`
            : "",
          Connection: "keep-alive",
          store: store && store !== "" ? store : storePath?.split("/")?.[1],
        },
      },
    }
  );

  const selectedCountryData = countryRegRes?.countries?.find(
    (data: any) => data?.two_letter_abbreviation === selectedCountry
  );

  const initialLoad = async (add: any) => {
    const region = countryRegRes?.countries?.filter(
      (item: any) => item?.two_letter_abbreviation === selectedCountry
    );
    setCustCountry(selectedCountryData?.two_letter_abbreviation);

    if (region && region.length > 0) {
      await addBillingAddrMutation({
        variables: {
          cartId: quest_cart_id,
          firstname: add?.firstname || "-",
          lastname: add?.lastname || "-",
          company: "jebelz",
          street: String(add?.street[0]) || ["-"],
          city: add?.city || "-",
          region: add?.region?.region_code || "-",
          // regionId: selectedCountryData?.available_regions[0]?.id || "0",
          postCode:
            store_code === "sa_en" || store_code === "sa_ar"
              ? "0000"
              : add?.postcode || "-",
          country_code: selectedCountryData?.two_letter_abbreviation || "IN",
          telephone: add?.telephone || "-",
          save_in_address_book: false,
        },
        context: {
          headers: {
            Authorization: loggedInToken
              ? `Bearer ${JSON.parse(loggedInToken)}`
              : "",
            Connection: "keep-alive",
            store: store && store !== "" ? store : storePath?.split("/")?.[1],
          },
        },
      });
      await addShipingAddrMutation({
        variables: {
          cartId: quest_cart_id,
          firstname: add?.firstname || "-",
          lastname: add?.lastname || "-",
          company: "jebelz",
          street: String(add?.street[0]) || ["-"],
          city: add?.city || "-",
          region: add?.region?.region_code || "-",
          // regionId: selectedCountryData?.available_regions[0]?.id || "0",
          postCode:
            store_code === "sa_en" || store_code === "sa_ar"
              ? "0000"
              : add?.postcode || "-",
          country_code: selectedCountryData?.two_letter_abbreviation || "IN",
          telephone: add?.telephone || "-",
          save_in_address_book: false,
        },
        context: {
          headers: {
            Authorization: loggedInToken
              ? `Bearer ${JSON.parse(loggedInToken)}`
              : "",
            Connection: "keep-alive",
            store: store && store !== "" ? store : storePath?.split("/")?.[1],
          },
        },
      });

      refetch();
    }
    if (
      paymentvalue &&
      shippingMethods[0]?.carrier_code !== "" &&
      shippingMethods[0]?.carrier_code !== undefined &&
      shippingMethods[0]?.method_code !== undefined &&
      shippingMethods[0].method_code !== ""
    ) {
      await handleSetPaymentMethod(paymentvalue);
      await handleSetShippingMethod();
    }
  };

  // useEffect(() => {
  //   if(selectedAddress?.firstname === " " && loggedInToken !== " "){
  //     initialLoad();
  //   }
  //   // eslint-disable-next-line
  // }, [custCountry, countryRegRes,successAddress,selectedAddressId,loggedInToken,selectedAddress]);

  const [placeOrderMutation] = useMutation(
    mutations.orderMutation.PLACE_ORDER_MUTATION,
    {
      variables: { cart_id: quest_cart_id },
      context: {
        headers: {
          Authorization: loggedInToken
            ? `Bearer ${JSON.parse(loggedInToken)}`
            : "",
          Connection: "keep-alive",
          store: store && store !== "" ? store : storePath?.split("/")?.[1],
        },
      },
    }
  );
  const [addBillingAddrMutation] = useMutation(
    mutations.orderMutation.ADD_BILLING_ADDR,
    {
      context: {
        headers: {
          Authorization: loggedInToken
            ? `Bearer ${JSON.parse(loggedInToken)}`
            : "",
          Connection: "keep-alive",
          store: store && store !== "" ? store : storePath?.split("/")?.[1],
        },
      },
    }
  );
  const [addShipingAddrMutation] = useMutation(
    mutations.orderMutation.ADD_SHIPPING_ADDR,
    {
      context: {
        headers: {
          Authorization: loggedInToken
            ? `Bearer ${JSON.parse(loggedInToken)}`
            : "",
          Connection: "keep-alive",
          store: store && store !== "" ? store : storePath?.split("/")?.[1],
        },
      },
    }
  );

  const [setBillingAddrCustMutation] = useMutation(
    mutations.orderMutation.SET_BILLING_ADDRESS_CUST,
    {
      context: {
        headers: {
          Authorization: loggedInToken
            ? `Bearer ${JSON.parse(loggedInToken)}`
            : "",
          Connection: "keep-alive",
          store: store && store !== "" ? store : storePath?.split("/")?.[1],
        },
      },
    }
  );

  const [setShippingAddrCustMutation] = useMutation(
    mutations.orderMutation.SET_SHIPPING_ADDR_CUST,
    {
      context: {
        headers: {
          Authorization: loggedInToken
            ? `Bearer ${JSON.parse(loggedInToken)}`
            : "",
          Connection: "keep-alive",
          store: store && store !== "" ? store : storePath?.split("/")?.[1],
        },
      },
    }
  );

  useEffect(() => {
    if (billingOnLoad && defaultBilling && defaultBilling.length > 0) {
      setBillingOnLoad(false);
    }
  }, [defaultBilling, billingOnLoad]);

  const [SetOrderPaymentMethod] = useMutation(
    mutations.orderMutation.SET_ORDER_Add_PAYMENT_METHOD_MUTATION,
    {
      context: {
        headers: {
          Authorization: loggedInToken
            ? `Bearer ${JSON.parse(loggedInToken)}`
            : "",
          Connection: "keep-alive",
          store: store && store !== "" ? store : storePath?.split("/")?.[1],
        },
      },
    }
  );

  const handleSetPaymentMethod = async (value: any) => {
    const cartId = quest_cart_id;

    try {
      await SetOrderPaymentMethod({
        variables: {
          cartId: cartId,
          paymentCode: paymentvalue,
        },
        context: {
          headers: {
            Authorization: loggedInToken
              ? `Bearer ${JSON.parse(loggedInToken)}`
              : "",
            Connection: "keep-alive",
            store: store && store !== "" ? store : storePath?.split("/")?.[1],
          },
        },
      });
    } catch (error) {
      console.error("Error setting payment method:", error);
    }
  };

  const excludedMethods = new Set([
    "banktransfer",
    "office",
    "telrservicemethod",
    "posmachinemethod",
    "cashdepositmethod",
    "chequedepositmethod",
    "walletsystem",
    "tabbyservicemethod",
    "paymobpaymentlink",
  ]);

  const paymentMethodRes = useMemo(() => {
    if (availablePayMethodRes?.cart?.available_payment_methods) {
      const filteredMethods =
        availablePayMethodRes?.cart?.available_payment_methods?.filter(
          (method: any) => !excludedMethods.has(method?.code)
        );

      if (selectedStores === "ae_en" || selectedStores === "ae_ar") {
        setPaymentValue(filteredMethods[0]?.code || "telr_telrpayments");
      } else {
        setPaymentValue(filteredMethods[0]?.code);
      }

      return filteredMethods;
    }
    return [];
    //eslint-disable-next-line
  }, [availablePayMethodRes, selectedStores]);

  const cartId = quest_cart_id;

  const [setShippingMethod] = useMutation(
    mutations.orderMutation.SET_SHIPPING_METHOD_MUTATION,
    {
      context: {
        headers: {
          Authorization: loggedInToken
            ? `Bearer ${JSON.parse(loggedInToken)}`
            : "",
          Connection: "keep-alive",
          store: store && store !== "" ? store : storePath?.split("/")?.[1],
        },
      },
    }
  );

  const handleSetShippingMethod = async () => {
    try {
      await setShippingMethod({
        variables: {
          cartId: cartId,
          shippingMethods: shippingMethods,
        },
        context: {
          headers: {
            Authorization: loggedInToken
              ? `Bearer ${JSON.parse(loggedInToken)}`
              : "",
            Connection: "keep-alive",
            store: store && store !== "" ? store : storePath?.split("/")?.[1],
          },
        },
      }).then((res) => {
        setShipAmt(
          res?.data?.setShippingMethodsOnCart?.cart?.shipping_addresses[0]
            ?.selected_shipping_method?.amount?.value
        );
        setShipAmtCurr(
          res?.data?.setShippingMethodsOnCart?.cart?.shipping_addresses[0]
            ?.selected_shipping_method?.amount?.currency
        );
      });
    } catch (error) {
      console.error("Error setting shipping method:", error);
    }
  };

  const handlePaymentChange = async (event: any) => {
    if (event.target.value !== undefined || event.target.value !== null) {
      setPaymentValue(event.target.value);
    }
    if (event.target.value === "telr_telrpayments") {
      // navigate(`/${store}/telrPayment`);
    } else {
      await handleSetPaymentMethod(event.target.value);
      // handleSetShippingMethod();
    }
    refetchCartData();
  };

  const handleSetShippingAdd = async (addId: any) => {
    await setBillingAddrCustMutation({
      variables: {
        cart_id: quest_cart_id,
        customer_address_id: addId,
      },
      context: {
        headers: {
          Authorization: loggedInToken
            ? `Bearer ${JSON.parse(loggedInToken)}`
            : "",
          Connection: "keep-alive",
          store: store && store !== "" ? store : storePath?.split("/")?.[1],
        },
      },
    });
    await setShippingAddrCustMutation({
      variables: {
        cart_id: quest_cart_id,
        customer_address_id: addId,
      },
      context: {
        headers: {
          Authorization: loggedInToken
            ? `Bearer ${JSON.parse(loggedInToken)}`
            : "",
          Connection: "keep-alive",
          store: store && store !== "" ? store : storePath?.split("/")?.[1],
        },
      },
    }).then((res) => {
      setCheckShipingToggle(true);
    });
  };

  useEffect(() => {
    refetchCartData();
    setIsQtyDisabled(false);
  }, [cartUpdated, refetchCartData, newToken]);

  //-------- teller -------
  var onTokenReceive = function (telrToken: any) {
    /* Note: Include the received card token from iframe in payment form & send as ivp_ctoken parameter */
    setTelerToken(telrToken);
  };

  useEffect(() => {
    if (paymentvalue === "telr_telrpayments") {
      const script = document.createElement("script");
      script.src = "https://secure.telr.com/jssdk/v2/telr_sdk.js";
      script.async = true;

      script.onload = () => {
        // Initialize Telr SDK here
        const telrSdk = window.telrSdk;
        var telr_params = {
          store_id: 20906,
          currency: "AED",
          test_mode: 1,
          callback: onTokenReceive,
        };
        telrSdk.init(telr_params);
      };
      document.head.appendChild(script);
    } else if (paymentvalue === "tabby_installments") {
      const script = document.createElement("script");
      script.src = "https://checkout.tabby.ai/tabby-card.js";
      script.async = true;
      script.onload = () => {
        // Initialize Telr SDK here
        // const tabby:any = window.tabby;
        // var tabby_params = {
        //   selector: '#tabbyCard', // empty div for TabbyCard.
        //   currency: 'AED', // required, currency of your product. AED|SAR|KWD|BHD|QAR only supported, with no spaces or lowercase.
        //   lang: 'en', // Optional, language of snippet and popups.
        //   price: 100, // required, total price or the cart. 2 decimals max for AED|SAR|QAR and 3 decimals max for KWD|BHD.
        //   size: 'narrow', // required, can be also 'wide', depending on the width.
        //   theme: 'black', // required, can be also 'default'.
        //   header: false // if a Payment method name present already.
        // }
        //tabby(tabby_params);
      };
      document.head.appendChild(script);
    }
  }, [paymentvalue]);

  const [generatePostPayTokenMutation] = useMutation(
    mutations.postPayMutation.POST_PAY_GENERATE_TOKEN
  );

  const [generateTabbyMutation] = useMutation(
    mutations.postPayMutation.TABBY_CHECKOUT
  );

  const [buildCcavenueCheckoutToken] = useMutation(
    mutations.postPayMutation.CCEVENUE_GENERATE_TOKEN,
    {
      context: {
        headers: {
          Authorization: loggedInToken
            ? `Bearer ${JSON.parse(loggedInToken)}`
            : "",
          Connection: "keep-alive",
          store: store && store !== "" ? store : storePath?.split("/")?.[1],
        },
      },
    }
  );

  const handleQuantityChange = (sku: any, uid: any, newQuantity: any) => {
    const updatedData = prodQty.map((item: any) => {
      if (item.sku === sku) {
        return { ...item, qty: newQuantity };
      }
      return item;
    });
    setProQty(updatedData);

    if (newQuantity !== "") {
      updateCartItems({
        variables: {
          cartId: cartId,
          cartItemUid: uid,
          quantity: newQuantity,
        },
        context: {
          headers: {
            Authorization: loggedInToken
              ? `Bearer ${JSON.parse(loggedInToken)}`
              : "",
            Connection: "keep-alive",
            store: store && store !== "" ? store : storePath?.split("/")?.[1],
          },
        },
      })
        .then((res) => {
          setCartUpdated(res?.data?.updateCartItems?.cart?.items[0]?.quantity);
          setIsQtyDisabled(true);
        })
        .catch((error) => {
          console.error("enter Error updating cart:", error);
          setIsQtyDisabled(false);
        });
    }
  };

  const SubmitForm = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e?.preventDefault();

    if (!loggedInToken) {
      toast.error(`${t("auth.checkout.login")}`);
      return;
    }
    if (filteredAddresses?.length === 0) {
      toast.error(`${t("auth.checkout.add_error")}`);
      return;
    }
    if (selectedAddress?.firstname === "-") {
      toast.error("Please Add valid first name in your address!");
      return;
    } else if (selectedAddress?.lastname === "-") {
      toast.error("Please Add valid last name address!");
      return;
    } else if (selectedAddress?.telephone === "-") {
      toast.error("Please Add valid telephone number address!");
      return;
    } else if (selectedAddress?.street[0] === "-") {
      toast.error("Please Add valid street Address address!");
      return;
    }
    if (loggedInToken !== null && !checkShipingToggle) {
      toast.error("Please Select Shipping Address address!");
      return false;
    } else if (
      loggedInToken === null &&
      paymentvalue === "telr_telrpayments" &&
      (!telerToken || telerToken === "")
    ) {
      toast.error("Card details is Required");
      return false;
    }
    const guestVariables = {
      cartId: quest_cart_id,
      firstname: billFname,
      lastname: billSname,
      company: "Jebelz",
      street: billAddr1,
      city: billCity,
      region: selectedRegion?.bill_region,
      regionId: selectedRegion?.region_id,
      postCode:
        store_code === "in" || store_code === "sa_en" || store_code === "sa_ar"
          ? pinCode
          : "00000",
      country_code: custCountry,
      telephone: billTel,
      save_in_address_book: true,
    };
    let redirectUrl = "";
    let orderNumber = "";
    setProcedToPayToggle(true);
    setPlaceOrderLoading(true); //start loading
    if (loggedInToken === "" || !loggedInToken) {
      await setUestEmail({
        variables: {
          cart_id: quest_cart_id,
          email: billEmail === "-" ? "Jebelz@gmail.com" : billEmail,
        },
        context: {
          headers: {
            Authorization: loggedInToken
              ? `Bearer ${JSON.parse(loggedInToken)}`
              : "",
            Connection: "keep-alive",
            store: store && store !== "" ? store : storePath?.split("/")?.[1],
          },
        },
      });
      addBillingAddrMutation({
        variables: guestVariables,
        context: {
          headers: {
            Authorization: loggedInToken
              ? `Bearer ${JSON.parse(loggedInToken)}`
              : "",
            Connection: "keep-alive",
            store: store && store !== "" ? store : storePath?.split("/")?.[1],
          },
        },
      });
      await addShipingAddrMutation({
        variables: guestVariables,
        context: {
          headers: {
            Authorization: loggedInToken
              ? `Bearer ${JSON.parse(loggedInToken)}`
              : "",
            Connection: "keep-alive",
            store: store && store !== "" ? store : storePath?.split("/")?.[1],
          },
        },
      });
    }
    if (
      shippingMethods[0]?.carrier_code !== "" &&
      shippingMethods[0]?.carrier_code !== undefined &&
      shippingMethods[0]?.method_code !== undefined &&
      shippingMethods[0].method_code !== ""
    ) {
      await handleSetShippingMethod();
    }
    await handleSetPaymentMethod(paymentvalue);
    // await handlePlaceOrder();
    await placeOrderMutation({
      variables: {
        cartId: quest_cart_id,
      },
      context: {
        headers: {
          Authorization: loggedInToken
            ? `Bearer ${JSON.parse(loggedInToken)}`
            : "",
          Connection: "keep-alive",
          store: store && store !== "" ? store : storePath?.split("/")?.[1],
        },
      },
    })
      .then((result) => {
        localStorage.setItem(
          "orderId",
          result.data.placeOrder.order.order_number
        );

        //redirectUrl = `${result.data.placeOrder.order.order_redirect_url}?payment_token=${telerToken}&order_id=${result.data.placeOrder.order.order_number}`;
        redirectUrl = `${result.data.placeOrder.order.order_redirect_url}`;
        orderNumber = result.data.placeOrder.order.order_number;
        setTelrRediretUrl(redirectUrl);
        localStorage.removeItem("quest_cart_id");
        sessionStorage.removeItem("storeAndCart");
        set_quest_cart_id("");
        setCartCount(0);
        refetchCartData();
        // https://jebelz.com/ae_en/cms/empty-cart
      })
      .catch((error) => {
        console.error("Error placing order:", error);
      });

    const newOrderId = localStorage.getItem("orderId");
    if (paymentvalue === "cardondelivery") {
      navigate(`/${store_code}/ordersuccess`);
    }
    if (paymentvalue === "cashondelivery") {
      navigate(`/${store_code}/ordersuccess`);
    } else if (paymentvalue === "telr_telrpayments") {
      window.location.href = `${redirectUrl}?payment_token=${telerToken}&order_id=${orderNumber}`;
    } else if (paymentvalue === "tabby_installments") {
      window.location.href = `${redirectUrl}`;
    } else if (paymentvalue === "paymob_payment") {
      window.location.href = `${redirectUrl}`;
    } else if (
      paymentvalue === "postpay" &&
      newOrderId !== null &&
      newOrderId !== ""
    ) {
      generatePostPayTokenMutation({
        variables: { orderId: newOrderId },
      })
        .then((res: any) => {
          window.location.href = res?.data?.createPostpayCheckout?.redirect_url;
          localStorage.setItem("orderId", "");
          set_quest_cart_id("");
          setCartCount(0);
        })
        .catch((err: any) => {
          console.log(err);
        });
    } else if (
      paymentvalue === "tabby_installments" &&
      newOrderId !== null &&
      newOrderId !== ""
    ) {
      generateTabbyMutation({
        variables: { orderId: newOrderId },
      })
        .then((res: any) => {
          window.location.href = res?.data?.createPostpayCheckout?.redirect_url;
          localStorage.setItem("orderId", "");
          set_quest_cart_id("");
          setCartCount(0);
        })
        .catch((err: any) => {
          console.log(err);
        });
    } else if (
      paymentvalue === "ccavenue" &&
      newOrderId !== null &&
      newOrderId !== ""
    ) {
      buildCcavenueCheckoutToken({
        variables: { orderId: newOrderId },
      })
        .then((res: any) => {
          setCcevenueRes(res?.data?.buildCcavenueCheckout);
          localStorage.setItem("orderId", "");
          set_quest_cart_id("");
          setCartCount(0);
        })
        .catch((err: any) => {
          console.log(err);
        });
    }
    setPlaceOrderLoading(false);
  };

  const handleCouponChange = (event: any) => {
    setCouponCode(event.target.value);
  };

  const handleApplyCoupon = async () => {
    try {
      // eslint-disable-next-line
      const result = await applyCouponToCart({
        variables: {
          cartId: quest_cart_id,
          couponCode: couponCode,
        },
        context: {
          headers: {
            Authorization: loggedInToken
              ? `Bearer ${JSON.parse(loggedInToken)}`
              : "",
            Connection: "keep-alive",
            store: store && store !== "" ? store : storePath?.split("/")?.[1],
          },
        },
      });
      setCouponCodeSuccess(true);
      refetchCartData();
    } catch (err: any) {
      console.error("coupon Mutation Error:", err);
      toast.error(`Error applying coupon: ${err.message}`);
      setCouponCodeSuccess(false);
    }
  };

  const handleRemoveCoupon = async () => {
    try {
      // eslint-disable-next-line
      const result = await removeCouponFromCart({
        variables: {
          cartId: quest_cart_id,
        },
        context: {
          headers: {
            Authorization: loggedInToken
              ? `Bearer ${JSON.parse(loggedInToken)}`
              : "",
            Connection: "keep-alive",
            store: store && store !== "" ? store : storePath?.split("/")?.[1],
          },
        },
      });

      toast.success("Coupon removed successfully!");
      setCouponCode("");
      refetchCartData();
      setCouponCodeSuccess(false);
    } catch (error: any) {
      console.error("Remove Coupon Error:", error);

      const errorMessage =
        error.graphQLErrors && error.graphQLErrors.length
          ? error.graphQLErrors[0].message
          : "An error occurred. Please try again.";

      toast.error(`Error removing coupon: ${errorMessage}`);
      setCouponCodeSuccess(true);
    }
  };

  const filteredAddresses = useMemo(() => {
    if (custDetailsDataRes?.customer?.addresses) {
      // Filter addresses by selected country
      const addressesByCountry = custDetailsDataRes.customer.addresses.filter(
        (data: any) => data.country_code === selectedCountry
      );

      // Set default address to the last one in the filtered list if available
      if (addressesByCountry.length > 0) {
        const defaultAddress = addressesByCountry.slice(-1)[0]?.id;
        setSelectedAddressId(defaultAddress);
        setCheckShipingToggle(true);
      }

      // Return the last three filtered addresses or fewer if there are less than three
      return addressesByCountry.slice(-3);
    }
    refetchCustInfo();
    return [];
    // eslint-disable-next-line
  }, [custDetailsDataRes, successAddress, open, selectedCountry]);

  useEffect(() => {
    if (successAddress) {
      refetchCustInfo();
    }
    // eslint-disable-next-line
  }, [successAddress, open]);

  useEffect(() => {
    if (
      selectedAddressId &&
      selectedAddressId !== "undefined" &&
      loggedInToken
    ) {
      setCheckShipingToggle(true);
      const foundAddress = custDetailsDataRes?.customer?.addresses?.find(
        (item: any) => selectedAddressId === item.id
      );
      if (foundAddress) {
        setSelectedAddress(foundAddress);
        initialLoad(foundAddress);
        setSuccessAddress(true);
      }
    }
    // eslint-disable-next-line
  }, [
    custDetailsDataRes?.customer?.addresses,
    custCountry,
    countryRegRes,
    successAddress,
    selectedAddressId,
    loggedInToken,
    selectedAddress,
    islogin,
  ]);

  const handleRadioChange = (data: any) => {
    // const selectedId = event.target.value;
    setSelectedAddressId(data?.id);
    setCheckShipingToggle(true);
    handleSetShippingAdd(data?.id);
  };

  // console.log('cartDetails',cartDetails)

  // useEffect(() => {
  //   if ( cartDetails === undefined ) return;
  //   const itemsLength = cartDetails?.items?.length || 0;
  //   if (itemsLength === 0  ) {
  //     setIsCartEmpty(true)
  //   }
  //   if (isCartEmpty) {
  //     navigate(`/${path}`);
  //   }
  // }, [cartDetails, path, navigate,isCartEmpty,loggedInToken]);

  useEffect(() => {
    if (loggedInToken) {
      setIsOpenRegister(false);
    }
  }, [loggedInToken]);

  const StyledIconButton = styled(IconButton)`
    border: 1px solid #ece9e9;
    border-radius: 2px;
    padding: 3.5px 8px;
  `;

  const login = useGoogleLogin({
    onSuccess: async (response) => {
      try {
        handleSuccess(response);
      } catch (error) {
        console.error("Google login failed:", error);
        toast.error("Google login failed.");
      }
    },
    onError: (error) => console.log("Login Failed:", error),
  });

  const handleSuccess = async (response: any) => {
    setnewResponse(response);
    try {
      const res = await fetch(
        "https://www.googleapis.com/oauth2/v1/userinfo?alt=json",
        {
          headers: {
            Authorization: `Bearer ${response.access_token}`,
          },
        }
      );
      const dataIs = await res.json();

      const data = {
        email: dataIs?.email,
        firstname: dataIs?.given_name,
        lastname: dataIs?.family_name,
        apitoken: response?.access_token,
      };

      if (newToken !== "undefined") {
        setnewToken(response?.access_token);
        const responseIs = await generateCustomerTokenAPI({
          variables: data,
        });
        const generateCustData = responseIs?.data;
        setResError("");
        setShowErrorAlert(false);

        const token = generateCustData?.generateCustomerTokenAPI?.token;

        if (token) {
          await localStorage.setItem("loggedInCust", JSON.stringify(token));

          setIslogin(token);

          await handleMergeCarts();

          await custCartQueryDataRefetch();

          await refetchCartData();

          navigate(`/${store_code}/checkout`);
        }
      }
    } catch (err: any) {
      console.error(err?.message);
      // setShowErrorAlert(true);
    }
  };

  useEffect(() => {
    handleSuccess(newResponse);
    // eslint-disable-next-line
  }, [newToken]);

  // const handleAppleSignInSuccess = (response: any) => {
  //   // Apple will provide an authorization code or ID token
  //   console.log("Apple Sign-In successful:", response);

  //   // Typically, you'll get an ID token that you can send to your backend for verification
  //   const token = response.authorization.id_token;
  //   const email = response.user?.email;
  //   const firstname = response.user?.name?.firstName;
  //   const lastname = response.user?.name?.lastName;

  //   console.log("Token:", token);
  //   console.log("Email:", email);
  //   console.log("Firstname:", firstname);
  //   console.log("Lastname:", lastname);
  // };

  // const handleAppleSignInError = (error: any) => {
  //   console.error("Apple Sign-In error:", error);
  // };


  return (
    <>
      <PreHeader />
      <div className="ckeckout-wrapper">
        <div className="checkout-cont-wrap">
          <Container
            component="main"
            maxWidth="xl"
            style={{ marginBottom: "60px" }}
          >
            {successUrl &&
              successUrl !== "" &&
              paymentvalue === "telr_telrpayments" && (
                <Grid item xs={12}>
                  <iframe
                    src={successUrl}
                    title="success"
                    style={{
                      width: " 100%",
                      height: "404px",
                    }}
                  />
                </Grid>
              )}

            <Box
              sx={{
                marginTop: 5,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Grid container spacing={4} className="directionrtl">
                <Grid item xs={12} md={4} lg={4} xl={4}>
                  <Card
                    sx={{
                      maxWidth: "100%",
                      boxShadow: "none",
                      border: "1px solid #E0DEDE",
                      p: 2,
                    }}
                  >
                    <CardHeader
                      sx={{
                        backgroundColor: "#eee",
                        padding: "12px",
                        marginBottom: "16px",
                        "& .MuiTypography-root": {
                          fontSize: "16px",
                          fontWeight: "600",
                        },
                      }}
                      title={t("auth.checkout.ship_add")}
                    />
                    {loggedInToken !== "" &&
                    loggedInToken !== null &&
                    loggedInToken !== undefined ? (
                      <>
                        {filteredAddresses && (
                          <>
                            {filteredAddresses
                              ?.filter(
                                (data: any) =>
                                  data.country_code === selectedCountry
                              )
                              .map((data: any, index: any) => (
                                <RadioGroup
                                  aria-labelledby="demo-radio-buttons-group-label"
                                  name="radio-buttons-group"
                                  sx={{ gap: "5px" }}
                                  className="address-method-box"
                                  value={selectedAddressId}
                                  onChange={() => {
                                    handleRadioChange(data);
                                  }}
                                >
                                  <Card
                                    key={data.id}
                                    sx={{
                                      maxWidth: "100%",
                                      boxShadow: "none",
                                      backgroundColor: "#F1F3F6",
                                      mb: 2,
                                      p: 1,
                                      border: `${
                                        selectedAddressId === data.id
                                          ? "1px solid #00BFFF"
                                          : "0.25px solid #000"
                                      }`,
                                      borderRadius: "0px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                        // backgroundColor: `${
                                        //   selectedAddressId === data.id
                                        //     ? "#10CDF2"
                                        //     : "#eee"
                                        // }`,
                                        border: "1px solid #F1F3F6",
                                        marginBottom: "0px",
                                      }}
                                    >
                                      <FormControlLabel
                                        value={data.id}
                                        control={
                                          <Radio
                                            className="payment-radio"
                                            sx={{
                                              color: "black",
                                              "&.Mui-checked": {
                                                color: "#10CDF2",
                                              },
                                            }}
                                          />
                                        }
                                        label={
                                          <CardContent
                                            sx={{
                                              px: 0,
                                              // display: "flex",
                                              // flexDirection: "column",
                                              color: "#5c5b5b",
                                              marginBottom: "0px",
                                            }}
                                          >
                                            {showErrorAlert && (
                                              <Alert severity="error">
                                                <div>
                                                  <MessageWithLinks message={resError} store={store} />
                                                </div>
                                              </Alert>
                                            )}
                                            <Box>
                                              <div className="address-content">
                                                <Typography
                                                  variant="h6"
                                                  component="div"
                                                  sx={{
                                                    fontSize: "16px",
                                                    fontWeight: 400,
                                                  }}
                                                >
                                                  {data?.firstname}&nbsp;
                                                  {data?.lastname},
                                                </Typography>
                                                <Typography
                                                  variant="h6"
                                                  component="div"
                                                  sx={{
                                                    fontSize: "16px",
                                                    fontWeight: 400,
                                                  }}
                                                >
                                                  {data?.street[0]},
                                                </Typography>
                                                <Typography
                                                  variant="h6"
                                                  component="div"
                                                  sx={{
                                                    fontSize: "16px",
                                                    fontWeight: 400,
                                                  }}
                                                >
                                                  {data?.city},{" "}
                                                  {data?.country_code !== "AE"
                                                    ? data?.postcode
                                                    : ""}
                                                </Typography>
                                                <Typography
                                                  variant="h6"
                                                  component="div"
                                                  sx={{
                                                    fontSize: "16px",
                                                    fontWeight: 400,
                                                  }}
                                                >
                                                  {data?.region?.region},{" "}
                                                  {data?.country_code},
                                                </Typography>
                                                <Typography
                                                  variant="h6"
                                                  component="div"
                                                  sx={{
                                                    fontSize: "16px",
                                                    fontWeight: 400,
                                                  }}
                                                >
                                                  {data?.telephone}
                                                </Typography>
                                              </div>
                                            </Box>
                                          </CardContent>
                                        }
                                        sx={{
                                          color: `${
                                            selectedAddressId === data.id
                                              ? "white"
                                              : "black"
                                          }`,
                                          p: "0px 10px",
                                          "& .MuiTypography-root": {
                                            fontSize: "16px",
                                            fontWeight: `${
                                              selectedAddressId === data.id
                                                ? "600"
                                                : "400"
                                            }`,
                                          },
                                        }}
                                      />
                                      <Button
                                        onClick={() => handleOpenModal(data.id)}
                                        variant="contained"
                                        disableElevation
                                        className="address-button"
                                        sx={{
                                          backgroundColor: `${
                                            selectedAddressId === data.id
                                              ? "#06607E"
                                              : "#AEB1B2"
                                          }`,
                                        }}
                                      >
                                        {t("auth.checkout.edit")}
                                      </Button>
                                    </div>
                                  </Card>
                                </RadioGroup>
                              ))}

                            {filteredAddresses?.length !== 0 && (
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "end",
                                  justifyContent: "end",
                                }}
                              >
                                <Button
                                  onClick={() => handleOpenModal("")}
                                  variant="contained"
                                  disableElevation
                                  className="address-button"
                                >
                                  {t("auth.checkout.new_addr")}
                                </Button>
                              </Box>
                            )}
                          </>
                        )}
                        {filteredAddresses?.length === 0 && (
                          <Box sx={{ padding: "10px" }}>
                            <Box
                              sx={{
                                display: "flex",
                                flexWrap: "wrap",
                                alignItems: "end",
                                justifyContent: "space-between",
                              }}
                            >
                              <p
                                style={{
                                  fontSize: "15px",
                                  marginBottom: "10px",
                                }}
                              >
                                {t("auth.checkout.ship_add_desc")}
                              </p>
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "end",
                                  justifyContent: "end",
                                }}
                              >
                                <Button
                                  onClick={() => handleOpenModal("")}
                                  variant="contained"
                                  disableElevation
                                  className="address-button"
                                >
                                  {t("auth.checkout.new_addr")}
                                </Button>
                              </Box>
                            </Box>
                          </Box>
                        )}
                      </>
                    ) : (
                      <>
                        <Card
                          sx={{
                            maxWidth: "100%",
                            boxShadow: "none",
                            border: "1px solid #E0DEDE",
                            mb: 3,
                            p: "0px 20px 10px 20px",
                          }}
                        >
                          <CardContent sx={{ px: 0 }}>
                            {showErrorAlert && (
                              <Alert severity="error">{resError}</Alert>
                            )}
                            <Box
                              component="form"
                              noValidate
                              sx={{ mt: 3 }}
                              autoComplete="off"
                              className="login-form"
                            >
                              <Grid
                                container
                                spacing={isMobileScreen ? 1 : 2}
                                direction="row"
                                justifyContent="end"
                                alignItems="center"
                              >
                                <Grid item xs={12} className="form-label">
                                  <label> {t("auth.checkout.signin")}</label>
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                  <InputLabel htmlFor="input-field">
                                    {t("auth.checkout.email")}
                                  </InputLabel>
                                </Grid>
                                <Grid item xs={12} sm={9}>
                                  <TextField
                                    autoComplete="off"
                                    name="email"
                                    required
                                    fullWidth
                                    id="email"
                                    onChange={handleChange}
                                    placeholder={t("auth.checkout.email")}
                                    value={
                                      custDetailsDataRes
                                        ? custDetailsDataRes?.customer?.email
                                        : formData.email
                                    }
                                  />
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                  <InputLabel htmlFor="input-field">
                                    {t("auth.checkout.password")}
                                  </InputLabel>
                                </Grid>

                                <Grid item xs={12} sm={9} position="relative">
                                  <TextField
                                    autoComplete="off"
                                    name="password"
                                    type={showPwd ? "text" : "password"}
                                    required
                                    fullWidth
                                    id="password"
                                    value={formData.password}
                                    onChange={handleChange}
                                    placeholder={t("auth.checkout.password")}
                                    // autoFocus
                                  />
                                  <span
                                    className="pwd_visiblity"
                                    onClick={() => setShowPwd(!showPwd)}
                                  >
                                    {showPwd ? (
                                      <Visibility />
                                    ) : (
                                      <VisibilityOff />
                                    )}{" "}
                                  </span>
                                </Grid>
                                <Grid item xs={12} sm={3}></Grid>
                                <Grid item xs={12} sm={9}>
                                  {/* <Link to="/login"> */}
                                  <Button
                                    onClick={handleGenerateToken}
                                    variant="contained"
                                    sx={{
                                      textTransform: "capitalize",
                                      color: "#fff",
                                      fontSize: "18px",
                                      fontWeight: "500",
                                      padding: "6px 34px",
                                      width: "100%",
                                    }}
                                  >
                                    {t("auth.checkout.login")}
                                  </Button>
                                  {/* </Link> */}
                                </Grid>

                                <Grid item xs={12} sm={9}>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <div
                                      onClick={() => {
                                        setIsOpenRegister(true);
                                      }}
                                      style={{
                                        cursor: "pointer",
                                      }}
                                    >
                                      {t("auth.checkout.registration")}
                                    </div>

                                    <div
                                      onClick={() => setIsOpenFPwd(!isOpenFPwd)}
                                      style={{
                                        cursor: "pointer",
                                      }}
                                    >
                                      {t("auth.checkout.forgot_password")}
                                    </div>
                                  </Box>
                                </Grid>
                                <Grid item xs={12} sm={9}>
                                  <div className="google-login-container">
                                    <div className="social-button-group">
                                      <Button
                                        className="social_button"
                                        sx={{ width: "100%" }}
                                        onClick={() => login()}
                                      >
                                        {" "}
                                        <span className="g_icon">
                                          <img src={googleIcon} alt="gIcon" />
                                        </span>
                                        <span>
                                          {t(
                                            "auth.checkout.sign_in_with_google"
                                          )}
                                        </span>
                                      </Button>
                                    </div>
                                  </div>
                                </Grid>
                                {/* <Grid item xs={12} sm={6} md={12} lg={6}>
                                <AppleSignin
                                  authOptions={{
                                    clientId: "com.yourwebsite.signin", // Your actual client ID
                                    redirectURI: "http://localhost:3000", // Your redirect URI
                                    scope: "email name",
                                    state: "state",
                                    usePopup: true,
                                  }}
                                  // buttonStyle="black" // Use supported styles, refer to library docs for valid options
                                  onSuccess={handleAppleSignInSuccess}
                                  onError={handleAppleSignInError}
                                />
                              </Grid> */}
                              </Grid>
                            </Box>
                          </CardContent>
                        </Card>
                      </>
                    )}
                  </Card>
                </Grid>
                <Grid item xs={12} md={6} lg={4} xl={4}>
                  <Card
                    sx={{
                      maxWidth: "100%",
                      boxShadow: "none",
                      border: "1px solid #E0DEDE",
                      p: 2,
                    }}
                  >
                    <CardHeader
                      sx={{
                        backgroundColor: "#eee",
                        padding: "12px",
                        "& .MuiTypography-root": {
                          fontSize: "16px",
                          fontWeight: "600",
                        },
                      }}
                      title={t("auth.checkout.payment_method")}
                    />

                    <CardContent sx={{ px: 0 }}>
                      <Box>
                        {!successUrl &&
                          successUrl === "" &&
                          paymentvalue === "telr_telrpayments" &&
                          ccevenueRes === "" && (
                            <FormControl fullWidth>
                              {paymentvalue !== undefined ? (
                                <RadioGroup
                                  aria-labelledby="demo-radio-buttons-group-label"
                                  name="radio-buttons-group"
                                  sx={{ gap: "15px" }}
                                  className="payment-method-box"
                                  value={paymentvalue}
                                  onChange={handlePaymentChange}
                                >
                                  {paymentMethodRes &&
                                    paymentMethodRes?.map(
                                      (item: any, index: number) => {
                                        return (
                                          <>
                                            <FormControlLabel
                                              key={index}
                                              value={
                                                paymentMethodRes[index]?.code
                                              }
                                              control={
                                                <Radio className="payment-radio" />
                                              }
                                              label={
                                                paymentMethodRes[index]?.title
                                              }
                                              sx={{
                                                backgroundColor: "#F1F3F6",
                                                mx: 0,
                                                border: "0.25px solid #000",
                                                padding: "10px 20px",
                                                color: "#B0B5B6",
                                                "& .MuiFormControlLabel-label":
                                                  {
                                                    fontSize: "16px",
                                                  },
                                              }}
                                            />
                                            {paymentMethodRes[index]?.code ===
                                              paymentvalue &&
                                              (paymentMethodRes[index]?.code !==
                                                "cashondelivery" ||
                                                paymentMethodRes[index]
                                                  ?.code !==
                                                  "cardondelivery") && (
                                                <Box>
                                                  {paymentvalue !==
                                                    "telr_telrpayments" && (
                                                    <h1>{paymentvalue}</h1>
                                                  )}
                                                  {!successUrl &&
                                                    successUrl === "" && (
                                                      <Grid item xs={12}>
                                                        <div id="telr_frame"></div>
                                                        {isSubmit &&
                                                        paymentvalue ===
                                                          "telr_telrpayments" &&
                                                        (!telerToken ||
                                                          telerToken === "") ? (
                                                          <div className="error-msg">
                                                            {t(
                                                              "auth.checkout.error.telr"
                                                            )}
                                                          </div>
                                                        ) : null}
                                                      </Grid>
                                                    )}
                                                </Box>
                                              )}
                                          </>
                                        );
                                      }
                                    )}
                                </RadioGroup>
                              ) : (
                                ""
                              )}
                            </FormControl>
                          )}

                        {(paymentvalue === "postpay" ||
                          paymentvalue === "paymob_payment" ||
                          paymentvalue === "cashondelivery" ||
                          paymentvalue === "cardondelivery" ||
                          (paymentvalue === "tabby_installments" &&
                            cartDetails?.prices?.grand_total?.value <= 5000)) &&
                          !successUrl &&
                          ccevenueRes === "" && (
                            <FormControl fullWidth>
                              {paymentvalue !== undefined ? (
                                <RadioGroup
                                  aria-labelledby="demo-radio-buttons-group-label"
                                  name="radio-buttons-group"
                                  sx={{ gap: "15px" }}
                                  className="payment-method-box"
                                  value={paymentvalue}
                                  onChange={handlePaymentChange}
                                >
                                  {paymentMethodRes &&
                                    paymentMethodRes?.map(
                                      (item: any, index: number) => {
                                        return (
                                          <>
                                            <FormControlLabel
                                              key={index}
                                              value={
                                                paymentMethodRes[index]?.code
                                              }
                                              control={
                                                <Radio className="payment-radio" />
                                              }
                                              label={
                                                paymentMethodRes[index]?.title
                                              }
                                              sx={{
                                                backgroundColor: "#F1F3F6",
                                                mx: 0,
                                                border: "0.25px solid #000",
                                                padding: "10px 20px",
                                                color: "#B0B5B6",
                                                "& .MuiFormControlLabel-label":
                                                  {
                                                    fontSize: "16px",
                                                  },
                                              }}
                                            />
                                          </>
                                        );
                                      }
                                    )}
                                </RadioGroup>
                              ) : (
                                ""
                              )}
                            </FormControl>
                          )}
                        {paymentvalue === "ccavenue" &&
                          !successUrl &&
                          ccevenueRes === "" && (
                            <FormControl fullWidth>
                              {paymentvalue !== undefined ? (
                                <RadioGroup
                                  aria-labelledby="demo-radio-buttons-group-label"
                                  name="radio-buttons-group"
                                  sx={{ gap: "15px" }}
                                  className="payment-method-box"
                                  value={paymentvalue}
                                  onChange={handlePaymentChange}
                                >
                                  {paymentMethodRes &&
                                    paymentMethodRes?.map(
                                      (item: any, index: number) => {
                                        return (
                                          <>
                                            <FormControlLabel
                                              key={index}
                                              value={
                                                paymentMethodRes[index]?.code
                                              }
                                              control={
                                                <Radio className="payment-radio" />
                                              }
                                              label={
                                                paymentMethodRes[index]?.title
                                              }
                                              sx={{
                                                backgroundColor: "#F1F3F6",
                                                mx: 0,
                                                border: "0.25px solid #000",
                                                padding: "10px 20px",
                                                color: "#B0B5B6",
                                                "& .MuiFormControlLabel-label":
                                                  {
                                                    fontSize: "16px",
                                                  },
                                              }}
                                            />
                                          </>
                                        );
                                      }
                                    )}
                                </RadioGroup>
                              ) : (
                                ""
                              )}
                            </FormControl>
                          )}
                        {paymentvalue === "postpay" && (
                          <PostpayWidget
                            amt={cartDetails?.prices?.grand_total?.value}
                            page="checkout"
                          />
                        )}
                        {paymentvalue === "tabby_installments" &&
                          cartDetails?.prices?.grand_total?.value <= 7000 && (
                            <TabbyWidget
                              amt={cartDetails?.prices?.grand_total?.value}
                              currency={
                                cartDetails?.prices?.grand_total?.currency
                              }
                              store={store}
                              page="checkout"
                            />
                          )}
                      </Box>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} md={6} lg={4} xl={4}>
                  <Card
                    sx={{
                      maxWidth: "100%",
                      boxShadow: "none",
                      border: "1px solid #E0DEDE",
                      p: 2,
                    }}
                  >
                    <CardHeader
                      sx={{
                        backgroundColor: "#eee",
                        padding: "12px",
                        "& .MuiTypography-root": {
                          fontSize: "16px",
                          fontWeight: "600",
                        },
                      }}
                      title={t("auth.checkout.order_summary")}
                    />

                    <CardContent sx={{ px: 0 }}>
                      <Box>
                        <Box
                          sx={{
                            display: isMobileScreen ? "block" : "flex",
                            gap: "10px",
                            mb: 2,
                          }}
                        >
                          <TextField
                            required
                            fullWidth
                            name="apply-coupon"
                            placeholder={
                              couponCodeSuccess
                                ? t("auth.checkout.removed-coupon")
                                : t("auth.checkout.enter-your-coupon")
                            }
                            type="text"
                            id="apply-coupon"
                            autoComplete="off"
                            className="apply-coupon-box small-textfield"
                            value={couponCode}
                            disabled={couponCodeSuccess}
                            onChange={handleCouponChange}
                            sx={{
                              width: isMobileScreen ? "100%" : "600px",
                              mb: isMobileScreen ? 1 : 0,
                              fontSize: isMobileScreen ? "14px" : "16px",
                              color: couponCodeSuccess
                                ? "lightgray"
                                : "inherit",
                            }}
                          />
                          <Button
                            fullWidth
                            variant="contained"
                            sx={{
                              textTransform: "capitalize",
                              color: "#fff",
                              fontSize: isMobileScreen ? "13px" : "14px",
                              fontWeight: "500",
                              padding: isMobileScreen ? "4px 16px" : "6px 16px",
                            }}
                            onClick={
                              couponCodeSuccess
                                ? handleRemoveCoupon
                                : handleApplyCoupon
                            }
                          >
                            {couponCodeSuccess
                              ? t("auth.checkout.remove-coupon")
                              : t("auth.checkout.apply-coupon")}
                          </Button>
                        </Box>
                        <Box sx={{ minWidth: 100 }}>
                          <TextField
                            required
                            fullWidth
                            name="total-qty"
                            placeholder={`${
                              orderSummaryData?.items?.length
                            } ${t("auth.checkout.item-in-cart")}`}
                            type="text"
                            id="total-qty"
                            autoComplete="off"
                            className="total-qty-box small-textfield"
                            disabled
                          />
                        </Box>
                        <div className="cart-total-items">
                          {orderSummaryData &&
                            orderSummaryData?.items.map(
                              (data: any, index: any) => (
                                <div
                                  className="cart-content"
                                  style={{ padding: "15px 0px" }}
                                >
                                  <Box sx={{ height: 100, width: 100 }}>
                                    <CardMedia
                                      component="img"
                                      sx={{
                                        width: "100%",
                                        height: "100%",
                                        objectFit: "contain",
                                      }}
                                      image={data?.product?.small_image?.url}
                                      alt={
                                        data?.product?.small_image?._typename
                                      }
                                    />
                                  </Box>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      flexDirection: "column",
                                      width: 330,
                                    }}
                                  >
                                    <CardContent
                                      sx={{
                                        flex: "1 0 auto",
                                        pt: 0,
                                        pl: "10px",
                                      }}
                                    >
                                      <Typography
                                        gutterBottom
                                        component="div"
                                        variant="h5"
                                        sx={{
                                          fontSize: 16,
                                          color: "#252525",
                                          fontWeight: "400",
                                        }}
                                      >
                                        {data?.product?.name}
                                      </Typography>
                                    </CardContent>
                                    <Box
                                      sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                        gap: "10px",
                                        pl: 1,
                                        pb: 1,
                                      }}
                                    >
                                      <div className="qty-box">
                                        <label>{t("auth.checkout.Qty")}</label>
                                        <Box>
                                          <FormControl
                                            fullWidth
                                            sx={{
                                              border: "1px solid #C6C3C3",
                                              borderRadius: "2px",
                                            }}
                                          >
                                            <Input
                                              className="quantity-input-filed"
                                              type="number"
                                              value={prodQty[index].qty}
                                              onChange={(e: any) =>
                                                handleQuantityChange(
                                                  prodQty[index].sku,
                                                  prodQty[index].uid,
                                                  e.target.value
                                                )
                                              }
                                              inputProps={{ min: 1 }}
                                              disabled={isQtyDisabled}
                                            />
                                          </FormControl>
                                        </Box>
                                        <Box>
                                          <StyledIconButton
                                            aria-label="delete"
                                            onClick={() =>
                                              handleRemoveItemClick(data?.id)
                                            }
                                          >
                                            <DeleteIcon
                                              sx={{ height: "22px" }}
                                            />
                                          </StyledIconButton>
                                        </Box>
                                      </div>
                                      <Typography
                                        gutterBottom
                                        variant="h5"
                                        component="div"
                                        sx={{
                                          fontSize: 20,
                                          color: "#252525",
                                          fontWeight: "700",
                                        }}
                                      >
                                        {data?.prices?.price?.currency}
                                        &nbsp;
                                        {data?.prices?.price?.value}
                                      </Typography>
                                    </Box>
                                    <Box
                                      sx={{
                                        color: "#424141",
                                        fontSize: "12px",
                                        fontWeight: "500",
                                      }}
                                    ></Box>
                                  </Box>
                                </div>
                              )
                            )}
                        </div>

                        <Divider />
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "15px",
                            py: 3,
                          }}
                        >
                          <div className="final-cart-details">
                            <label className="item-lbl">
                              {t("auth.checkout.cart_subtotal")}
                            </label>
                            <span className="item-val">
                              {cartSubTotalCurr}&nbsp;
                              {cartSubTotal}
                            </span>
                          </div>
                          {selectedStore === "sa_en" ||
                          selectedStore === "sa_ar" ||
                          selectedStore === "en" ||
                          selectedStore === "ar" ? (
                            <>
                              <div className="final-cart-details">
                                <label className="item-lbl">
                                  {selectedStore === "sa_en" ||
                                  selectedStore === "sa_ar"
                                    ? t("auth.checkout.ksa_vat")
                                    : selectedStore === "en" ||
                                      selectedStore === "ar"
                                    ? t("auth.checkout.oman_vat")
                                    : ""}
                                </label>
                                <span className="item-val">
                                  {vat?.currency}&nbsp;
                                  {vat?.value}
                                </span>
                              </div>
                              <div className="final-cart-details">
                                <label className="item-lbl">
                                  {t("auth.checkout.custom_duty")}
                                </label>
                                <span className="item-val">
                                  {customDuty?.currency}&nbsp;
                                  {customDuty?.value}
                                </span>
                              </div>
                            </>
                          ) : (
                            ""
                          )}

                          <div className="final-cart-details">
                            <label className="item-lbl">
                              {t("auth.checkout.shipping")}
                            </label>
                            <span className="item-val">
                              {shipAmtCurr}&nbsp;
                              {shipAmt}
                            </span>
                          </div>
                          {paymentvalue === "cashondelivery" && (
                            <div className="final-cart-details">
                              <label className="item-lbl">
                                {t("auth.checkout.COD")}
                              </label>
                              <span className="item-val">AED 10</span>
                            </div>
                          )}
                          {couponCodeSuccess &&
                            discountPrice &&
                            discountPrice.map((data: any) => (
                              <Box
                                className="disc-cart-details"
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <label className="item-lbl">
                                  {t("auth.checkout.discount")}({data?.label})
                                </label>
                                <span className="item-val">
                                  -{data?.amount?.currency}{" "}
                                  {data?.amount?.value}
                                </span>
                              </Box>
                            ))}
                        </Box>
                        <Divider />
                        <div className="final-cart-details">
                          <label className="item-lbl">
                            {t("auth.checkout.total")}
                          </label>
                          <span className="item-val">
                            {orderSummaryData?.prices?.grand_total?.currency}
                            &nbsp;
                            {orderSummaryData?.prices?.grand_total?.value}
                          </span>
                        </div>
                        {
                          /*(islogin) ?*/
                          <div className="iss-mobile-fixed">
                            <Button
                              type="submit"
                              // id="submit_form"
                              onClick={(e) => SubmitForm(e)}
                              fullWidth
                              disabled={procedToPayToggle}
                              variant="contained"
                              sx={{
                                textTransform: "capitalize",
                                mt: isMobileScreen ? 0 : 2,
                                // mb:-4,
                                color: "#fff",
                                fontSize: "16px",
                                fontWeight: "500",
                                padding: "6px 34px",
                              }}
                            >
                              {placeOrderLoading ? ( // If loading, show CircularProgress
                                <CircularProgress size={24} />
                              ) : paymentvalue === "cashondelivery" ? (
                                t("auth.checkout.place-order")
                              ) : (
                                t("auth.checkout.proceed-to-pay")
                              )}
                            </Button>
                          </div>
                          /*: ''*/
                        }
                      </Box>
                      <AddNewAddress
                        isOpen={open}
                        handleCloseModal={handleCloseModal}
                        isEdit={isEdit}
                        setSuccessAddress={(event: any) =>
                          setSuccessAddress(event)
                        }
                      />
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Box>
            {ccevenueRes && <CCAvenueForm data={ccevenueRes} />}
          </Container>
        </div>
        {isOpenRegister && (
          <Modal
            selectedValue="register"
            open={isOpenRegister}
            onClose={(value: any) => setIsOpenRegister(value)}
            // onTokenGenerated={(token:any)=>console.log("token is 1",token)}
          />
        )}
        {isOpenFPwd && (
          <Modal
            selectedValue="forgotpwd"
            open={isOpenFPwd}
            onClose={(value: any) => setIsOpenFPwd(value)}
          />
        )}
      </div>
      {paymentFailed && (
        <PaymentFailer
          isOpen={true}
          onClose={() => {
            setPaymentFailed(false);
          }}
          title={t("auth.checkout.paymentFailTitle")}
          description1={t("auth.checkout.paymentFailDesc1")}
          description2={t("auth.checkout.paymentFailDesc2")}
        />
      )}
    </>
  );
}
export default Checkout;
