import React, { useState, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Grid from "@material-ui/core/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import GridUn from "@mui/material/Unstable_Grid2";
import Button from "@mui/material/Button";
import { Link, useLocation } from "react-router-dom";
import { Checkbox, Select, Skeleton, useMediaQuery } from "@mui/material";
// import { NetworkStatus, useQuery } from "@apollo/client";
// import * as queries from "../../../operations/queries";
import theme from "../../../theme";
import Popover from "@mui/material/Popover";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import Slider from "@mui/material/Slider";
import "./Domestic-machine.scss";
import { CountryContext } from "../MainComponent";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Pagination from "@mui/material/Pagination";
import { Helmet } from "react-helmet";
import MenuItem from "@mui/material/MenuItem";
import offerBanner from "../../../assets/images/offer-banner.png";
import MobileofferBanner from "../../../assets/images/mobile_banner.png";
import orderIcon from "../../../assets/images/order_icon.png";
import ProductMainCard from "../../../components/productMainCard/ProductMainCard";
import CartModal from "../../../components/CarouselSlider/cartmodal/CartModal";
import MainCardSkelton from "../../../components/skelton/MainCardSkelton";
import axios from "axios";

const filterIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M19 3H5C3.586 3 2.879 3 2.44 3.412C2 3.824 2 4.488 2 5.815V6.505C2 7.542 2 8.061 2.26 8.491C2.52 8.921 2.993 9.189 3.942 9.723L6.855 11.363C7.491 11.721 7.81 11.9 8.038 12.098C8.512 12.509 8.804 12.993 8.936 13.588C9 13.872 9 14.206 9 14.873V17.543C9 18.452 9 18.907 9.252 19.261C9.504 19.616 9.952 19.791 10.846 20.141C12.725 20.875 13.664 21.242 14.332 20.824C15 20.407 15 19.452 15 17.542V14.872C15 14.206 15 13.872 15.064 13.587C15.1896 13.0042 15.5059 12.4798 15.963 12.097C16.19 11.9 16.509 11.721 17.145 11.362L20.058 9.722C21.006 9.189 21.481 8.922 21.74 8.492C22 8.062 22 7.542 22 6.504V5.814C22 4.488 22 3.824 21.56 3.412C21.122 3 20.415 3 19 3Z"
      stroke="#118FA7"
      stroke-width="1.5"
    />
  </svg>
);

const responsive: any = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 10,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1200 },
    items: 10,
    slidesToSlide: 3,
  },
  laptop: {
    breakpoint: { max: 1200, min: 1024 },
    items: 6,
    slidesToSlide: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 3,
    slidesToSlide: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 2,
    slidesToSlide: 1,
  },
};

function DomesticMachine({ onLoad, isCountry }: any) {
  let minFilterPrice: number = 0;
  let maxFilterPrice: number = 0;
  const location = useLocation();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { t } = useTranslation();
  const [selectedMenufacturer, setSelectedMenufacturer] = useState<any>([]);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const { store } = useContext(CountryContext);
  const [currentPage, setCurrentPage] = useState(1);
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const initialPageSize = isMobileScreen ? 20 : 50;
  const [pageSize, setPageSize] = useState(initialPageSize);
  const [isCartModalOpen, setCartModalOpen] = useState(false);
  const [modalUrlKey, setModalUrlKey] = useState("");
  const [productData, setProductData] = useState("");
  const [priceValue, setPriceValue] = useState<number[]>([
    minFilterPrice,
    maxFilterPrice,
  ]);
  const [filteredPro, setFilteredPro] = useState<any>([]);
  const [categoryDataIs, setCategoryDataIs] = useState<any>(null);
  const [jsonLoader, setJsonLoader] = useState(true);
  const open = Boolean(anchorEl);
  const [anchorElSort, setAnchorElSort] = useState<null | HTMLElement>(null);
  const openSortPopover = Boolean(anchorElSort);
  const [sortOrder, setSortOrder] = useState<string | undefined>(undefined);

  const arrayForMobileScreen = [
    { value: 20, label: "20" },
    { value: 30, label: "30" },
    { value: 40, label: "40" },
    { value: 50, label: "50" },
  ];

  const arrayForDesktopScreen = [
    { value: 50, label: "50" },
    { value: 75, label: "75" },
    { value: 100, label: "100" },
    { value: 125, label: "125" },
  ];

  const array = isMobileScreen ? arrayForMobileScreen : arrayForDesktopScreen;

  function valuetext(value: number) {
    return `${value}AED`;
  }
  useEffect(() => {
    window?.scrollTo(0, 0);
  }, [filteredPro, pageSize, currentPage]);

  const url = location?.pathname?.split("/category");
  const urlKey = url[url?.length - 1];
  const urlKeyFromParams = urlKey.replace(".html", "").slice(1);
  // const urlKey = data?.urlResolver?.relative_url;
  // const urlKeyWithExtension = urlKey ? urlKey : urlKeyFromParams;
  const lastPartOfUrl = urlKeyFromParams?.split("/").pop();

  const urlKeyIsFromAPI = lastPartOfUrl
    ? lastPartOfUrl.replace(".html", "")
    : "";
  // Output: "top-sellers-uae"
  // const {
  //   data: categoryid,
  //   refetch: refatchId,
  //   loading: urlLoader,
  //   networkStatus,
  // } = useQuery(queries.cartQueries.GET_CATEGORY_URL, {
  //   variables: { url: urlKey },
  //   skip: !urlKey,
  //   fetchPolicy: "network-only",
  //   notifyOnNetworkStatusChange: true,
  // });

  // const isRefetching = networkStatus === NetworkStatus.refetch;

  // useEffect(() => {
  //   refatchId();
  //   setSortOrder(undefined);
  // }, [urlKey, refatchId, store]);

  // var category_id = categoryid?.urlResolver?.id?.toString();

  const fetchCategoryData = async () => {
    // const urlKey = data?.urlResolver?.relative_url;
    // const urlKeyWithExtension = urlKey ? urlKey : urlKeyFromParams;
    // const lastPartOfUrl = urlKeyWithExtension?.split("/").pop();
    // const urlKeyIsFromAPI = lastPartOfUrl
    //   ? lastPartOfUrl.replace(".html", "")
    //   : "";
    setCategoryDataIs(null);
    setFilteredPro([]);
    const url = `https://access.jebelz.com/media/contents/${store}/${urlKeyIsFromAPI}.json`;
    try {
      setJsonLoader(true);
      const response = await axios.get(url, {
        headers: {
          "Cache-Control": "max-age=1800",
        },
      });
      setCategoryDataIs(response?.data);
      setJsonLoader(false);
    } catch (err) {
      setFilteredPro([]);
      setCategoryDataIs(null);
      setJsonLoader(false);
    } finally {
      setJsonLoader(false);
    }
  };

  useEffect(() => {
    if (urlKeyIsFromAPI) {
      fetchCategoryData();
      setSortOrder(undefined);
      setCurrentPage(1);
      setPageSize(initialPageSize)
    }
    //eslint-disable-next-line
  }, [urlKeyIsFromAPI , store]);

  const categoryProduct = categoryDataIs?.category?.data;
  let categoryProductList = categoryProduct?.products?.items;
  const catMetaData = categoryProduct?.category;
  const htmlContent = catMetaData?.description;
  const catData = categoryProduct?.getSubCategoriesData;

  const [availableBrands, setAvailableBrands] = useState<any>([]);
  const [availablePriceRange, setAvailablePriceRange] = useState([0, 0]);

  const calculateAvailableBrands = (products: any[]) => {
    if (!products || products?.length === 0) return [];

    const brandMap = new Map();

    products.forEach((product) => {
      const brand = product?.brand_logo;
      if (brand) {
        if (brandMap.has(brand)) {
          brandMap.set(brand, brandMap.get(brand) + 1);
        } else {
          brandMap.set(brand, 1);
        }
      }
    });

    return Array.from(brandMap.entries()).map(([brand, count]) => ({
      label: brand,
      value: brand,
      count,
    }));
  };

  const calculatePriceRange = (products: any[]) => {
    if (!products || products?.length === 0) return [0, 0];

    const prices = products.map(
      (product) => product?.price_range?.minimum_price?.final_price?.value || 0
    );

    const minPrice = Math.min(...prices);
    const maxPrice = Math.max(...prices);

    return [minPrice, maxPrice];
  };

  const applyCombinedFilters = () => {
    if (!categoryProductList || categoryProductList?.length === 0) {
      setFilteredPro([]);
      return;
    }

    let filteredByBrand = categoryProductList;
    if (selectedMenufacturer?.length > 0) {
      filteredByBrand = categoryProductList.filter((product: any) => {
        const productBrand = product?.brand_logo?.toLowerCase();
        return selectedMenufacturer.some(
          (brand: string) => brand?.toLowerCase() === productBrand
        );
      });
    }

    const [minPrice, maxPrice] = priceValue;
    let filteredByPrice = filteredByBrand;
    if (maxPrice > 0) {
      filteredByPrice = filteredByBrand.filter((product: any) => {
        const productPrice =
          product?.price_range?.minimum_price?.final_price?.value || 0;
        return productPrice >= minPrice && productPrice <= maxPrice;
      });
    }

    const newAvailableBrands = calculateAvailableBrands(categoryProductList);
    setAvailableBrands(newAvailableBrands);

    const newPriceRange = calculatePriceRange(filteredByBrand);
    setAvailablePriceRange(newPriceRange);

    const sortedProducts = sortProducts(filteredByPrice, sortOrder);
    setFilteredPro(sortedProducts);
  };

  useEffect(() => {
    if (categoryProductList && categoryProductList?.length > 0) {
      const initialPriceRange = calculatePriceRange(categoryProductList);
      setPriceValue(initialPriceRange);
      setAvailablePriceRange(initialPriceRange);

      setAvailableBrands(calculateAvailableBrands(categoryProductList));

      applyCombinedFilters();
    }
    //eslint-disable-next-line
  }, [categoryProductList, selectedMenufacturer]);

  useEffect(() => {
    applyCombinedFilters();
    //eslint-disable-next-line
  }, [selectedMenufacturer, priceValue, sortOrder]);

  // useEffect(() => {
  //   setCurrentPage(1);
  // }, [category_id]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setDropdownOpen(!isDropdownOpen);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const id = open ? "simple-popover" : undefined;

  const handleSortClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElSort(event.currentTarget);
  };

  const handleSortClose = () => {
    setAnchorElSort(null);
  };

  const sortProducts = (products: any[], order: string | undefined) => {
    if (!products || products?.length === 0) return [];

    const productsCopy = [...products];
    setFilteredPro(productsCopy);
    setCurrentPage(1);

    switch (order) {
      case "ASC":
        return productsCopy.sort((a, b) => {
          const priceA = a.price_range?.minimum_price?.final_price?.value || 0;
          const priceB = b.price_range?.minimum_price?.final_price?.value || 0;
          return priceA - priceB;
        });
      case "DESC":
        return productsCopy.sort((a, b) => {
          const priceA = a.price_range?.minimum_price?.final_price?.value || 0;
          const priceB = b.price_range?.minimum_price?.final_price?.value || 0;
          return priceB - priceA;
        });
      default:
        return productsCopy;
    }
  };

  const handleSortOptionSelect = (value: string) => {
    const newSortOrder = value === "RECMD" ? undefined : value;
    setSortOrder(newSortOrder);
    if (categoryDataIs?.category?.data.products) {
      sortProducts(
        categoryDataIs?.category?.data?.products?.items,
        newSortOrder
      );
    }
    handleSortClose();
  };

  useEffect(() => {
    setPriceValue([0, maxFilterPrice]);
  }, [minFilterPrice, maxFilterPrice]);

  const handleBrandClick = (item: any) => {
    const index = selectedMenufacturer?.indexOf(item?.value);
    const newSelectedBrands: string[] =
      index === -1
        ? [...selectedMenufacturer, item.value]
        : selectedMenufacturer?.filter((data: string) => data !== item.value);

    setSelectedMenufacturer(newSelectedBrands);
  };

  const handleSliderChange = (event: Event, newValue: number | number[]) => {
    setPriceValue(newValue as number[]);
  };

  const handlePageSizeChange = (event: any) => {
    setPageSize(event.target.value);
    setCurrentPage(1);
  };

  const handlePageChange = (event: any, value: any) => {
    setCurrentPage(value);
  };

  const totalItems =
    filteredPro?.length > 0
      ? filteredPro?.length
      : categoryProductList?.length || 0;
  const totalPages = Math.ceil(totalItems / pageSize);

  const paginatedOrderItems = (
    filteredPro?.length > 0 ? filteredPro : categoryProductList || []
  )?.slice((currentPage - 1) * pageSize, currentPage * pageSize);

  return (
    <>
      <Helmet>
        <title>{catMetaData?.meta_title}</title>
        <meta name="description" content={catMetaData?.meta_description} />
        <meta name="keywords" content={catMetaData?.meta_keywords} />
        <meta name="DC.title" content={catMetaData?.meta_title} />
        <meta name="geo.region" content={store === "in" ? "IN-UP" : "AE-DU"} />
        <meta
          name="geo.placename"
          content={store === "in" ? "Noida" : "Dubai"}
        />
        <meta
          name="geo.position"
          content={
            store === "in" ? "28.58508;77.315262" : "25.121614;55.377031"
          }
        />
        <meta
          name="ICBM"
          content={
            store === "in" ? "28.58508, 77.315262" : "25.121614, 55.377031"
          }
        />
        <meta property="og:title" content={catMetaData?.meta_title} />
        <meta
          property="og:description"
          content={catMetaData?.meta_description}
        />
        <meta property="og:url" content={window.location.href} />
        <meta
          property="og:image"
          content={
            store.includes("ar")
              ? "https://access.jebelz.com/media/logo/stores/4/JebelzA.gif"
              : "https://access.jebelz.com/media/logo/stores/10/Logo.gif"
          }
        />
        <meta property="og:type" content={catMetaData?.name} />
      </Helmet>
      {/* <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
        className="breadcrumb-data"
      >
        {breadcrumbs}
      </Breadcrumbs> */}

      {categoryProduct?.category?.category_banners?.length > 0 && (
        <div className="offer_banner">
          <img
            src={
              isMobileScreen
                ? categoryProduct?.category?.category_banners[0]
                    ?.image_mobile || MobileofferBanner
                : categoryProduct?.category?.category_banners[0]?.image ||
                  offerBanner
            }
            alt="Offer Banner"
            className="offer_img"
            style={{ width: "100%" }}
          />
        </div>
      )}

      <div
        className={`main-content ${
          categoryProduct?.category?.enable_campaign === 1 && "diwali-sale"
        }`}
      >
        <Box>
          <Grid container>
            <Grid item xs={12} md={12}>
              <Card
                sx={{ maxWidth: "100%", boxShadow: 0 }}
                className="category-home-grid"
              >
                <CardContent>
                  {jsonLoader ? (
                    <Box sx={{ pt: { lg: 5, md: 4, xs: 3 } }}>
                      <Carousel
                        responsive={responsive}
                        swipeable={true}
                        autoPlay={true}
                        transitionDuration={3000}
                        autoPlaySpeed={5000}
                        customTransition="all 3s linear"
                        centerMode={false}
                        arrows={false}
                        infinite={true}
                        ssr={true}
                        showDots={false}
                        slidesToSlide={1}
                        deviceType={""}
                        removeArrowOnDeviceType={["tablet", "mobile"]}
                      >
                        {Array.from({ length: 5 }).map((_, index) => (
                          <div className="category-box" key={index}>
                            <div className="category-box-img">
                              <Skeleton
                                variant="circular"
                                width={80}
                                height={80}
                                sx={{ mx: "auto", mb: 2 }}
                              />
                            </div>
                            <div className="category-box-title">
                              <Skeleton
                                variant="text"
                                width="60%"
                                height={20}
                                sx={{ mx: "auto" }}
                              />
                            </div>
                          </div>
                        ))}
                      </Carousel>
                    </Box>
                  ) : catData &&
                    catData !== undefined &&
                    catData?.length > 0 ? (
                    <>
                      <div
                        style={{
                          direction:
                            store === "ae_ar" ||
                            store === "sa_ar" ||
                            store === "ar"
                              ? "rtl"
                              : "ltr",
                          textAlign:
                            store === "ae_ar" ||
                            store === "sa_ar" ||
                            store === "ar"
                              ? "right"
                              : "left",
                        }}
                      >
                        <Carousel
                          className="category-home-grid-slider directionrtl"
                          responsive={responsive}
                          swipeable={true}
                          autoPlay={false}
                          centerMode={false}
                          arrows={false}
                          infinite={true}
                          ssr={true}
                          showDots={false}
                          slidesToSlide={1}
                          deviceType={""}
                          removeArrowOnDeviceType={["tablet", "mobile"]}
                        >
                          {catData?.map((data: any, index: any) => {
                            return (
                              <div className="category-box">
                                <Link
                                  to={`/${store}/category/${data?.url_key}`}
                                >
                                  <div className="category-box-img">
                                    <img
                                      className="img-cover"
                                      draggable={false}
                                      style={{ position: "relative" }}
                                      src={data.image}
                                      alt={data.name}
                                    />
                                  </div>
                                  <div className="category-box-title">
                                    <span>
                                      {data.name}{" "}
                                      {isSmallScreen
                                        ? data.image_mobile
                                        : data.image_mobile}
                                    </span>
                                  </div>
                                </Link>
                              </div>
                            );
                          })}
                        </Carousel>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Box>
        <div
          style={{
            direction:
              store === "ae_ar" || store === "sa_ar" || store === "ar"
                ? "rtl"
                : "ltr",
            textAlign:
              store === "ae_ar" || store === "sa_ar" || store === "ar"
                ? "right"
                : "left",
          }}
        >
          <Box sx={{ pt: 5 }}>
            <Card
              sx={{ maxWidth: "100%", boxShadow: 0 }}
              className="topsell-machine-grid"
            >
              <Box
                sx={{
                  minWidth: 120,
                  px: 2,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  flexWrap: "wrap",
                  gap: "15px",
                  marginTop: "13px",
                }}
              >
                <Box
                  className="sortby-btn filter-box filter-input-box"
                  sx={{ width: { md: "297px", sm: "276px", xs: "100%" } }}
                >
                  <Box>
                    <Button
                      aria-describedby={id}
                      variant="contained"
                      onClick={handleClick}
                      sx={{
                        bgcolor: "#fff",
                        boxShadow: "none",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "left",
                        gap: "10px",
                      }}
                      className="filter-btn"
                      endIcon={
                        open ? (
                          <KeyboardArrowUpIcon />
                        ) : (
                          <KeyboardArrowDownIcon />
                        )
                      }
                    >
                      <span
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        {filterIcon}
                      </span>
                      <span className="filter-text">
                        {t("inner_pages.domestic_machine.filter")}
                      </span>
                    </Button>
                    <Popover
                      className="filter-popver"
                      id={id}
                      open={open}
                      anchorEl={anchorEl}
                      onClose={handleClose}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                    >
                      <Card sx={{ minWidth: "100%", boxShadow: "none" }}>
                        <CardContent>
                          <Grid container spacing={2}>
                            <Grid item xs={12} lg={12} md={12}>
                              {availableBrands &&
                                availableBrands?.length > 0 && (
                                  <Accordion
                                    sx={{
                                      display: "flex",
                                      flexDirection: "column",
                                      boxShadow: "none",
                                    }}
                                  >
                                    <AccordionSummary
                                      expandIcon={<ExpandMoreIcon />}
                                      aria-controls="panel1a-content"
                                      id="panel1a-header"
                                      sx={{
                                        border: "1px solid #ebeaea",
                                        background: "#f8f6f6",
                                      }}
                                    >
                                      <Typography>
                                        {t(
                                          "inner_pages.domestic_machine.menufacturer"
                                        )}
                                        <span
                                          className="menufacturer-brand title"
                                          style={{ marginLeft: "5px" }}
                                        >
                                          ({availableBrands?.length})
                                        </span>
                                      </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails
                                      sx={{
                                        border: "1px solid #E5E3E3",
                                      }}
                                    >
                                      {/* <Stack
                                        direction="row"
                                        spacing={1}
                                        sx={{
                                          flexWrap: "wrap",
                                          gap: "10px",
                                        }}
                                      >
                                        {availableBrands.map((item: any) => {
                                          return (
                                            <Chip
                                              key={item?.value}
                                              onClick={() => {
                                                handleBrandClick(item);
                                              }}
                                              label={`${t(
                                                `brand.${item?.label}`
                                              ).replace("brand.", "")} (${
                                                item?.count
                                              })`}
                                              className={`filter-chip ${
                                                selectedMenufacturer.includes(
                                                  item?.value
                                                )
                                                  ? "active"
                                                  : ""
                                              }`}
                                              component="a"
                                              href="#"
                                              clickable
                                              variant="outlined"
                                              sx={{
                                                border: "1px solid #EFEFEF",
                                                background: "#FAFAFA",
                                                px: 4,
                                                borderRadius: "5px",
                                              }}
                                            />
                                          );
                                        })}
                                      </Stack> */}
                                      <Stack
                                        direction="column"
                                        // spacing={1}
                                        sx={{
                                          flexWrap: "wrap",
                                          direction: store.includes("ar") ? "ltr" : "ltr",
                                        }}
                                      >
                                        {availableBrands.map((item: any) => (
                                          <Box
                                            key={item?.value}
                                            sx={{
                                              display: "flex",
                                              alignItems: "center",
                                              cursor: "pointer",
                                              "&:hover": {
                                                backgroundColor:
                                                  "rgba(0, 0, 0, 0.04)",
                                              },
                                            }}
                                            onClick={() =>
                                              handleBrandClick(item)
                                            }
                                          >
                                            <Checkbox
                                              checked={selectedMenufacturer.includes(
                                                item?.value
                                              )}
                                              onChange={() =>
                                                handleBrandClick(item)
                                              }
                                              color="primary"
                                              sx={{
                                                padding: "6px",
                                              }}
                                            />
                                            <Typography
                                              sx={{ fontSize: "0.875rem" }}
                                            >
                                              {`${t(
                                                `brand.${item?.label}`
                                              ).replace("brand.", "")} (${
                                                item?.count
                                              })`}
                                            </Typography>
                                          </Box>
                                        ))}
                                      </Stack>
                                    </AccordionDetails>
                                  </Accordion>
                                )}
                            </Grid>
                            <Grid item xs={12} lg={12} md={12}>
                              <Accordion
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: "20px",
                                  boxShadow: "none",
                                }}
                              >
                                <AccordionSummary
                                  expandIcon={<ExpandMoreIcon />}
                                  aria-controls="panel1a-content"
                                  id="panel1a-header"
                                  sx={{
                                    border: "1px solid #ebeaea",
                                    background: "#f8f6f6",
                                  }}
                                >
                                  <Typography>
                                    {t("inner_pages.domestic_machine.price")}
                                  </Typography>
                                </AccordionSummary>
                                <AccordionDetails
                                  sx={{
                                    border: "1px solid #E5E3E3",
                                  }}
                                >
                                  <Stack
                                    direction="row"
                                    // spacing={1}
                                    sx={{ mb: 3 ,gap:"10px" }}
                                  >
                                    <Chip
                                      sx={{
                                        border: "1px solid #EFEFEF",
                                        background: "#FAFAFA",
                                        px: 1.8,
                                        borderRadius: "5px",
                                      }}
                                      label={`AED ${availablePriceRange[0]}`}
                                      variant="outlined"
                                    />
                                    <Chip
                                      sx={{
                                        border: "1px solid #EFEFEF",
                                        background: "#FAFAFA",
                                        px: 1.8,
                                        borderRadius: "5px",
                                      }}
                                      label={`AED ${availablePriceRange[1]}`}
                                      variant="outlined"
                                    />
                                  </Stack>
                                  <Box>
                                    <Box>
                                      <Slider
                                        getAriaLabel={() => "Price range"}
                                        value={priceValue}
                                        onChange={handleSliderChange}
                                        valueLabelDisplay="auto"
                                        getAriaValueText={valuetext}
                                        min={availablePriceRange[0]}
                                        max={availablePriceRange[1]}
                                        disabled={
                                          selectedMenufacturer?.length === 0
                                        }
                                      />
                                    </Box>
                                  </Box>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                    }}
                                  >
                                    <span className="directionrtl">
                                      {filteredPro?.length}{" "}
                                      {t(
                                        "inner_pages.domestic_machine.2365-products"
                                      )}
                                    </span>
                                  </Box>
                                </AccordionDetails>
                              </Accordion>
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Card>
                    </Popover>
                  </Box>
                </Box>

                <Box
                  className="sortby-btn filter-box filter-input-box"
                  sx={{ width: { md: "297px", sm: "276px", xs: "100%" } }}
                >
                  <Box>
                    <Button
                      aria-describedby="sort-popover"
                      variant="contained"
                      onClick={handleSortClick}
                      sx={{
                        bgcolor: "#fff",
                        boxShadow: "none",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "left",
                        gap: "10px",
                      }}
                      className="filter-btn"
                      endIcon={
                        openSortPopover ? (
                          <KeyboardArrowUpIcon />
                        ) : (
                          <KeyboardArrowDownIcon />
                        )
                      }
                    >
                      <span
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <img
                          src={orderIcon}
                          alt="icon logo"
                          style={{
                            height: "25px",
                          }}
                        />
                      </span>

                      <span className="filter-text sort-text">
                        {(() => {
                          if (sortOrder === undefined) {
                            return t(
                              "inner_pages.domestic_machine.recommended"
                            );
                          } else if (sortOrder === "ASC") {
                            return t(
                              "inner_pages.domestic_machine.price_low_to_h"
                            );
                          } else if (sortOrder === "DESC") {
                            return t(
                              "inner_pages.domestic_machine.price_high_to_low"
                            );
                          }
                        })()}
                      </span>
                    </Button>

                    <Popover
                      className="filter-popver"
                      id="sort-popover"
                      open={openSortPopover}
                      anchorEl={anchorElSort}
                      onClose={handleSortClose}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                    >
                      <Card sx={{ minWidth: "100%", boxShadow: "none" }}>
                        <CardContent>
                          <MenuItem
                            onClick={() => handleSortOptionSelect("RECMD")}
                            sx={{
                              minHeight: "46px !important",
                              marginBottom: "10px",
                              background: "#f8f6f6",
                              border: "1px solid #ebeaea",
                            }}
                          >
                            {t("inner_pages.domestic_machine.recommended")}
                          </MenuItem>
                          <MenuItem
                            onClick={() => handleSortOptionSelect("ASC")}
                            sx={{
                              minHeight: "46px !important",
                              marginBottom: "10px",
                              background: "#f8f6f6",
                              border: "1px solid #ebeaea",
                            }}
                          >
                            {t("inner_pages.domestic_machine.price_low_to_h")}
                          </MenuItem>
                          <MenuItem
                            onClick={() => handleSortOptionSelect("DESC")}
                            sx={{
                              minHeight: "46px !important",
                              background: "#f8f6f6",
                              border: "1px solid #ebeaea",
                            }}
                          >
                            {t(
                              "inner_pages.domestic_machine.price_high_to_low"
                            )}
                          </MenuItem>
                        </CardContent>
                      </Card>
                    </Popover>
                  </Box>
                </Box>
              </Box>
              <CardContent className="category_list">
                {jsonLoader ? (
                  <MainCardSkelton />
                ) : paginatedOrderItems?.length > 0 ? (
                  <GridUn
                    container
                    rowSpacing={3}
                    columnSpacing={{ xs: 2, md: 1.5 }}
                    columns={{ xs: 12 }}
                  >
                    {Array.from(Array(paginatedOrderItems?.length)).map(
                      (_, index) => (
                        <ProductMainCard
                          filteredPro={paginatedOrderItems}
                          setCartModalOpen={setCartModalOpen}
                          setModalUrlKey={setModalUrlKey}
                          index={index}
                          setProductData={setProductData}
                        />
                      )
                    )}
                  </GridUn>
                ) : (
                  <div>
                    {t("inner_pages.domestic_machine.no-product-found")}
                  </div>
                )}
              </CardContent>
            </Card>
          </Box>
          {/* {totalPage && ( */}
          <Box sx={{ pt: 4, pb: 2 }}>
            <Box className="deskPagination">
              <Stack className="pagination-controls">
                <Typography className="items-label">
                  {t("buyer_dashboard.myOrderList.Item_per_page")}:
                </Typography>
                <Select
                  value={pageSize}
                  onChange={handlePageSizeChange}
                  variant="outlined"
                  size="small"
                  className="items-select"
                >
                  {array.map((size: any) => (
                    <MenuItem key={size.value} value={size.value}>
                      {size.label}
                    </MenuItem>
                  ))}
                </Select>
              </Stack>

              <Pagination
                count={totalPages}
                shape="rounded"
                page={currentPage}
                onChange={handlePageChange}
                className="pagination-controls"
              />
            </Box>
          </Box>
          {/* )} */}

          {htmlContent && (
            <Box className="coffee-grinders-container">
              <h2>{catMetaData?.name}</h2>
              <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
            </Box>
          )}
        </div>

        {/* )} */}
        {isCartModalOpen && (
          <CartModal
            open={isCartModalOpen}
            setCartModalOpen={setCartModalOpen}
            urlkey={modalUrlKey}
            productdata={productData}
          />
        )}
      </div>
    </>
  );
}
export default React.memo(DomesticMachine);
