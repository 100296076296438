interface SubTopic {
  name: string;
  linkUrl: string;
  details: any;
}

interface LinkInfo {
  name: string;
  linkUrl: string;
  details: any;
}

interface HelpTopic {
  category: string;
  subTopics: SubTopic[];
  additionalInfo?: LinkInfo[];
}

export const helpTopics: HelpTopic[] = [
  {
    category: "Account & Login",
    subTopics: [
      {
        name: "Creating an Account",
        linkUrl: "registration",
        details: [
          `<div style="font-family: 'Poppins', sans-serif; max-width: 1100px; margin: 20px auto; padding: 20px;  ">
  <h2 style="color: #1082c2; text-align: left;">Creating an Account</h2>
  <p style="font-size: 15px; color: #333; margin-bottom:10px;">Learn how to create your Jebelz account and start enjoying exclusive benefits.</p>
  
  <h3 style="color: #1082c2;">Step-by-Step Registration Process</h3>
  <ol style="font-size: 15px; color: #333;">
      <li>Go to the <a href="store/registration" style="color: #d2691e; text-decoration: none;">registration page</a>.</li>
      <li>Fill in your personal details including name and last name.</li>
      <li>Enter your email address and create a secure password.Please enter correct email which was not used previously.</li>
      <li>Add confirmed password.</li>
      <li>Password must be at least 8 characters long, contain at least one uppercase letter, one number, and one special character..</li>
      <li>Click on the 'Create An Account' button.</li>
      <li>Wait while your request is proccessing.</li>
      <li>If you already have an account then log in to your Jebelz account and start shopping!</li>
  </ol>
  <br />
  <h3 style="color: #1082c2;">Troubleshooting Registration Issues</h3>
  <p style="font-size: 15px; color: #333;"><strong>Facing an 401 error?</strong></p>
  <ul style="font-size: 15px; color: #333;">
      <li>Verity your email that is not previously used.</li>
      <li>Check your password.</li>
  </ul>
  <br />
  <h3 style="color: #1082c2;">Why Register?</h3>
  <p style="font-size: 15px; color: #333;">Creating a Jebelz account gives you access to:</p>
  <ul style="font-size: 15px; color: #333;">
      <li>Faster checkout and order tracking.</li>
      <li>Exclusive discounts and early access to new products.</li>
      <li>Personalized recommendations for your favorite coffee blends.</li>
      <li>Saved payment details for seamless shopping.</li>
  </ul>
  <br />
  <h3 style="color: #1082c2;">Need Help?</h3>
  <p style="font-size: 15px; color: #333;">Visit our <a href="#" style="color: #d2691e; text-decoration: none;">Help Center</a> or contact our support team.</p>
</div>
`,
        ],
      },
      {
        name: "Logging In",
        linkUrl: "login",
        details: [
          `<div style="font-family: 'Poppins', sans-serif; max-width: 1100px; margin: 20px auto; padding: 20px; background-color: #ffffff; border-radius: 8px;">
    <h2 style="color: #1082c2; text-align: left;">Logging In</h2>
    <p style="font-size: 15px; color: #333;">Learn how to log in to your Jebelz account and resolve any login issues.</p>
    <br />
    
    <h3 style="color: #1082c2;">Step-by-Step Login Process</h3>
    <ol style="font-size: 15px; color: #333;">
        <li>Go to the <a href="/:country/login" style="color: #d2691e; text-decoration: none;">login page</a>.</li>
        <li>Enter your registered email address and password.</li>
        <li>Click on the 'Login' button.</li>
    <li>Wait while your request is proccessing.</li>
    </ol>
    <br />
    
    <h3 style="color: #1082c2;">Forgot Your Password?</h3>
    <p style="font-size: 15px; color: #333;">Reset it <a href="/:country/reset-password" style="color: #d2691e; text-decoration: none;">here</a>.</p>
    <ul style="font-size: 15px; color: #333;">
        <li>Click on 'Forgot Password' on the login page.</li>
        <li>Enter your registered email address and submit.</li>
        <li>Check your email for a password reset link.</li>
        <li>Follow the link and set a new password.</li>
        <li>Log in with your new credentials.</li>
    </ul>
    <br />
    
    <h3 style="color: #1082c2;">Troubleshooting Login Issues</h3>
    <p style="font-size: 15px; color: #333;"><strong>Having trouble logging in?</strong> Try the following:</p>
    <ul style="font-size: 15px; color: #333;">
        <li>Ensure your email and password are correct.</li>
        <li>Check if Caps Lock is on/off when entering your password.</li>
        <li>Clear your browser cache and cookies.</li>
        <li>Try logging in from a different browser or device.</li>
        <li>Reset your password if necessary.</li>
        <li>If the issue persists, contact <a href="/:country/support" style="color: #d2691e; text-decoration: none;">customer support</a>.</li>
    </ul>
    <br />
    
    <h3 style="color: #1082c2;">Enhancing Account Security</h3>
    <p style="font-size: 15px; color: #333;">Protect your account with these security measures:</p>
    <ul style="font-size: 15px; color: #333;">
        <li>Use a strong, unique password for your account.</li>
        <li>Do not share your login credentials with anyone.</li>
        <li>Regularly update your password and security settings.</li>
    </ul>
    <br />
    
    <h3 style="color: #1082c2;">Need Help?</h3>
    <p style="font-size: 15px; color: #333;">Visit our <a href="#" style="color: #d2691e; text-decoration: none;">Help Center</a> or contact our support team.</p>
</div>
`,
        ],
      },
    ],
    additionalInfo: [
      {
        name: "Security & Passwords",
        linkUrl: "update-password",
        details: [
          `<div style="font-family: 'Poppins', sans-serif; max-width: 1100px; margin: 20px auto; padding: 20px; background-color: #ffffff; border-radius: 8px; ">
    <h2 style="color: #1082c2; text-align: left;">Secuirty and Password</h2>
    <p style="font-size: 15px; color: #333;">Learn about how jebelz provide security.</p>
    <br />
    
    <h3 style="color: #1082c2;">Enhancing Account Security</h3>
    <p style="font-size: 15px; color: #333;">Protect your account with these security measures:</p>
    <ul style="font-size: 15px; color: #333;">
        <li>Enable <strong>Two-Factor Authentication (2FA)</strong> for added security.</li>
        <li>Use a strong, unique password for your account.</li>
        <li>Do not share your login credentials with anyone.</li>
        <li>Regularly update your password and security settings.</li>
    </ul>
    <br />
    
    <h3 style="color: #1082c2;">Security & Passwords</h3>
    <p style="font-size: 15px; color: #333;">Reset your password securely <a href="/:country/customer/account/createPassword" style="color: #d2691e; text-decoration: none;">here</a>.</p>
    <ul style="font-size: 15px; color: #333;">
        <li><strong>Password Tips:</strong> Use a strong, unique password and change it regularly.</li>
        <li><strong>Account Recovery:</strong> Follow the steps to recover your account if you lose access.</li>
    </ul>
    <br />

    <h3 style="color: #1082c2;">Need Help?</h3>
    <p style="font-size: 15px; color: #333;">Visit our <a href="#" style="color: #d2691e; text-decoration: none;">Help Center</a> or contact our support team.</p>
</div>
`,
        ],
      },
    ],
  },
  {
    category: "Orders & Shipping",
    subTopics: [
      {
        name: "Tracking Your Order",
        linkUrl: "tracking",
        details: [
          `<div style="font-family: 'Poppins', sans-serif; max-width: 1100px; margin: 20px auto; padding: 20px; background-color: #ffffff; border-radius: 8px;">
    <h2 style="color: #1082c2; text-align: left;">Tracking Your Order</h2>
    <p style="font-size: 15px; color: #333;">Keep track of your order status in real time.</p>
    <br />
    <h3 style="color: #1082c2;">How to Track Your Order</h3>
    <ol style="font-size: 15px; color: #333;">
        <li>Visit the <a href="/tracking-online" style="color: #d2691e; text-decoration: none;">order tracking page</a>.</li>
        <li>Enter your order number and email address.</li>
        <li>Click on the 'Track Order' button to see your order status.</li>
        <li>Receive real-time updates on your order's shipping and delivery status.</li>
    </ol>
    <br />
    <h3 style="color: #1082c2;">Common Tracking Issues</h3>
    <ul style="font-size: 15px; color: #333;">
        <li><strong>Tracking Not Updating:</strong> Wait a few hours as tracking updates may be delayed.</li>
        <li><strong>Order Not Found:</strong> Ensure you entered the correct order number.</li>
        <li><strong>Delivery Delays:</strong> If there is a delay, you will be notified with an estimated delivery time.</li>
        <li><strong>Need Help?</strong> Contact <a href="/:country/support" style="color: #d2691e; text-decoration: none;">customer support</a> for assistance.</li>
    </ul>
    <br />
    <h3 style="color: #1082c2;">Need Help?</h3>
    <p style="font-size: 15px; color: #333;">Visit our <a href="#" style="color: #d2691e; text-decoration: none;">Help Center</a> or contact our support team.</p>
</div>
`,
        ],
      },
      {
        name: "Order Confirmation",
        linkUrl: "ordersuccess",
        details: [
          `<p>Check your order details <a href='/:country/ordersuccess'>here</a>.</p>`,
          `<p><strong>Order Modifications:</strong> Contact customer service to modify your order before it ships.</p>`,
          `<p><strong>Order Cancellation:</strong> Cancel your order within 24 hours for a full refund.</p>`,
        ],
      },
    ],
    additionalInfo: [
      {
        name: "Shipping Policies",
        linkUrl: "shipping-options",
        details: [
          `<p>Learn about shipping methods <a href='/:country/category/shipping-options'>here</a>.</p>`,
          `<p><strong>International Shipping:</strong> We ship to over 100 countries worldwide.</p>`,
          `<p><strong>Shipping Costs:</strong> Costs vary based on destination and order size.</p>`,
        ],
      },
    ],
  },
  {
    category: "Payments & Pricing",
    subTopics: [
      {
        name: "Payment Methods",
        linkUrl: "payments",
        details: [
          `<p>We accept Credit/Debit Cards, PayPal, and Jebelz Wallet.</p>`,
          `<p><strong>Payment Security:</strong> All transactions are encrypted for your safety.</p>`,
          `<p><strong>Payment Confirmation:</strong> You will receive a confirmation email once your payment is processed.</p>`,
        ],
      },
      {
        name: "Pricing & Discounts",
        linkUrl: "pricing",
        details: [
          `<p><strong>Discount Codes:</strong> Apply discount codes at checkout for savings.</p>`,
          `<p><strong>Subscription Savings:</strong> Save up to 20% with our Coffee Club Membership.</p>`,
          `<p><strong>Price Matching:</strong> Contact us if you find a lower price elsewhere.</p>`,
        ],
      },
    ],
    additionalInfo: [
      {
        name: "Refunds & Cancellations",
        linkUrl: "refunds-cancellations",
        details: [
          `<p>Cancel an order before shipping <a href='/cancel-order'>here</a>.</p>`,
          `<p><strong>Refund Processing:</strong> Refunds are processed within 5-7 business days.</p>`,
          `<p><strong>Cancellation Policy:</strong> Orders can be canceled within 24 hours of placement.</p>`,
        ],
      },
    ],
  },
  {
    category: "Shopping & Categories",
    subTopics: [
      {
        name: "Coffee Beans",
        linkUrl: "coffee-beans",
        details: [
          `<p>Explore our wide range of coffee beans <a href='/:country/category/coffee-beans'>here</a>.</p>`,
          `<p><strong>Single-Origin:</strong> Discover unique flavors from around the world.</p>`,
          `<p><strong>Blends:</strong> Try our expertly crafted blends for a balanced taste.</p>`,
        ],
      },
      {
        name: "Brewing Equipment",
        linkUrl: "brewing-equipment",
        details: [
          `<p>Find the perfect brewing equipment <a href='/:country/category/brewing-equipment'>here</a>.</p>`,
          `<p><strong>French Press:</strong> Ideal for a rich, full-bodied brew.</p>`,
          `<p><strong>Pour-Over:</strong> Perfect for precision and clarity in flavor.</p>`,
        ],
      },
    ],
    additionalInfo: [
      {
        name: "Popular Brands",
        linkUrl: "brands",
        details: [
          `<p>View all brands <a href='/:country/brands.html'>here</a>.</p>`,
          `<p><strong>Featured Brands:</strong> Explore top brands like Blue Mountain, Lavazza, and Stumptown.</p>`,
        ],
      },
    ],
  },
  {
    category: "Help & Support",
    subTopics: [
      {
        name: "Customer Service",
        linkUrl: "help",
        details: [
          `<p>Get assistance from our support team <a href='/:country/help'>here</a>.</p>`,
          `<p><strong>Live Chat:</strong> Chat with a representative in real-time.</p>`,
          `<p><strong>Email Support:</strong> Reach us at support@coffeewebsite.com.</p>`,
        ],
      },
    ],
    additionalInfo: [
      {
        name: "Help Center Details",
        linkUrl: "help-details",
        details: [
          `<p>Find answers to common queries <a href='/:country/help/details/:key'>here</a>.</p>`,
          `<p><strong>FAQs:</strong> Browse frequently asked questions for quick solutions.</p>`,
        ],
      },
    ],
  },
  {
    category: "Returns & Refunds",
    subTopics: [
      {
        name: "Return an Item",
        linkUrl: "return-item",
        details: [
          `<p>Return items within 14 days. <a href='/start-return'>Start return</a>.</p>`,
          `<p><strong>Return Policy:</strong> Items must be unopened and in original packaging.</p>`,
          `<p><strong>Return Shipping:</strong> Free returns for defective or incorrect items.</p>`,
        ],
      },
    ],
    additionalInfo: [
      {
        name: "Warranty & Replacements",
        linkUrl: "replacement-warranty",
        details: [
          `<p>Check warranty and replacement policies <a href='/warranty'>here</a>.</p>`,
          `<p><strong>Defective Items:</strong> Contact us for a replacement or refund.</p>`,
        ],
      },
    ],
  },
  {
    category: "Blog & News",
    subTopics: [
      {
        name: "Latest Blog Posts",
        linkUrl: "blog",
        details: [
          `<p>Read our latest blogs <a href='/blog.html'>here</a>.</p>`,
          `<p><strong>Coffee Tips:</strong> Learn how to brew the perfect cup.</p>`,
          `<p><strong>Industry News:</strong> Stay updated on the latest coffee trends.</p>`,
        ],
      },
    ],
    additionalInfo: [
      {
        name: "Blog Details",
        linkUrl: "blog-details",
        details: [
          `<p>Explore blog topics in detail <a href='/:country/blog/:blogDetail'>here</a>.</p>`,
          `<p><strong>Guest Posts:</strong> Read insights from coffee experts.</p>`,
        ],
      },
    ],
  },
  {
    category: "Brands & Stores",
    subTopics: [
      {
        name: "Find Your Brand",
        linkUrl: "brand-page",
        details: [
          `<p>Shop by brand <a href='/:country/brand/:brandname'>here</a>.</p>`,
          `<p><strong>Top Brands:</strong> Explore Blue Mountain, Lavazza, and Stumptown.</p>`,
        ],
      },
    ],
    additionalInfo: [
      {
        name: "Brand List",
        linkUrl: "brand-list",
        details: [
          `<p>View all brands <a href='/:country/brands.html'>here</a>.</p>`,
          `<p><strong>New Arrivals:</strong> Discover the latest additions to our brand lineup.</p>`,
        ],
      },
    ],
  },
  {
    category: "Membership & Rewards",
    subTopics: [
      {
        name: "Coffee Club Membership",
        linkUrl: "coffee-subscriptions",
        details: [
          `<p>Subscribe & save on your favorite coffee blends! <a href='/subscriptions'>View plans</a>.</p>`,
          `<p><strong>Benefits:</strong> Free shipping, exclusive discounts, and early access to new products.</p>`,
        ],
      },
    ],
    additionalInfo: [
      {
        name: "Rewards Program",
        linkUrl: "loyalty-rewards",
        details: [
          `<p>Earn points on every purchase. Check <a href='/rewards'>reward balance</a>.</p>`,
          `<p><strong>Redeem Points:</strong> Use points for discounts or free products.</p>`,
        ],
      },
    ],
  },
  {
    category: "Checkout & Payment Issues",
    subTopics: [
      {
        name: "Payment Failures",
        linkUrl: "payments",
        details: [
          `<p>Check why your payment failed and how to fix it <a href='/payment-help'>here</a>.</p>`,
          `<p><strong>Common Issues:</strong> Insufficient funds, expired cards, or incorrect details.</p>`,
        ],
      },
    ],
    additionalInfo: [
      {
        name: "Secure Payments",
        linkUrl: "payments",
        details: [
          `<p>Read about safe transactions <a href='/payment-security'>here</a>.</p>`,
          `<p><strong>Encryption:</strong> All payments are processed securely using SSL encryption.</p>`,
        ],
      },
    ],
  },
];

// export const helpTopicsDetails : any = [
//   {
//     linkUrl: "registration",
//     details: [
//       `<div style="font-family: 'Poppins', sans-serif; max-width: 1100px; margin: 20px auto; padding: 20px;">
//         <h2 style="color: #1082c2; text-align: left;">Creating an Account</h2>
//         <p style="font-size: 15px; color: #333; margin-bottom:10px;">Learn how to create your Jebelz account and start enjoying exclusive benefits.</p>
//         <!-- Add more details here -->
//       </div>`,
//     ],
//   },
//   {
//     linkUrl: "login",
//     details: [
//       `<div style="font-family: 'Poppins', sans-serif; max-width: 1100px; margin: 20px auto; padding: 20px;">
//         <h2 style="color: #1082c2; text-align: left;">Logging In</h2>
//         <p style="font-size: 15px; color: #333;">Learn how to log in to your Jebelz account and resolve any login issues.</p>
//         <!-- Add more details here -->
//       </div>`,
//     ],
//   },
//   {
//     linkUrl: "update-password",
//     details: [
//       `<div style="font-family: 'Poppins', sans-serif; max-width: 1100px; margin: 20px auto; padding: 20px;">
//         <h2 style="color: #1082c2; text-align: left;">Security and Password</h2>
//         <p style="font-size: 15px; color: #333;">Learn about how Jebelz provides security.</p>
//         <!-- Add more details here -->
//       </div>`,
//     ],
//   },
//   // Add other linkUrl details similarly
// ];

// export const helpTopicsLinks : any = [
//   {
//     category: "Account & Login",
//     subTopics: [
//       {
//         name: "Creating an Account",
//         linkUrl: "registration",
//       },
//       {
//         name: "Logging In",
//         linkUrl: "login",
//       },
//     ],
//     additionalInfo: [
//       {
//         name: "Security & Passwords",
//         linkUrl: "update-password",
//       },
//     ],
//   },
//   {
//     category: "Orders & Shipping",
//     subTopics: [
//       {
//         name: "Tracking Your Order",
//         linkUrl: "tracking",
//       },
//       {
//         name: "Order Confirmation",
//         linkUrl: "ordersuccess",
//       },
//     ],
//     additionalInfo: [
//       {
//         name: "Shipping Policies",
//         linkUrl: "shipping-options",
//       },
//     ],
//   },
//   // Add other categories and sub-topics similarly
// ];
