import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  MenuItem,
  Pagination,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import "./ReturnCompleted.scss";
import NoReturnCompleted from "../noreturnCompleted/NoReturnCompleted";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";
import * as queries from "../../../../../operations/queries";
import { CountryContext } from "../../../MainComponent";
import Loader from "../../loader/Loader";

const ReturnCompleted = () => {
  const { t } = useTranslation();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const loggedInToken = localStorage.getItem("loggedInCust");
  const navigate = useNavigate();
  const { store } = useContext(CountryContext);

  const array = [
    { value: 10, label: "10" },
    { value: 15, label: "15" },
    { value: 20, label: "20" },
    { value: 30, label: "30" },
  ];
  
  const {
    data : returnListData,
    refetch: refetchListData,
    loading: returnLoading,
  } = useQuery(queries.returnQueries.GET_RETURN_REQUESTS, {
    variables: { type: "list" },
    fetchPolicy: "network-only",
    context: {
      headers: {
        Authorization: loggedInToken
          ? `Bearer ${JSON.parse(loggedInToken)}`
          : "",
        Connection: "keep-alive",
        store: store,
      },
    },
  });

  const returnRequestList = returnListData?.getReturnRequests
  useEffect(() => {
    if (returnListData) {
      refetchListData();
    }
  }, [returnListData, refetchListData,store]);

  const filteredReturnRequests = returnRequestList?.filter(
    (item: any) => item.return_status === "return_aws_generated" || item.return_status === "return_accepted"
  );

  const returnRequestsWithCompleteStatus = filteredReturnRequests?.map(
    (item: any) => ({
      ...item,
      status: item.return_status === "return_aws_generated" ? t("buyer_dashboard.return.approved") : t("buyer_dashboard.return.completed"),
    })
  );

  const closePage = () => {
    const currentPath = window.location.pathname;
    const basePath = currentPath.split("/return-completed")[0];
    navigate(basePath);
  };

  // const handlOrderClaim = (orderNumber: any, id: any) => {
  //   console.log("orderNumber", orderNumber);
  //   const currentPath = window.location.pathname;
  //   const basePath = currentPath.split("/my-returns")[0];
  //   const orderLink = `${basePath}/claims/${orderNumber}?id=${id}&props=add`;
  //   navigate(orderLink);
  // };

  const handlePageChange = (newPage: any) => {
    setCurrentPage(newPage);
  };

  const handlePageSizeChange = (event: any) => {
    setPageSize(event.target.value);
    setCurrentPage(1);
  };

  const totalItems = returnRequestsWithCompleteStatus?.length;
  const totalPages = Math.ceil(totalItems / pageSize);
  const paginatedItems = returnRequestsWithCompleteStatus
    ?.sort(
      (a: any, b: any) =>
        new Date(b.refund_date).getTime() - new Date(a.refund_date).getTime()
    )
    ?.slice((currentPage - 1) * pageSize, currentPage * pageSize);

  return (
    <div className="return-items">
      <div className="heading-sec">
        <div className="name-sec">
          <h2 className="title">{t("buyer_dashboard.return.title")}</h2>
          <p className="subtitle">{t("buyer_dashboard.return.return_desc")}</p>
        </div>

        {returnRequestsWithCompleteStatus?.length !== 0 && (
          <div className="btn-sec">
            <button onClick={() => closePage()} style={{ cursor: "pointer" }}>
              {t("buyer_dashboard.return.back_return_btn")}
            </button>
          </div>
        )}
      </div>

      {returnRequestsWithCompleteStatus?.length === 0 ? (
        <NoReturnCompleted />
      ) : !returnLoading ? (
        <>
          <div className="desktop-view">
          <Box
              sx={{
                overflowX: "auto",
                "&::-webkit-scrollbar": {
                  height: "6px",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "#888",
                  borderRadius: "10px",
                },
                "&::-webkit-scrollbar-thumb:hover": {
                  backgroundColor: "#555",
                },
                "&::-webkit-scrollbar-track": {
                  backgroundColor: "#f1f1f1",
                },
              }}
            >
            <Table sx={{ backgroundColor: "white" }}>
              <TableHead>
                <TableRow>
                  <TableCell className="header-cell">
                    {t("buyer_dashboard.return.product")}
                  </TableCell>
                  <TableCell className="header-cell">
                    {t("buyer_dashboard.return.order")}
                  </TableCell>
                  <TableCell className="header-cell">
                    {t("buyer_dashboard.return.product_name")}
                  </TableCell>
                  <TableCell className="header-cell">
                    {" "}
                    {t("buyer_dashboard.return.date")}
                  </TableCell>
                  <TableCell className="header-cell">
                    {" "}
                    {t("buyer_dashboard.return.price")}
                  </TableCell>
                  <TableCell className="header-cell">
                    {t("buyer_dashboard.return.status")}
                  </TableCell>
                  {/* <TableCell className="header-cell">{t("buyer_dashboard.return.add_claim")}</TableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                {paginatedItems?.map((item: any) => (
                  <TableRow key={item?.id}>
                    <TableCell className="body-cell">
                      {item?.product_image ? (
                        <img
                          src={item?.product_image}
                          alt="Product"
                          className="product-image"
                        />
                      ) : (
                        <span className="no-image">No Image</span>
                      )}
                    </TableCell>
                    <TableCell className="body-cell">
                      {item.order_number}
                    </TableCell>
                    <TableCell className="body-cell">
                      {item.product_name}
                    </TableCell>
                    <TableCell className="body-cell">
                      {new Date(item.updated_at).toLocaleDateString("en-GB")}
                    </TableCell>
                    <TableCell className="body-cell">
                      {Number(item.refundable_amount).toFixed(2)}
                    </TableCell>
                    <TableCell className="body-cell">
                      <span className={`status ${item.status.toLowerCase()}`}>
                        {item.status}
                      </span>
                    </TableCell>
                    {/* <TableCell className="body-cell">
                      {(() => {
                        const refundDate = new Date(item.updated_at);

                        const currentDate = new Date();
                        const diffTime = Math.abs(
                          currentDate.getTime() - refundDate.getTime()
                        );
                        const diffDays = diffTime / (1000 * 60 * 60 * 24);

                        return diffDays <= 7 ? (
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              cursor: "pointer",
                            }}
                          >
                            <button
                              onClick={() =>
                                handlOrderClaim(
                                  item.order_number,
                                  item?.item_id
                                )
                              }
                            >
                              {t("buyer_dashboard.return.add_claim")}
                            </button>
                          </Box>
                        ) : (
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            Claim period is over
                          </Box>
                        );
                      })()}
                    </TableCell> */}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            </Box>
          </div>

          <div className="mobile-view">
            {paginatedItems?.map((item: any) => (
              <div className="table-container" key={item.id}>
                <Table sx={{ backgroundColor: "white" }}>
                  <TableBody>
                    <TableRow>
                      <TableCell className="header">Product</TableCell>
                      <TableCell>
                        {item.product_image ? (
                          <img
                            src={item.product_image}
                            alt="Product"
                            className="product-image"
                          />
                        ) : (
                          <span className="no-image">No Image</span>
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className="header">
                        {t("buyer_dashboard.return.order")}
                      </TableCell>
                      <TableCell>
                        {" "}
                        {Number(item.refundable_amount).toFixed(2)}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className="header">
                        {t("buyer_dashboard.return.product_name")}
                      </TableCell>
                      <TableCell>{item.product_name}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className="header">
                        {" "}
                        {t("buyer_dashboard.return.date")}
                      </TableCell>
                      <TableCell>
                        {new Date(item.updated_at).toLocaleDateString("en-GB")}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className="header">
                        {" "}
                        {t("buyer_dashboard.return.price")}
                      </TableCell>
                      <TableCell>{item.refundable_amount}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className="header">
                        {t("buyer_dashboard.return.status")}
                      </TableCell>
                      <TableCell>
                        <span className={`status ${item.status.toLowerCase()}`}>
                          {item.status}
                        </span>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      {/* <TableCell className="header">Add claim</TableCell>
                      <TableCell className="body-cell">
                        {(() => {
                          const refundDate = new Date(item.refund_date);
                          const currentDate = new Date();
                          const diffTime = Math.abs(
                            currentDate.getTime() - refundDate.getTime()
                          );
                          const diffDays = diffTime / (1000 * 60 * 60 * 24);

                          return diffDays <= 7 ? (
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                cursor: "pointer",
                              }}
                            >
                              <button
                                onClick={() =>
                                  handlOrderClaim(
                                    item.order_number,
                                    item?.item_id
                                  )
                                }
                              >
                                Add A Claim
                              </button>
                            </Box>
                          ) : (
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              Claim period is over
                            </Box>
                          );
                        })()}
                      </TableCell> */}
                    </TableRow>
                  </TableBody>
                </Table>
              </div>
            ))}
          </div>
          {totalItems > 9 && (
            <Box className="deskPagination">
              <Stack className="pagination-controls">
                <Typography className="items-label">
                  {t("buyer_dashboard.myOrderList.Item_per_page")}:
                </Typography>
                <Select
                  value={pageSize}
                  onChange={handlePageSizeChange}
                  variant="outlined"
                  size="small"
                  className="items-select"
                >
                  {array.map((size: any) => (
                    <MenuItem key={size.value} value={size.value}>
                      {size.label}
                    </MenuItem>
                  ))}
                </Select>
              </Stack>

              <Pagination
                count={totalPages}
                page={currentPage}
                onChange={handlePageChange}
                shape="rounded"
              />
            </Box>
          )}
        </>
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default ReturnCompleted;
