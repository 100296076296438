import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  Box,
  Typography,
} from '@mui/material';
import { Visibility, VisibilityOff, Close } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useMutation } from "@apollo/client";
import * as mutations from "../../../../../operations/mutations";
import { toast } from 'react-toastify';

interface UpdatePasswordModalProps {
  isOpen: boolean;
  handleClose: () => void;
}

const UpdatePasswordModal: React.FC<UpdatePasswordModalProps> = ({ isOpen, handleClose }) => {
  const [formData, setFormData] = useState({
    currentPassword: "",
    newPassword: "",
  });
  const [confPassword, setConfPassword] = useState("");
  const [showPassword, setShowPassword] = useState<{ [key: string]: boolean }>({
    current: false,
    new: false,
    confirm: false,
  });
  const { t } = useTranslation();
  const loggedInToken = localStorage.getItem("loggedInCust");

  const [changePassword] = useMutation(
    mutations.customerMutation.CHANGE_PASSWORD_MUTATION,
    {
      context: {
        headers: {
          Authorization: loggedInToken ? `Bearer ${JSON.parse(loggedInToken)}` : "",
          Connection: "keep-alive",
        },
      },
    }
  );

  const handleTogglePasswordVisibility = (field: string) => {
    setShowPassword((prev) => ({ ...prev, [field]: !prev[field] }));
  };

const handleUpdatePassword = async (e: React.FormEvent) => {
  e.preventDefault();
  
  try {
    const response = await changePassword({
      variables: {
        currentPassword: formData.currentPassword,
        newPassword: formData.newPassword,
      },
    });

    if (response.data?.changeCustomerPassword) {
      toast.success(t("buyer_dashboard.update_pwd.pwd_chng_msg"));
      setFormData({ currentPassword: "", newPassword: "" });
      setConfPassword("");
      handleClose();
    } else {
      toast.error(t("buyer_dashboard.update_pwd.err_msg"));
    }
  } catch (error : any) {
    if (error.networkError && error.networkError.result) {
      const networkErrorResponse = error.networkError.result;
      if (
        networkErrorResponse.errors &&
        networkErrorResponse.errors.length > 0
      ) {
        const errorMessages = networkErrorResponse.errors
          .map((err: any) => err.message)
          .join(", ");
          toast.error(errorMessages);
      } else {
        toast.error(error.networkError.message);
      }
    } else if (error.graphQLErrors && error.graphQLErrors.length > 0) {
      const errorMessages = error.graphQLErrors
        .map((err: any) => err.message)
        .join(", ");
        toast.error(errorMessages);
    } else {
      toast.error(t("auth.registration.user_err"));
    }
  }
};

  const isButtonDisabled = !formData.currentPassword || !formData.newPassword || !confPassword || formData.newPassword !== confPassword;

  return (
    <Dialog open={isOpen} onClose={handleClose} fullWidth maxWidth="xs">
      <Box sx={{ borderRadius: '2px', p: 2 }}>
        <Box display="flex" justifyContent="space-between" alignItems="center" px={2} pt={1}>
          <DialogTitle sx={{ p: 0, fontWeight: 'bold', fontSize: '1.25rem' }}>{t("buyer_dashboard.update_pwd.title")}</DialogTitle>
          <IconButton onClick={handleClose}>
            <Close />
          </IconButton>
        </Box>
        <hr style={{ margin: '10px -15px', borderColor: '#F0ECEC' }} />
        <DialogContent>
          <Box mb={2}>
            <Typography gutterBottom sx={{ mb: 1.5, fontWeight: '500', fontSize: '14px' }}>{t("buyer_dashboard.update_pwd.current_pwd")}</Typography>
            <Box display="flex" alignItems="center" border="1px solid #F0ECEC" borderRadius="2px" p={1}>
              <input
                type={showPassword.current ? 'text' : 'password'}
                value={formData.currentPassword}
                onChange={(e) => setFormData({ ...formData, currentPassword: e.target.value })}
                style={{ flexGrow: 1, border: 'none', outline: 'none', fontSize: '14px', padding: 7 }}
                placeholder={t("buyer_dashboard.update_pwd.current_pwd")}
              />
              <InputAdornment position="end">
                <IconButton onClick={() => handleTogglePasswordVisibility('current')}>
                  {showPassword.current ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            </Box>
          </Box>

          <Box mb={2}>
            <Typography variant="body2" gutterBottom sx={{ mb: 1.5, fontWeight: '500', fontSize: '14px' }}>{t("buyer_dashboard.update_pwd.new_pwd")}</Typography>
            <Box display="flex" alignItems="center" border="1px solid #F0ECEC" borderRadius="2px" p={1}>
              <input
                type={showPassword.new ? 'text' : 'password'}
                value={formData.newPassword}
                onChange={(e) => setFormData({ ...formData, newPassword: e.target.value })}
                style={{ flexGrow: 1, border: 'none', outline: 'none', fontSize: '14px', padding: 7 }}
                placeholder={t("buyer_dashboard.update_pwd.new_pwd")}
              />
              <InputAdornment position="end">
                <IconButton onClick={() => handleTogglePasswordVisibility('new')}>
                  {showPassword.new ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            </Box>
          </Box>

          <Box mb={2}>
            <Typography variant="body2" gutterBottom sx={{ mb: 1.5, fontWeight: '500', fontSize: '14px' }}>{t("buyer_dashboard.update_pwd.conf_pwd")}</Typography>
            <Box display="flex" alignItems="center" border="1px solid #F0ECEC" borderRadius="2px" p={1}>
              <input
                type={showPassword.confirm ? 'text' : 'password'}
                value={confPassword}
                onChange={(e) => setConfPassword(e.target.value)}
                style={{ flexGrow: 1, border: 'none', outline: 'none', fontSize: '14px', padding: 7 }}
                placeholder={t("buyer_dashboard.update_pwd.conf_pwd")}
              />
              <InputAdornment position="end">
                <IconButton onClick={() => handleTogglePasswordVisibility('confirm')}>
                  {showPassword.confirm ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={handleUpdatePassword}
            fullWidth
            disabled={isButtonDisabled}
            style={{
              backgroundColor: isButtonDisabled ? '#e0e0e0' : '#3f51b5',
              color: '#fff',
              margin: "0px 17px",
              padding: '8px',
              borderRadius: 0 
            }}
          >
           {t("buyer_dashboard.update_pwd.update_pwd_btn")}
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default UpdatePasswordModal;
