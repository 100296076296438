import React, { useContext } from "react";
import "./OrderSummary.scss";
import CloseIcon from "@mui/icons-material/Close";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useNavigate, useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import * as queries from "../../../../../operations/queries";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { CountryContext } from "../../../MainComponent";
import Loader from "../../loader/Loader";

const OrderSummary = () => {
  const { store } = useContext(CountryContext);
  const { orderNumber } = useParams();
  const { t } = useTranslation();
  const loggedInToken = localStorage.getItem("loggedInCust");
  const navigate = useNavigate();
  // let subTotal = 0;

  const urlParams = new URLSearchParams(window.location.search);
  const idFromUrl = urlParams.get("id");

  const { data: orderDetailsRes, loading } = useQuery(
    queries.customerQueries.GET_ORDER_DETAILS,
    {
      variables: {
        order_number: orderNumber,
        item_id: idFromUrl,
      },
      context: {
        headers: {
          Authorization: loggedInToken
            ? `Bearer ${JSON.parse(loggedInToken)}`
            : "",
          Connection: "keep-alive",
        },
      },
    }
  );

  const { data: invoiceDetails } = useQuery(
    queries.customerQueries.GET_INVOICE_LINK,
    {
      variables: {
        order_number: orderNumber,
        item_id: idFromUrl,
      },
      context: {
        headers: {
          Authorization: loggedInToken
            ? `Bearer ${JSON.parse(loggedInToken)}`
            : "",
          Connection: "keep-alive",
          "Content-Type": "application/json",
          store: store,
        },
      },
    }
  );

  const handleDownload = () => {
    if (invoiceDetails?.customerOrderInvoice?.[0]?.invoice_url) {
      const invoiceUrl = invoiceDetails?.customerOrderInvoice?.[0]?.invoice_url;

      const link = document.createElement("a");
      link.href = invoiceUrl;
      link.setAttribute("download", "invoice.pdf");
      link.setAttribute("target", "_blank");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      toast.error("Invoice URL not available.");
    }
  };

  const orderDetails = orderDetailsRes?.customerOrdersDetail?.[0] || [];
  console.log("orderDetails", orderDetails);

  // const grandTotal =
  //   orderDetails?.total?.grand_total?.currency +
  //   " " +
  //   orderDetails?.total?.grand_total?.value;

  // orderDetails?.items?.forEach((data: any) => {
  //   subTotal += data?.product_sale_price?.value * data?.quantity_ordered;
  // });

  const orderTrackingPage = () => {
    const currentPath = window.location.pathname;
    const basePath = currentPath.split("/order")[0];
    const orderLink = `${basePath}/tracking/${orderNumber}?id=${idFromUrl}`;
    navigate(orderLink);
  };

  const closePage = () => {
    const currentPath = window.location.pathname;
    const basePath = currentPath.split("/order")[0];
    navigate(basePath);
  };

  return (
    <>
      <div className="tracking-details-main-box directionrtl">
        <div className="tracking-heder">
          <div className="title">
            <h4>{t("buyer_dashboard.OrderSummary.title")}</h4>
            <p>{t("buyer_dashboard.OrderSummary.desc")}</p>
          </div>
          <div className="close-btn">
            <button onClick={() => closePage()}>
              <CloseIcon />
            </button>
          </div>
        </div>

        {loading ? (
          <Loader />
        ) : (
          <>
            <div className="tracking-body">
              <div className="tracking-details">
                <div className="tracking-item-summary">
                  <div className="main-title">
                    <h2>
                      {t("buyer_dashboard.OrderSummary.order_id")} {orderNumber}
                    </h2>

                    <h4>
                      {orderDetails?.status} on{" "}
                      {new Date(orderDetails?.date).toLocaleDateString('en-GB')} at{" "}
                      {new Date(orderDetails?.date).toLocaleTimeString()}
                    </h4>
                  </div>

                  <div className="title">
                    <h5>{t("buyer_dashboard.OrderSummary.status")}</h5>
                    <p>{orderDetails?.status}</p>
                  </div>

                  <TableContainer component={Paper}>
                    <Table aria-label="order details table">
                      <TableHead>
                        <TableRow>
                          <TableCell align="center">
                            <p>{t("buyer_dashboard.OrderSummary.Product")}</p>
                          </TableCell>
                          <TableCell align="center">
                            <p>{t("buyer_dashboard.OrderSummary.SKU")}</p>
                          </TableCell>
                          <TableCell align="center">
                            <p>{t("buyer_dashboard.OrderSummary.Quantity")}</p>
                          </TableCell>
                          <TableCell align="center">
                            <p>{t("buyer_dashboard.OrderSummary.Price")}</p>
                          </TableCell>
                          <TableCell align="center">
                            <p>
                              {t("buyer_dashboard.OrderSummary.Total_Price")}
                            </p>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key={orderDetails?.product_sku}>
                          <TableCell align="center">
                            <Box>
                              <img
                                src={orderDetails?.product_image}
                                alt="Product"
                                style={{ maxWidth: "70px" }}
                              />
                            </Box>
                          </TableCell>
                          <TableCell align="center">
                            <p>{orderDetails?.product_name}</p>
                          </TableCell>
                          <TableCell align="center">
                            <p>{orderDetails?.qty}</p>
                          </TableCell>

                          <TableCell align="center">
                            <p>
                              {orderDetails?.currency} {orderDetails?.price}
                            </p>
                          </TableCell>
                          <TableCell align="center">
                            <p>
                              {orderDetails?.currency}{" "}
                              {orderDetails?.price * orderDetails?.qty}
                            </p>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>

                  <div className="view-more">
                    <button onClick={() => orderTrackingPage()}>
                      {t("buyer_dashboard.OrderSummary.view_more")}
                    </button>
                  </div>
                </div>
              </div>
              <div className="tracking-summary-details">
                <div className="tracking-order-invoice">
                  <h5>{t("buyer_dashboard.OrderSummary.invoice")}</h5>

                  <div>
                    <p>{t("buyer_dashboard.OrderSummary.Subtotal")}</p>

                    <p>
                      {orderDetails?.currency} {orderDetails?.price}
                    </p>
                  </div>

                  <div>
                    <p>{t("buyer_dashboard.OrderSummary.Shipping_Fee")}</p>

                    <p className="free">{orderDetails?.currency} 20.00</p>
                  </div>

                  <div>
                    <p>{t("buyer_dashboard.OrderSummary.COD_fee")}</p>

                    <p>{orderDetails?.currency} 10.00</p>
                  </div>

                  <hr />

                  <div className="order-invoice">
                    <p>{t("buyer_dashboard.OrderSummary.Order_Total")}</p>

                    <p>
                      {orderDetails?.currency} {orderDetails?.price + 10 + 20}
                    </p>
                  </div>

                  <hr />

                  <div className="payment-method">
                    <p>{t("buyer_dashboard.OrderSummary.Payment_Method")}</p>

                    {/* <p>{orderDetails?.payment_methods[0]?.name}</p> */}
                  </div>
                </div>

                <div className="tracking-invoice-summary">
                  <div>
                    <h5>
                      {t("buyer_dashboard.OrderSummary.download_invoice")}
                    </h5>
                    <p>{t("buyer_dashboard.OrderSummary.invoice_desc")}</p>
                  </div>

                  <button onClick={handleDownload}>
                    <KeyboardArrowDownIcon />
                  </button>
                </div>

                {/* <div className="tracking-invoice-summary">
              <div>
                <h5>Two Days Delivery</h5>
                <p>
                  Select this option to receive all your eligible express items
                  within 2 days.
                </p>
              </div>
            </div> */}

                <div className="tracking-delivery-address">
                  <h5>{t("buyer_dashboard.OrderTracking.Delivery_address")}</h5>
                  <p>
                    {
                      orderDetailsRes?.customerOrdersDetail?.[0]
                        ?.shipping_address?.[0]?.firstname
                    }{" "}
                    {
                      orderDetailsRes?.customerOrdersDetail?.[0]
                        ?.shipping_address?.[0]?.lastname
                    }
                    <br />
                    {
                      orderDetailsRes?.customerOrdersDetail?.[0]
                        ?.shipping_address?.[0]?.street
                    }
                    ,{" "}
                    {
                      orderDetailsRes?.customerOrdersDetail?.[0]
                        ?.shipping_address?.[0]?.city
                    }
                    ,{" "}
                    {
                      orderDetailsRes?.customerOrdersDetail?.[0]
                        ?.shipping_address?.[0]?.region
                    }
                    {/* { orderDetailsRes?.customerOrdersDetail[0]?.shipping_address[0]?.country} */}
                    <br />
                  </p>
                  <h6>
                    {" "}
                    {
                      orderDetailsRes?.customerOrdersDetail?.[0]
                        ?.shipping_address?.[0]?.telephone
                    }{" "}
                    <span className="verified" style={{ color: "green" }}>
                      {t("buyer_dashboard.OrderTracking.Verified")}
                    </span>
                  </h6>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default OrderSummary;
