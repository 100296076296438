import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
// import OrderSuccess from "./auth/OrderSuccess";
import * as queries from "../../operations/queries";
import { useLazyQuery } from "@apollo/client";
import { ToastContainer } from "react-toastify";
import i18n from "../../i18n";
import axios from "axios";
import NotFoundPage from "../../components/404page/NotFoundPage";

import RegSuccess from "./auth/RegSuccess";
import Dashboard from "./dashboard";
import Checkout from "./auth/checkout";

export type ICountryContext = {
  country: string;
  setCountry: React.Dispatch<React.SetStateAction<string>>;
};

export const CountryContext = createContext({
  country: "ae_ar",
  setCountry: (country: string) => {},
  store: "",
  setStore: (store: string) => {},
  loading: true,
  setLoading: (loading: boolean) => {},
  currentStore: "",
});
export const LoadingContext = createContext({
  Loading: true,
  setLoading: (loading: boolean) => {},
});


export const mainContext = createContext({
  cartData: [],
  setCartData: (data: any) => {},
  cartCount: 0,
  setCartCount: (count: any) => {},
  quest_cart_id: null,
  set_quest_cart_id: (id: any) => {},
  cartAdded: false,
  setCartAdded: (id: any) => {},
  cartId: "",
  setCartId: (count: any) => {},
  wishlistData: [],
  isAdded: false,
  setIsAdded: (() => {}) as Dispatch<SetStateAction<boolean>>,
  isUpdate: false,
  setIsUpdate: (() => {}) as Dispatch<SetStateAction<boolean>>,
  // detailsData: null,
  // setDetailsData: (store: string) => {},
});

function MainComponent() {
  const storePath = window.location.pathname;
  const selectedStores = storePath?.split("/")?.[1];
  const [country, setCountry] = useState("ae_ar");
  const [store, setStore] = useState("");
  const [loading, setLoading] = useState(true);
  const [cartData, setCartData] = useState([]);
  const [cartCount, setCartCount] = useState(0);
  const [cartId, setCartId] = useState("");
  const [cartAdded, setCartAdded] = useState<any>(false);
  const [wishlistData, setWishlistData] = useState([]); 
  const [isAdded, setIsAdded] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  // const [detailsData, setDetailsData] = useState<any>();
  const sessionStoreData = sessionStorage.getItem("storeAndCart");
  const loggedInToken = localStorage.getItem("loggedInCust");
  const userLoggedIn = localStorage.getItem("LoggedInUser");

  const currentsessionStore = sessionStoreData
    ? JSON.parse(sessionStoreData)
    : [];
  // const storeCode:any = localStorage.getItem('store_code');

  // eslint-disable-next-line
  const filteredArray =
    currentsessionStore &&
    currentsessionStore?.filter(
      (object: any) => object.genStore === selectedStores
    );
  const [quest_cart_id, set_quest_cart_id] = useState<any>();

  const [
    fetchQueryId,
    { data: custQueryDataRes, refetch: custCartQueryDataRefetch },
  ] = useLazyQuery(queries.customerQueries.GET_CUSTOMER_CART_QUERY, {
    context: {
      headers: {
        Authorization: loggedInToken
          ? `Bearer ${JSON.parse(loggedInToken)}`
          : "",
        Connection: "keep-alive",
        store: store && store !== "" ? store : storePath?.split("/")?.[1],
      },
    },
  });

  const [
    fetchWishlistData,
    { data: custWishlistData , refetch : refetchWishlistData },
  ]= useLazyQuery(
    queries.wishlistQueries.GET_CUSTOMER_WISHLISTS,
    {
      context: {
        headers: {
          Authorization: loggedInToken
            ? `Bearer ${JSON.parse(loggedInToken)}`
            : "",
          Connection: "keep-alive",
          store: store && store !== "" ? store : storePath?.split("/")?.[1],
        },
      },
    }
  );

  useEffect(() => { 
    if (loggedInToken && userLoggedIn === "true") {
      fetchQueryId();
      fetchWishlistData();
    } 
  }, [loggedInToken, userLoggedIn,fetchQueryId,fetchWishlistData]);

  useEffect(()=>{
    if(custWishlistData){
    setWishlistData(custWishlistData && custWishlistData?.customer?.wishlists[0]?.items_v2?.items)
    const wishlistId = custWishlistData?.customer?.wishlists[0]?.id;
      localStorage.setItem("wishlistId", wishlistId);
    }
  },[custWishlistData])

  useEffect(()=>{
    if (loggedInToken && userLoggedIn === "true") {
    refetchWishlistData()
    }
  },[isAdded , refetchWishlistData ,loggedInToken ,userLoggedIn])

  if (
    custWishlistData &&
    custWishlistData?.customer?.wishlists[0]?.id !== "undefined"
  ) {
    const wishlistId = custWishlistData?.customer?.wishlists[0]?.id;
    localStorage.setItem("wishlistId", wishlistId);
  }

  useEffect(() => {
    if (!loggedInToken && userLoggedIn !== null) {
      custCartQueryDataRefetch();
    }
  }, [loggedInToken, custCartQueryDataRefetch, userLoggedIn]);
  
  useEffect(() => {
    if (
      loggedInToken !== null &&
      loggedInToken !== undefined &&
      loggedInToken !== ""
    ) {
      set_quest_cart_id(custQueryDataRes?.customerCart?.id);
    } else if (loggedInToken === "" || currentsessionStore.length > 0) {
      const filteredArray =
        currentsessionStore &&
        currentsessionStore?.filter(
          (object: any) => object.genStore === selectedStores
        );
      if (filteredArray[0]) {
        set_quest_cart_id(filteredArray[0].sessionGenCartId);
      }
    }
    // eslint-disable-next-line
  }, [loggedInToken, currentsessionStore, custQueryDataRes]);

  let storeLang: string = "";
  let countrys: string = "";
  const [currentStore, setCurrentStore] = useState("");

  useEffect(() => {
    const path = window.location.pathname;
    const selecteStore = path?.split("/");
    if (selecteStore[1] === "in") {
      // eslint-disable-next-line
      storeLang = "in";
      // eslint-disable-next-line
      countrys = "in";
    } else if (selecteStore[1] === "ae_en") {
      storeLang = "ae_en";
      countrys = "ae_ar";
    } else if (selecteStore[1] === "ae_ar") {
      storeLang = "ae_ar";
      countrys = "ae_ar";
    } else if (selecteStore[1] === "sa_ar") {
      storeLang = "sa_ar";
      countrys = "sa_en";
    } else if (selecteStore[1] === "sa_en") {
      storeLang = "sa_en";
      countrys = "sa_en";
    } else if (selecteStore[1] === "ar") {
      storeLang = "ar";
      countrys = "en";
    } else if (selecteStore[1] === "en") {
      storeLang = "en";
      countrys = "en";
    } else {
      axios
        .get("https://ipapi.co/json/")
        .then((response) => {
          let data = response.data;
          if (
            data?.country_code === "UA" ||
            data?.country_code === "ARE" ||
            data?.country_code === "AE"
          ) {
            storeLang = "ae_en";
            countrys = "ae_ar";
          } else if (
            data?.country_code === "SD" ||
            data?.country_code === "SAU" ||
            data?.country_code === "SA"
          ) {
            storeLang = "sa_ar";
            countrys = "sa_en";
          } else if (
            data?.country_code === "OM" ||
            data?.country_code === "OMN" ||
            data?.country_code === "OM"
          ) {
            storeLang = "ar";
            countrys = "en";
          } else if (
            data?.country_code === "IN" ||
            data?.country_code === "IND"
          ) {
            storeLang = "in";
            countrys = "in";
          } else {
            storeLang = "ae_en";
            countrys = "ae_ar";
          }
          // storeLang = "in";
          // country = "in";
          setStore(storeLang);
          setCountry(country);
          setCurrentStore(storeLang);
        })
        .catch((error) => {
          console.log(error);
        });
    }
    setStore(storeLang);
    setCountry(countrys);
    setCurrentStore(storeLang);
  }, []);

  useEffect(() => {
    if (store && store !== "" && store !== undefined) {
      if (store === "en" || store.split("_")[1] === "en" || store === "in") {
        let beforeVal: any;
        if (store.indexOf("_") !== -1) {
          beforeVal = store.split("_")[0] + "_ar";
        } else {
          // eslint-disable-next-line
          beforeVal = "en";
        }
        i18n.changeLanguage("en");
        document.documentElement.setAttribute("lang", "en");
        document.body.classList.add("en");
        document.body.classList.remove("ar");
      } else {
        let beforeVal: any;
        if (store.indexOf("_") !== -1) {
          beforeVal = store.split("_")[0] + "_en";
        } else {
          // eslint-disable-next-line
          beforeVal = "ar";
        }
        i18n.changeLanguage("ar");
        document.documentElement.setAttribute("lang", "ar");
        document.body.classList.add("ar");
        document.body.classList.remove("en");
      }
    }
  }, [store]);

  return (
    // <BrowserRouter basename="/jebelz-ui"></BrowserRouter>
    <CountryContext.Provider
      value={{
        country,
        setCountry,
        store,
        setStore,
        loading,
        setLoading,
        currentStore,
      }}
    >
      <mainContext.Provider
        value={{
          cartData,
          setCartData,
          cartCount,
          setCartCount,
          quest_cart_id,
          set_quest_cart_id,
          cartAdded,
          setCartAdded,
          cartId,
          setCartId,
          wishlistData,
          isAdded,
          setIsAdded ,
          isUpdate,
          setIsUpdate,
          // detailsData,
          // setDetailsData
        }}
      >
        <BrowserRouter basename="/">
          <ToastContainer
            position="top-right"
            autoClose={3000}
            rtl={
              store === "ae_ar" || store === "ar" || store === "sa_ar"
                ? true
                : false
            }
          />
            <Routes>
              <Route path="/regsuccess" element={<RegSuccess />} />
              <Route path="/*" element={<Dashboard />} />
              <Route path="/:country/checkout" element={<Checkout />} />
              <Route
                path="/:country/checkout/?paymentFail=true"
                element={<Checkout />}
              />
              <Route path="*" element={<NotFoundPage />} />
            </Routes>
        </BrowserRouter>
      </mainContext.Provider>
    </CountryContext.Provider>
  );
}

export default MainComponent;
