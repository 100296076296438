import { useQuery } from "@apollo/client";
import * as queries from "../../operations/queries";
import { useContext, useEffect, useState } from "react";
import { CountryContext } from "../../container/buyer/MainComponent";
import { useLocation } from "react-router-dom";
import "./payment.scss";
import { Helmet } from "react-helmet";
import { Box, Container, Skeleton } from "@mui/material";

function Payment() {
  const { store } = useContext(CountryContext);
  const location = useLocation();
  const identifier = location?.pathname?.split("/");
  const [isRefetching, setIsRefetching] = useState(false);
  useEffect(() => {
    window?.scrollTo(0, 0);
  }, []);
  const customHeader = {
    store: store,
    Connection: "keep-alive",
  };
  const {
    data: paymentData,
    refetch: refetchPaymentData,
    loading: paymentLoading,
  } = useQuery(queries.cmsQueries.GET_PAYMENT_PAGE, {
    variables: { identifier: identifier[identifier.length - 1] },
    context: {
      headers: customHeader,
    },
  });
  let paymentHtml = paymentData?.cmsPage?.content;
  const str = paymentHtml?.replaceAll("&lt;", "<");
  const finalStr = str?.replaceAll("&gt;", ">");

  useEffect(() => {
    setIsRefetching(true);
    refetchPaymentData().finally(() => setIsRefetching(false));
    //eslint-disable-next-line
  }, [store]);

  const showSkeleton = paymentLoading || isRefetching;

  return (
    <div className="PaymentWrapper directionrtl">
      <Helmet>
        <title>
          {paymentData?.cmsPage?.meta_title !== ""
            ? paymentData?.cmsPage?.meta_title
            : `jebelz.com ${paymentData?.cmsPage?.title}`}
        </title>
        <meta
          name="description"
          content={
            paymentData?.cmsPage?.meta_description !== ""
              ? paymentData?.cmsPage?.meta_description
              : `jebelz.com ${paymentData?.cmsPage?.title}`
          }
        />
        <meta
          name="keywords"
          content={
            paymentData?.cmsPage?.meta_keyword !== ""
              ? paymentData?.cmsPage?.meta_keyword
              : `jebelz.com ${paymentData?.cmsPage?.title}`
          }
        />
        <meta name="DC.title" content={paymentData?.cmsPage?.meta_title} />
        <meta name="geo.region" content={store === "in" ? "IN-UP" : "AE-DU"} />
        <meta
          name="geo.placename"
          content={store === "in" ? "Noida" : "Dubai"}
        />
        <meta
          name="geo.position"
          content={
            store === "in" ? "28.58508;77.315262" : "25.121614;55.377031"
          }
        />
        <meta
          name="ICBM"
          content={
            store === "in" ? "28.58508, 77.315262" : "25.121614, 55.377031"
          }
        />
        <meta
          property="og:title"
          content={
            paymentData?.cmsPage?.meta_title !== ""
              ? paymentData?.cmsPage?.meta_title
              : `jebelz.com ${paymentData?.cmsPage?.title}`
          }
        />
        <meta
          property="og:description"
          content={
            paymentData?.cmsPage?.meta_keyword !== ""
              ? paymentData?.cmsPage?.meta_keyword
              : `jebelz.com ${paymentData?.cmsPage?.title}`
          }
        />
        <meta property="og:url" content={window.location.href} />
        <meta
          property="og:image"
          content={
            store.includes("ar")
              ? "https://access.jebelz.com/media/logo/stores/4/JebelzA.gif"
              : "https://access.jebelz.com/media/logo/stores/10/Logo.gif"
          }
        />
        <meta
          property="og:type"
          content={
            paymentData?.cmsPage?.content_heading !== ""
              ? paymentData?.cmsPage?.content_heading
              : `jebelz.com ${paymentData?.cmsPage?.content_heading}`
          }
        />
      </Helmet>
      {showSkeleton ? (
        <Container sx={{ py: 4 }}>
          <Box>
            <Skeleton variant="text" width={150} height={40} />

            {[1, 2, 3].map((item) => (
              <Box key={item} sx={{ mt: 3 }}>
                <Skeleton variant="text" width={200} height={30} />
                <Skeleton
                  variant="rectangular"
                  width="100%"
                  height={60}
                  sx={{ mt: 1 }}
                />
              </Box>
            ))}
          </Box>
        </Container>
      ) : (
        <div dangerouslySetInnerHTML={{ __html: finalStr }}></div>
      )}
    </div>
  );
}

export default Payment;
